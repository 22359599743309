import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component } from '@angular/core';
import { TeamMembersService } from 'services/team-members.service';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { ModalService } from 'services/modal.service';
import { faTimes } from 'magma/common/icons';
import { TeamBannedMember, TeamData } from '../../../../../shared/interfaces';
import { DEFAULT_SIMPLE_TABLE_PARAMS, Permission, SimpleTableColumn, SimpleTableParams, SortOrders } from 'magma/common/interfaces';
import { SimpleTableService } from 'services/simple-table.service';
import { tap } from 'rxjs';
import { TeamService } from 'services/team.service';
import { Router } from '@angular/router';
import { belowBreakpointMD } from 'components/utils';

@UntilDestroy()
@Component({
  selector: 'team-settings-bans',
  templateUrl: './team-settings-bans.component.pug',
  styleUrls: [
    '../../account-common.component.scss',
    './team-settings-bans.component.scss',
  ],
})
export class TeamSettingsBansComponent {
  faTimes = faTimes;
  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;
  columns: SimpleTableColumn[] = [
    { name: 'User', key: 'user.name', sortable: true, inMobileView: true },
    { name: 'Banned', key: 'createdAt', sortable: true },
    { name: 'Unban', inMobileView: true },
  ];
  simpleTableParams = {...DEFAULT_SIMPLE_TABLE_PARAMS, sortBy: 'createdAt', sortDirection: SortOrders.Descending};
  isLoading = false;
  _members: TeamBannedMember[] = [];
  bannedTeamMembers: TeamBannedMember[] = [];

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersService: TeamMembersService,
    private toastService: ToastService,
    private modalService: ModalService,
    private simpleTableService: SimpleTableService,
    private teamService: TeamService,
    private router: Router,
  ) {
    this.activeTeam$.pipe(tap(() => this.isLoading = true), untilDestroyed(this)).subscribe(async (team) => {
      this.activeTeam = team;
      if (team && this.teamService.hasPermissionFlag(Permission.CanManageTeamMembers)) {
        this._members = await this.teamMembersService.getTeamBannedMembers(team._id);
        this.syncTable();
      }
      this.isLoading = false;
    });
  }

  async unbanUser(member: TeamBannedMember) {
    try {
      if (await this.modalService.unbanUserFromTeam({ teamName: this.activeTeam!.name, userName: member?.user?.name })) {
        await this.teamMembersService.unbanUserTeam(this.activeTeam!._id, member.user._id);
        this._members = this._members.filter((user) => user != member);
        this.syncTable();
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to Unban member', subtitle: e.message });
    }
  }

  get numberOfPages() {
    return Math.ceil((this.simpleTableParams.total || 0) / this.simpleTableParams.pageSize);
  }

  get isMobileView() {
    return belowBreakpointMD();
  }

  sortingTable(data: SimpleTableParams) {
    this.simpleTableParams = { ...data };
    this.syncTable();
  }

  syncTable() {
    const result = this.simpleTableService.sortAndPaginateArray(this._members, this.simpleTableParams.sortBy, this.simpleTableParams.sortDirection, this.simpleTableParams.currentPage, this.simpleTableParams.pageSize);
    this.bannedTeamMembers = result.data;
    this.simpleTableParams.total = result.totalItems;
  }

  navigateMembersTab() {
    void this.router.navigate(['s', this.teamsQuery.getActive()?.slug, 'settings', 'members']);
  }
}
