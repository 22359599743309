<button #contextMenu="context-menu" [contextMenu]="actionsMenu" contextMenuLocation="left top" contextMenuEvent="click"
  [allowInsideClick]="true" class="button btn-xs context-menu-button"
  (dblclick)="$event.preventDefault(); $event.stopPropagation()"
  [class.context-menu-button-open]="contextMenu.isOpen">
  <svg-icon [icon]="contextMenu.isOpen ? farEllipsisH : farEllipsisV" size="lg"></svg-icon>
</button>

<ng-template #actionsMenu>
  <div class="dropdown-menu context-menu dropdown-wide">
    <button class="dropdown-item" (click)="refresh(contextMenu)">
      Refresh
    </button>
    <button class="dropdown-item" (click)="remove(contextMenu)">
      Remove
    </button>
  </div>
</ng-template>
