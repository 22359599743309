import { Component, ElementRef, HostBinding, Input } from '@angular/core';
import { SortOrders } from 'magma/common/interfaces';
import { faArrowDown, faArrowUp } from 'magma/generated/fa-icons';

@Component({
    selector: 'entity-grid-column',
    templateUrl: './entity-column.component.pug',
    styleUrls: ['./entity-column.component.scss'],
  })
  export class EntityColumnComponent {
    readonly faArrowUp = faArrowUp;
    readonly faArrowDown = faArrowDown;
    constructor(private elementRef: ElementRef<HTMLElement>) {
      this.initialWidth = this.width || this.elementRef.nativeElement.offsetWidth;
    }
    @Input() isResizable = true;
    @Input() isSortable = false;
    @Input() direction: boolean | SortOrders = false;
    @HostBinding('style.width.px')
    width: number | null = null;
  
    private initialWidth: number | null = null; // Store the initial width

    onResize(resizeValue: number) {
      if (this.isResizable && this.initialWidth !== null) {
        this.width = this.initialWidth + resizeValue;
      }
    }

    updateInitialWidth() {
      this.initialWidth = this.elementRef.nativeElement.offsetWidth;
    }
  }
