import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EntityGridToolbarEvent } from 'components/entity-grid/entity-grid.component';
import { faFilm, faKey } from 'magma/generated/fa-icons';
import { blazeIcon } from 'magma/common/icons';
import { EntityData, Participant, ProjectData } from 'shared/interfaces';
import { EntityType } from 'shared/entities';
import { getEntityUrl, getQueryParamsForFolder } from 'shared/product-info';
import { createThumbPath } from 'shared/utils';
import { EntityToolbarButton } from 'util/entities-toolbar';
import { generateEntityDownloadUrl } from 'util/util';
import {  belowBreakpointXXL } from 'components/utils';

@Component({
  selector: 'live-entity-preview',
  templateUrl: 'live-entity-preview.component.pug',
  styleUrls: ['live-entity-preview.component.scss'],
})
export class LiveEntityPreviewComponent {
  getQueryParamsForFolder = getQueryParamsForFolder;
  readonly passwordIcon = faKey;
  readonly blazeIcon = blazeIcon;
  readonly faFilm = faFilm;

  @Input() entity!: EntityData;
  @Input() date: string | Date | undefined;
  @Input() isEntityPage = false;

  @Input()
  set participants(value: Participant[]) {
    this._participants = value || [];
  }
  get participants() {
    return this._participants;
  }
  private _participants: Participant[] = [];

  @Input() isLive = false;
  @Input() loading = false;
  @Input() actions = false;
  @Input() isDark = false;
  @Input() project: ProjectData | undefined = undefined;
  @Input() isBinFolder = false;
  @Input() participationId?: string;
  @Output() toolbarEvent = new EventEmitter<EntityGridToolbarEvent>();

  constructor(private router: Router) { }

  isFolder() {
    return this.entity.type === EntityType.Folder;
  }

  get link() {
    if (this.isFolder()) {
      const urlTree = this.router.parseUrl(this.router.url);
      urlTree.queryParams = {};
      return urlTree.toString();
    }
    return getEntityUrl(this.entity);
  }

  get thumbPath() {
    if (this.isFolder()) {
      return require('/assets/folder.svg');
    }

    return createThumbPath(this.entity.shortId, this.entity.cacheId);
  }

  get participantNumber() {
    return belowBreakpointXXL() ? 3 : 5;
  }

  onActionToolbarButtonClicked(entity: EntityData, button: EntityToolbarButton) {
    this.toolbarEvent.emit({ entity, button });
  }

  dragStart(event: DragEvent) {
    const fileName = this.entity.name.replace(/:/g, '') || 'download';
    const url = `${location.protocol}//${location.host}${generateEntityDownloadUrl(this.entity.shortId, 'png')}`;

    if (event.dataTransfer) {
      const image = (event.target as HTMLElement)?.querySelector?.('img');
      if (image && 'setDragImage' in event.dataTransfer) {
        event.dataTransfer.setDragImage(image, 0, 0);
      }
      event.dataTransfer.clearData();
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.setData('text/plain', url);
      event.dataTransfer.setData('text/uri-list', url);
      event.dataTransfer.setData('DownloadURL', `image/png:${fileName}.png:${url}`);
    }
  }
}
