import { Component, EventEmitter, Input, Output } from '@angular/core';
import { delay } from 'magma/common/promiseUtils';
import { TeamService } from 'services/team.service';
import { EntityData, ProjectData } from 'shared/interfaces';
import { EntityToolbarButton, getToolbar } from 'util/entities-toolbar';

@Component({
  selector: 'entity-actions-menu',
  templateUrl: 'entity-actions-menu.component.pug',
  styleUrls: ['./entity-actions-menu.component.scss'],
  host: { 'class': 'dropdown-menu dropdown-menu-dark context-menu dropdown-wide' },
})
export class EntityActionsMenuComponent {
  @Input() entity!: EntityData | EntityData[];
  @Input() project!: ProjectData | undefined;
  @Input() isBinFolder = false;
  @Output() action = new EventEmitter<EntityToolbarButton>();

  private _buttons: EntityToolbarButton[] | undefined;
  private _buttonsEntity?: EntityData | EntityData[];
  get buttons() {
    if (this._buttonsEntity !== this.entity) {
      this._buttonsEntity = this.entity;

      this._buttons = getToolbar(this.entity, this.teamService, this.project, this.isBinFolder);
    }
    return this._buttons;
  }

  get items() {
    return Array.isArray(this.entity) ? this.entity.length : 1;
  }

  constructor(private teamService: TeamService) {
  }

  async execAction(button: EntityToolbarButton) {
    if (!button.disabled || button.allowClick) {
      this.action.emit(button);

      if (button.clickedTitle) {
        button.clicked = true;
        await delay(1000);
        button.clicked = false;
      }
    }
  }
}
