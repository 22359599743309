import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ProjectService } from 'services/projects.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';

@Injectable()
export class TeamGuard implements CanActivate {
  constructor(
    private router: Router,
    private teamsQuery: TeamsQuery,
    private teamService: TeamService,
    private projectService: ProjectService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const slug = route.paramMap.get('team');

    // TODO: this is really bad, and should be removed, but removing this breaks team switching on firefox
    if (slug !== this.teamsQuery.getActiveId()) {
      this.teamService.setActiveTeamSlug(slug);
      this.projectService.setActiveProject(null);
    }

    return combineLatest([
      this.teamService.teamsReady$.pipe(filter(s => s)),
    ]).pipe(
      map(() => {
        if (!this.teamsQuery.getAll().some(team => team.slug === slug)) {
          void this.router.navigate(['no-access-or-not-found'], { skipLocationChange: true });
          return false;
        } else {
          return true;
        }
      }),
    );
  }
}
