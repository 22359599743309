import { ToastService } from 'magma/services/toast.service';
import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { faAngleDown, faAngleRight, faFolderPlus } from 'magma/common/icons';
import { SvgIconDefinition, TREE_ARTDESK_ID } from 'magma/common/interfaces';
import { EntitiesService } from 'services/entities.service';
import { MAX_ENTITY_NAME_LENGTH } from 'magma/common/constants';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.pug',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  faAngleRight = faAngleRight;
  faAngleDown = faAngleDown;
  faFolderPlus = faFolderPlus;
  shouldShowNewFolderInput = false;
  TREE_ARTDESK_ID = TREE_ARTDESK_ID;
  MAX_ENTITY_NAME_LENGTH = MAX_ENTITY_NAME_LENGTH;
  folderName = '';
  isLoading = false;

  @Output() onSelected = new EventEmitter();
  @Output() onOpen = new EventEmitter();
  @Output() newFolder = new EventEmitter<{ folder: string | null, project: string, name: string, id: string }>();

  @Input() id!: string; // projectId:folderId
  @Input() title!: string;
  @Input() avatar!: string;
  @Input() selected!: string;
  @Input() expandable = true;
  @Input() opened = false;
  @Input() icon?: SvgIconDefinition;
  @Input() isLoadingPreselected?: boolean;
  @Input() disabled = false;
  @Input() type!: string;

  constructor(private entitiesService: EntitiesService, private toastService: ToastService) { }

  @HostBinding('class.opened')
  get isOpened() {
    return this.opened;
  }

  get isSelected() {
    return this.selected === this.id;
  }

  showNewFolderInput() {
    this.shouldShowNewFolderInput = true;
    this.expandable = true;
    this.opened = true;
  }

  async onEnter() {
    const [project, folder] = this.id.split(':');

    try {
      this.isLoading = true;
      const newFolder = await this.entitiesService.createNewFolder(
        project === TREE_ARTDESK_ID ? undefined : project,
        folder ?? undefined,
        this.folderName,
        'move-entity-modal',
      );

      this.newFolder.emit({ project, folder, name: this.folderName, id: newFolder._id });
      this.folderName = '';
    } catch (e) {
      this.toastService.error({ message: 'Failed to create a folder', subtitle: e.message });
    }
    this.isLoading = false;
    this.shouldShowNewFolderInput = false;
  }

  toggle(event: MouseEvent) {
    if (this.disabled) return;
    this.opened = !this.opened;
    event.stopPropagation();
    if (this.opened) {
      this.onOpen.emit();
    }
  }

  select() {
    if (this.disabled) return;
    this.onSelected.emit();
    this.onOpen.emit();
    this.opened = true;
  }

  onBlur() {
    this.shouldShowNewFolderInput = false;
  }
}
