import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AiModel } from 'magma/common/aiInterfaces';
import { ModalService } from 'services/modal.service';
import { RpcService } from 'services/rpc.service';
import { ToastService } from 'magma/services/toast.service';
import { AiService } from 'services/ai.service';
import { generateAiThumbnailImageUrl } from 'util/util';
import { TeamsQuery } from 'services/team.query';
import { UserService } from 'services/user.service';
import { ActivatedRoute, Params } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'ai-models',
  templateUrl: './ai-model.component.pug',
  styleUrls: [
    './ai-model.component.scss',
  ],
})
export class AiModelComponent {
  generateAiThumbnailImageUrl = generateAiThumbnailImageUrl;

  rId = '';
  model: AiModel | undefined;

  constructor(private aiService: AiService, private modals: ModalService, private rpc: RpcService,
    private toastService: ToastService, private teamsQuery: TeamsQuery, private userService: UserService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(async (params: Params) => {
      this.rId = params.rId;
      this.model = await this.aiService.getAiModel(params.rId, this.teamId);
    });
  }

  get teamId() {
    return this.teamsQuery.getActive()?._id ?? null;
  }

  async refresh() {
    this.model = await this.aiService.getAiModel(this.rId, this.teamId,);
  }
  
  async edit(model: AiModel) {
    try {
      if (await this.modals.editAiModel(model)) {
        await this.refresh();
        this.toastService.success({ message: 'Successfully updated model' });
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to update model', subtitle: e.message });
    }
  }

  isShared(model: AiModel) {
    if (model.team) {
      return model.team !== this.teamId;
    } else {
      return model.owner !== this.userService.userId;
    }
  }
}
