import { Component, EventEmitter, Input, Output } from '@angular/core';
import { times } from 'magma/common/utils';
import { faChevronLeft, faChevronRight, faEllipsisH } from 'magma/generated/fa-icons';

@Component({
    selector: 'entity-grid-pagination',
    templateUrl: './entity-grid-pagination.component.pug',
    styleUrls: ['./entity-grid-pagination.component.scss']
  })
  export class EntityGridPaginationComponent {
    readonly faArrowLeft = faChevronLeft;
    readonly faArrowRight = faChevronRight;
    readonly faEllipsisH = faEllipsisH;

    @Input() currentPage = 1;
    @Input() numberOfPages = 1;
    @Input() numberOfElements = 0;
    @Input() isLoading = false;
    @Input() isMobile = false;
    @Input() isLight = false;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();

    onPageChange(pageNumber: number): void {
      if (pageNumber !== this.currentPage) {
        this.pageChange.emit(pageNumber);
        this.currentPage = pageNumber;
      }
    }
    getPagesArray(): number[] {
      return times(this.numberOfPages, i => i + 1);
    }
    private pagination = (count: number, ellipsis = -1) => (page: number, total: number) => {
      const {floor, min, max} = Math;
      const range = (lo: number, hi: number) => Array .from ({length: hi - lo}, (_, i) => i + lo);
      const start = max (1, min (page - floor ((count - 3) / 2), total - count + 2));
      const end = min (total, max (page + floor ((count - 2) / 2), count - 1));
      return [
        ... (start > 2 ? [1, ellipsis] : start > 1 ? [1] : []), 
        ... range (start, end + 1), 
        ... (end < total - 1 ? [ellipsis, total] : end < total ? [total] : [])
      ];
    };
    getCenteredPagesArray(): number[] {
      const numberOfPages = this.numberOfPages;
      const currentPage = this.currentPage;
      const maxPages = this.isMobile ? 3 : 5;

      const pages = this.pagination(maxPages)(currentPage, numberOfPages);
      return pages;
    }
    get currentPageNumber() {
      return this.currentPage;
    }
    moveBy(value: number): void {
      const newValue = this.currentPage + value;

      if (newValue > 0 && newValue <= this.numberOfPages) {
        this.onPageChange(newValue);
      }
    }
  }
