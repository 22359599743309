import { ToastService } from 'magma/services/toast.service';
import { SelectedFolder, SelectedTypes, TREE_ARTDESK_ID } from 'magma/common/interfaces';
import { ProjectService } from 'services/projects.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderInfo, ProjectTreeItem, TeamTreeItem } from 'shared/interfaces';
import { EntityType } from 'shared/entities';
import { ModalService } from 'services/modal.service';
import { ProjectQuery } from 'services/projects.query';
import { faHashtag, faFolder, faFile } from 'magma/common/icons';
import { TeamsQuery } from 'services/team.query';
import { firstValueFrom } from 'rxjs';

type FoldersTree = Map<string | null, FolderInfo>;

@Component({
  selector: 'team-picker',
  templateUrl: 'team-picker.component.pug',
  styleUrls: ['team-picker.component.scss'],
})
export class TeamPickerComponent {
  @Input() pickedFolderIds?: string[];
  @Input() teamId?: string;
  @Input() showArtdesk!: boolean;
  @Input() preselectedFolder?: SelectedFolder;
  @Input() showFiles = true;
  @Output() selectedFolder = new EventEmitter<SelectedFolder>();

  TREE_ARTDESK_ID = TREE_ARTDESK_ID;
  selected = '';
  projects = new Map<string, FoldersTree>();
  projectsData = new Map<string, ProjectTreeItem[]>(); // teamId:projectsList
  tree: TeamTreeItem[] | undefined = undefined;
  isLoading = true;

  projectIcon = faHashtag;
  folderIcon = faFolder;
  fileIcon = faFile;


  opened = new Set<string>();

  constructor(
    private projectService: ProjectService,
    private modalService: ModalService,
    private toastService: ToastService,
    private projectsQuery: ProjectQuery,
    private teamQuery: TeamsQuery,
  ) { }

  async ngOnInit() {
    this.getTeamTreeFromStore();

    try {
      const [tree] = await Promise.all([
        this.projectService.getProjectTree(this.teamId).toPromise(),
        this.preselectedFolder?.projectId ? this.getFolderTree(this.preselectedFolder.projectId) : undefined,
        this.loadArtdesk(),
      ]);

      this.getOpenedFoldersSet();
      this.tree = tree;
      this.isLoading = false;
    } catch (e) {
      this.toastService.error({ message: 'Failed to download list of folders.', subtitle: e.message });
    }
  }

  private getTeamTreeFromStore() {
    const teams = this.teamQuery.getAll();
    this.tree = teams.map(t => ({
      _id: t._id,
      name: t.name,
      avatar: t.avatar,
      projects: t.projects.map(p => ({ ...p, folderCount: 0 })),
      slug: t.slug,
    }));
    if (this.preselectedFolder) {
      const projectId = this.preselectedFolder.teamId ? this.preselectedFolder.projectId : TREE_ARTDESK_ID;
      if (this.preselectedFolder.entityId) {
        this.selected = `${projectId}:${this.preselectedFolder.entityId}`;
      } else if (this.preselectedFolder.teamId) {
        this.selected = `${projectId}`;
      }

      if (this.preselectedFolder.teamId) this.opened.add(this.preselectedFolder.teamId);
      if (projectId) {
        this.opened.add(projectId);
      }

      // wait for change detection
      setTimeout(() => {
        const elementToScroll = document.querySelector('.accordion-button > .selected') || document.querySelector('accordion.opened');
        elementToScroll?.scrollIntoView({ block: 'center' });
      }, 100);
    }
  }

  private getOpenedFoldersSet() {
    if (this.preselectedFolder) {
      const projectId = this.preselectedFolder.teamId ? this.preselectedFolder.projectId : TREE_ARTDESK_ID;
      if (this.preselectedFolder.entityId && this.preselectedFolder.projectId) {
        const folders = this.projects.get(this.preselectedFolder.projectId);
        const allFolderList = Array.from(folders?.values() ?? []).map(f => f.children).flat();
        const allFolderMap = new Map(allFolderList.map(f => [f._id, f]));
        if (folders) {
          let { entityId } = this.preselectedFolder;
          while (true) { // hmmm try to remove it
            const folder = allFolderMap.get(entityId);
            if (folder) {
              this.opened.add(folder._id);
              if (folder?.parentId) {
                entityId = folder.parentId;
              } else {
                break;
              }
            } else {
              break;
            }
          }
        }
        this.selected = `${projectId}:${this.preselectedFolder.entityId}`;
      } else if (this.preselectedFolder.teamId) {
        this.selected = `${projectId}`;
      }

      if (this.preselectedFolder.teamId) this.opened.add(this.preselectedFolder.teamId);
      if (projectId) {
        this.opened.add(projectId);
      }
      // wait for change detection
      setTimeout(() => {
        const elementToScroll = document.querySelector('.accordion-button > .selected') || document.querySelector('accordion.opened');
        elementToScroll?.scrollIntoView({ block: 'center' });
      }, 100);
    }
  }

  async getFolderTree(id: string) {
    const folders = await firstValueFrom(this.projectService.getFolderTree(id, this.showFiles));
    const map: FoldersTree = new Map();
    folders.forEach(f => map.set(f._id, f));
    this.projects.set(id, map);
  }

  async loadArtdesk() {
    if (!this.showArtdesk) return;
    await this.getFolderTree(TREE_ARTDESK_ID);
  }

  async loadProject(p: ProjectTreeItem) {
    if (this.projects.has(p._id) || p.folderCount === 0) return;
    await this.getFolderTree(p._id);
  }

  async onNewFolder(data: { folder: string | null, project: string, name: string, id: string }) {
    const p = this.projects.get(data.project);
    const newFolder = { parentId: data.folder, name: data.name, _id: data.id, children: [], type: EntityType.Folder };
    if (p && p.get(data.folder ?? null)) {
      p.set(data.id, newFolder);
      p.get(data.folder ?? null)?.children.push(newFolder);
    } else {
      const map: FoldersTree = new Map();
      map.set(null, { name: '', _id: '', parentId: null, children: [newFolder], type: EntityType.Folder });
      map.set(data.id, newFolder);
      this.projects.set(data.project, map);
    }
  }

  async createProject(teamId: string) {
    try {
      const project = await this.modalService.createProject(teamId);
      if (project) {
        const map: FoldersTree = new Map();
        map.set(null, { name: '', _id: '', parentId: null, children: [], type: EntityType.Folder });
        this.projects.set(project._id, map);

        this.tree?.find(t => t._id === project.team)?.projects.push({ _id: project._id, name: project.name, folderCount: 0 });
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to create a team', subtitle: e.message });
    }
  }

  onSelected(location: { projectId: string | undefined, entityId: string | undefined }, type: SelectedTypes) {
    const id = `${location.projectId ?? ''}${location.entityId ? `:${location.entityId}` : ''}`;
    if (this.selected === id) {
      this.selected = '';
    } else {
      this.selected = id;
    }

    const project = location.projectId ? this.projectsQuery.getEntity(location.projectId) : null;
    this.selectedFolder.emit({
      projectId: location.projectId === TREE_ARTDESK_ID ? undefined : location.projectId,
      entityId: location.entityId,
      teamId: project?.team,
      type
    });
  }

  byId(_index: number, item: { _id: string }) {
    return item._id;
  }
}
