import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'conference-start-modal',
  templateUrl: './conference-start-modal.component.pug',
  styleUrls: ['./conference-start-modal.component.scss'],
})
export class ConferenceStartModalComponent {
  @Output() close = new EventEmitter<boolean>();
}
