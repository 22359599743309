import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { options } from 'magma/common/data';
import { destroyCanny, initCanny, renderCanny } from 'util/canny';
import { UserService } from 'services/user.service';

const BoardTokens: { [key: string]: string; } = {
  '/my/feedback/requests': options.cannyFeedbackFeature || '06939cf0-5d95-41bf-0e79-6e28f1386ca8',
  '/my/feedback/bugs': options.cannyFeedbackBug || '35c5b62c-9f2e-5421-0383-ceae9e0f8f97',
  '/my/feedback/community-hub': options.cannyFeedbackCommunityHub || 'eaf2fa3c-b214-4f78-d296-8306e3a2c1d0',
};

@Component({
  selector: 'feedback-requests',
  templateUrl: './feedback-requests.component.pug',
  styleUrls: ['./feedback-requests.component.scss'],
})
export class FeedbackRequestsComponent {
  private boardToken = '';
  private basepath = '';

  constructor(router: Router, private userService: UserService) {
    initCanny();

    Object.keys(BoardTokens).forEach(path => {
      if (router.url.startsWith(path)) {
        this.basepath = path;
      }
    });

    this.boardToken = BoardTokens[this.basepath];
  }

  ngAfterViewInit() {
    this.userService.getUserCannyToken().subscribe(token => renderCanny(this.boardToken, this.basepath, token));
  }

  ngOnDestroy() {
    destroyCanny();
  }
}
