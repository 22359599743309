import { Component } from '@angular/core';
import { Feature } from 'magma/common/interfaces';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.pug',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsPageComponent {
  constructor(private featureFlagService: FeatureFlagService) { }

  get isAiDreamboothSupported() {
    return this.featureFlagService.isFeatureSupported(Feature.AiDreambooth);
  }
}
