import { BillingData, BillingInterval, BillingItem, CustomerBillingInfo, ProductPrice, ScheduledTeamBillingUpdate, StorageUsageData, TeamData, TeamType } from './../../../../../shared/interfaces';
import { TEAM_PRODUCTS, FreePlan, getSubscriptionPlanByEnum } from 'shared/billing';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { faInfoCircle, farCheckCircle, farExclamationCircle, farExclamationTriangle } from 'magma/generated/fa-icons';
import { getNumberOfPurchasedSub, getPlanForMember, hasInProSources, routeToTeam } from 'shared/utils';
import { BillingService } from 'services/billing.service';
import { Component } from '@angular/core';
import { ErrorReporter } from 'magma/services/errorReporter';
import { ModalService } from 'services/modal.service';
import { PaymentService } from 'services/payment.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';
import { delay } from 'magma/common/promiseUtils';
import { map } from 'rxjs/operators';
import { TeamService } from 'services/team.service';
import { BaseBillingComponent } from 'components/base-billing.component';
import { AppService } from 'services/app.service';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { TeamMembersService } from 'services/team-members.service';

@UntilDestroy() @Component({
  selector: 'team-settings-billing',
  templateUrl: './team-settings-billing.component.pug',
  styleUrls: [
    '../../account-common.component.scss',
    './team-settings-billing.component.scss',
  ],
})
export class TeamSettingsBillingComponent extends BaseBillingComponent {
  infoIcon = faInfoCircle;
  errorIcon = farExclamationCircle;

  limitOk = farCheckCircle;
  limitNok = farExclamationTriangle;

  products = new Map<string, BillingItem>();
  plan = FreePlan;
  productsQuantity = new Map<string, number>();
  billingInterval: BillingInterval = 'month';
  autoUpdateBilling = false;

  team?: TeamData;

  members = this.teamMembersQuery.getAll();
  members$ = this.teamMembersQuery.selectAll();
  membersCount$ = this.teamMembersQuery.selectCount();
  memberPlans$ = this.teamMembersQuery.selectAll().pipe(map(members => {
    return members.map(m => getPlanForMember(m));
  }));

  productsNeeded = new Map<string, number>(); // stripeProductId: quantity
  isBillingValid = false;
  prices: Map<string, ProductPrice> = new Map();

  billingPendingChanges: ScheduledTeamBillingUpdate | undefined;
  storage: StorageUsageData | undefined;
  cancelAnswers = [
    'We didn\'t have time to set it up',
    'We didn\'t have time to use it',
    'It was difficult for us to learn',
    'It haven\'t improved our process',
    'We prefer other collaboration tools',
    'It was too expensive',
    'Some important features were missing',
    'Customer service was unsatisfactory',
    'Other',
  ];

  upcomingInvoicePrice = 0;

  constructor(
    private billingService: BillingService,
    private paymentService: PaymentService,
    private modals: ModalService,
    userService: UserService,
    toastService: ToastService,
    errorReporter: ErrorReporter,
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private teamService: TeamService,
    private appService: AppService,
    private router: Router,
    private teamMemberService: TeamMembersService
  ) {
    super(userService, toastService, errorReporter);

    this.memberPlans$.pipe(untilDestroyed(this)).subscribe(plans => {
      for (const [productId, productData] of TEAM_PRODUCTS) {
        const count = plans.reduce((count, plan) => count + (plan === productId ? 1 : 0), 0);
        this.productsNeeded.set(productId, count);
      }
      return this.productsNeeded;
    });
  }

  get numberOfPlans() {
    return (this.team && getNumberOfPurchasedSub(this.billingPendingChanges, this.billingData, this.team.pro)) ?? 0;
  }

  get plansUsage() {
    return Math.min(100, this.numberOfAssignedPlans / this.numberOfPlans * 100);
  }

  get allPlansAssiged() {
    return this.numberOfAssignedPlans >= this.numberOfPlans;
  }

  get isFreeUsageCountOk() {
    return this.storage && this.storage.used < this.storage.limit;
  }

  get hasSubscription() {
    return this.team?.pro || (!!this.billingData?.stripe?.subscriptionId && !this.isExpired);
  }

  get forcedPro() {
    if (!this.team) return false;
    return this.team.forcePro || (this.team.forceProUntil && new Date(this.team.forceProUntil).getTime() > Date.now());
  }

  get arePendingChanges() {
    return this.team?.pro && !!this.billingPendingChanges && !this.isCancelled;
  }

  get planPrice() {
    if (this.interval === 'month') return this.plan.monthly;
    return this.plan.yearly;
  }

  getProduct(productId: string) {
    return TEAM_PRODUCTS.get(productId);
  }

  get interval() {
    return this.billingData!.interval;
  }

  get teamType() {
    return this.team && this.billingService.getTeamType(this.team);
  }

  get planName() {
    switch (this.teamType) {
      case TeamType.Professional:
        return 'Blaze';
      case TeamType.Private:
      case TeamType.Public:
        return 'Spark';
      case TeamType.Enterprise:
        return 'Fusion';
    }
  }

  async ngOnInit() {
    this.team = this.teamsQuery.getActive();
    if (this.team?.pro) {
      this.plan = getSubscriptionPlanByEnum(this.team.pro);
    }

    this.loadBillingData().catch(e => this.showAndReportError(e));
    if (this.team) {
      this.storage = await this.teamService.getUsageData(this.team?._id).toPromise();
    }

    this.teamMembersQuery.selectAll().pipe(untilDestroyed(this)).subscribe(members => {
      this.members = members;
    });

    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));
  }

  private async loadBillingData() {
    if (!this.team) return;

    await Promise.all([
      this.getBillingData(this.team._id),
      this.getPendingChanges(this.team._id)
    ]);

    await this.calculateCurrentInvoicePrice(this.billingData);
    await this.checkIfBillingValid(this.team, this.billingData);

    this.billingLoaded = true;
  }

  async changeSubscriptionInterval() {
    if (this.team && this.billingData) {
      const items = new Map(this.billingData.stripe?.items.map(i => [i.product, i.quantity]));

      await this.modals.teamBillingChangeInterval({
        teamId: this.team._id,
        teamSlug: this.team.slug,
        items,
        billingInterval: this.billingInterval,
        billingData: this.billingData,
      });
      await this.loadBillingData();
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async upgradeSubscription() {
    if (!this.billingData) return;
    const numberOfBlaze = this.numberOfPlans;
    if (this.team) {
      await this.modals.createTeamSubscriptionModal({
        team: this.team,
        billingData: this.billingData,
        autoUpdateBilling: this.autoUpdateBilling,
        items: { [this.plan.code]: numberOfBlaze },
        afterItems: { [this.plan.code]: numberOfBlaze },
        membersCount: this.members.length,
        plan: this.plan
      });
      await this.loadBillingData();
      this.storage = await this.teamService.refreshTeamUsageData(this.team._id);
      this.teamMemberService.reloadMembers();
    } else {
      DEVELOPMENT && console.error('Missing active team');
    }
  }

  async reviewBillingChanges() {
    if (this.team && this.billingData && this.billingPendingChanges) {
      const currentItems = new Map(this.billingData.stripe?.items.map(i => [i.price, i.quantity]));
      const afterItems = new Map(this.billingPendingChanges?.items?.map(i => [i.price, i.quantity]));

      await this.modals.teamBillingPendingChanges({
        teamId: this.team._id,
        teamSlug: this.team.slug,
        currentItems,
        afterItems,
        billingInterval: this.billingInterval,
        billingData: this.billingData,
        billingPendingChanges: this.billingPendingChanges,
      });
    }
  }

  async cancelSubscription() {
    if (!this.team) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }
    const takeEffectDate = this.billingData?.validUntil ?? new Date();
    const cancel = await this.modals.cancelSubscription({ takeEffectDate, trial: false, artspace: true, userCount: this.team.memberCount });

    if (!cancel) return;
    await this.onCancelingSubscription(this.team._id);
  }

  private async onCancelingSubscription(teamId: string) {
    try {
      this.isCancellingPlan = true;
      await this.billingService.cancelTeamSubscription(teamId);
      await this.loadBillingData();
      this.showCancelledSubscription = true;
      this.endOfCancelledSubscription = this.billingData?.validUntil ?? new Date();
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isCancellingPlan = false;
    }
  }

  async upgrade() {
    if (!this.team) return;
    this.modals.upgradeModal('team-settings', 'team', this.team._id);
  }

  async restartSubscription() {
    if (!this.team) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }
    try {
      this.isRestartingPlan = true;
      await this.billingService.restartTeamSubscription(this.team._id);
      await this.loadBillingData();
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isRestartingPlan = false;
    }
  }

  async updateCard() {
    if (!this.team) return;
    try {
      const checkout = await this.billingService.updateTeamPaymentInformation(this.team?._id);
      this.paymentService.redirectToStripeCheckout(checkout, '?upgraded=true');
    } catch (e) {
      this.showAndReportError(e);
    }
  }

  async retryPayment() {
    if (!this.team) return;
    const toast = this.toastService.loading({ message: 'Retrying payment' });
    try {
      this.isRetrying = true;
      await this.billingService.retryTeamPayment(this.team._id);
      await delay(2000);
      await this.loadBillingData();
      if (!this.isFailed) {
        this.toastService.updateToSuccess(toast, { message: 'Payment succeeded' });
      }
    } catch (error) {
      this.errorReporter.reportError(error.message, error);
      this.toastService.updateToError(toast, { message: error.message });
    } finally {
      this.isRetrying = false;
    }
  }

  async submitBillingInfo(billingInfo: CustomerBillingInfo) {
    await this.submitForm(async () => {
      if (!this.team) return;
      await this.billingService.updateTeamBillingInformation(this.team._id, billingInfo);
      if (this.billingData) this.billingData.billingInfo = { ...billingInfo };
      await this.loadBillingData();
      this.updatingBillingInfo = false;
    }, 'Billing info updated');
  }

  async submitCancelFeedback() {
    if (!this.team) return;

    const reasons = [...this.cancelChecked];
    const otherIndex = reasons.indexOf('Other');

    if (otherIndex !== -1) {
      reasons[otherIndex] += `: ${this.otherReason}`;
    }

    if (!reasons.length) return;

    await this.billingService.cancelationTeamReasons(this.team._id, reasons);
    this.showCancelledSubscription = false;
  }

  async contactUs() {
    await this.appService.onContactSupport();
  }

  get numberOfAssignedPlans() {
    if (!this.team) return 0;
    const teamId = this.team._id;
    return this.members.reduce((sum, m) => sum + (hasInProSources(m.user, teamId) ? 1 : 0), 0);
  }

  openTeamSettingsMembers() {
    void this.router.navigate(routeToTeam(this.team!.slug, ['settings', 'members']));
  }

  get currentInvoice() {
    if (!this.billingData?.stripe) return 0;

    return this.billingData.stripe.items.reduce((acc, billing) => {
      const item = this.prices.get(billing.price);
      if (item) {
        return acc + item.amount * billing.quantity;
      }
      return 0;
    }, 0);
  }

  private async calculateCurrentInvoicePrice(billing?: BillingData) {
    if (billing) {
      if (this.billingPendingChanges?.items && this.billingPendingChanges.items.length > 0) {
        const price = this.billingPendingChanges.items.reduce((acc, item) => {
          const product = this.prices.get(item.price);
          if (product) {
            return acc + product.amount * item.quantity;
          }
          return acc;
        }, 0);

        this.upcomingInvoicePrice = Math.abs((price / 100) ?? 0);
        return;
      }

      if (billing.nextCharge) {
        this.upcomingInvoicePrice = Math.abs((billing.nextCharge / 100) ?? 0);
        return;
      }

      if (!this.billingData?.stripe?.customerId && !this.billingData?.stripe?.subscriptionId && !this.billingPendingChanges) return;

      const upcomingInvoice = await this.billingService.getTeamUpcomingInvoice({
        customerId: this.billingData?.stripe?.customerId,
        subscriptionId: this.billingData?.stripe?.subscriptionId,
        items: this.billingPendingChanges?.items,
      });

      if (!upcomingInvoice) return;
      this.upcomingInvoicePrice = Math.abs((upcomingInvoice?.total / 100) ?? 0);
    }
  }

  private async getBillingData(teamId: string) {
    const { billing, autoUpdateBilling } = await firstValueFrom(this.billingService.getTeamBillingInformation(teamId));
    this.autoUpdateBilling = !!autoUpdateBilling;
    if (billing) {
      this.billingData = billing;
      if (this.billingData.expiry?.isExpired === false) {
        this.billingInterval = this.billingData.interval;
        this.products = new Map(this.billingData?.stripe?.items?.map(i => [i.product, i]));
        this.productsQuantity = new Map(this.billingData?.stripe?.items?.map(i => [i.product, i.quantity]));
      }
    }
  }

  private async getPendingChanges(teamId: string) {
    const changes = await this.billingService.getTeamBillingPendingChanges(teamId);

    if (changes?.items && changes.items.length > 0) {
      this.billingPendingChanges = changes;
    }
  }

  private async checkIfBillingValid(team: TeamData, billingData: BillingData | undefined) {
    this.isBillingValid = await this.billingService.isBillingValid(team, billingData);
  }
}
