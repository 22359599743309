import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Participant, RegisterArtworkData, RegisterArtworkData2 } from '../../../../../shared/interfaces';
import { faCheck, faTimes, farCheckCircle, farExclamationCircle, farExclamationTriangle, storyprotocolIcon } from 'magma/common/icons';
import { PublisherId } from 'magma/common/interfaces';
import { InheritedLicense } from '../../../../../../magma/src/ts/common/interfaces';
import { UserService } from 'services/user.service';
import { safeInt } from 'magma/common/toolUtils';
import { StoryProtocolLicenseDetails } from '../artwork-licensing-modal/artwork-licensing-modal.component';

@Component({
  selector: 'register-artwork-modal',
  templateUrl: './register-artwork-modal.component.pug',
  styleUrls: ['./register-artwork-modal.component.scss'],
})
export class RegisterArtworkModal {
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly storyprotocolIcon = storyprotocolIcon;
  readonly circleCheck = farCheckCircle;
  readonly warningMark = farExclamationTriangle;
  readonly dangerMark = farExclamationCircle;
  readonly defaultSharesPerPerson = 10;

  constructor(private userService: UserService) { }

  @Output() close = new EventEmitter<RegisterArtworkData2 | undefined>();
  @Input() set data(d: RegisterArtworkData | undefined) {
    if (!d || !d.entity || !d.thumb) {
      DEVELOPMENT && console.warn(`Missing data for RegisterArtworkModal (${!d} || ${!d?.entity} || ${!d?.thumb})`);
      this.close.emit(undefined);
      return;
    }
    this.id = d.entity._id;
    this.name = `${d.entity.name}`;
    this.numberOfShares = (d.entity.participants?.length || 0) * this.defaultSharesPerPerson;
    this.resetParticipantsPercentage(d.entity.participants || []);
    this.shortId = `${d.entity.shortId}`;
    this.thumb = d.thumb.toDataURL('image/jpeg');
    this.img = window.location.origin + `/${d.entity.shortId}.png`;
    this.inheritedLicense = d.inheritedLicense;
    this.requestPending = false;
  }

  // artwork data:
  id = '';
  name = '';
  participants: (Participant & { percentage: number })[] = [];
  participantsShares = new Map<string, number>();
  backupParticipantsShares = new Map<string, number>();
  thumb = '';
  img = '';
  shortId = '';
  inheritedLicense: InheritedLicense | undefined = undefined;

  // modal state:
  commercial = false;
  licenseDetailsVisible = false;
  description = '';
  numberOfShares = 0;

  requestPending = false;
  inEditMode = false;
  showErrorMessage = false;



  resetParticipantsPercentage(participants: Participant[], intial = true) {
    this.participants = (participants ?? []).map(p => ({ 
      ...p, 
      percentage: this.floorPercentage(100 * Number((intial ? this.defaultSharesPerPerson : this.participantsShares.get(p._id))) / this.numberOfShares)
    }));
    const rest = 100 - this.participants.reduce((total, participant) => (total + participant.percentage), 0);
    const authorParticipant = this.participants.find(p => p._id === this.userService.user?._id);
    if (authorParticipant && rest > 0.01) {
      authorParticipant.percentage = this.floorPercentage(authorParticipant.percentage + rest);
    }

    if (intial) {
      this.participants.forEach(p => {
        this.participantsShares.set(p._id, this.defaultSharesPerPerson);
        this.backupParticipantsShares.set(p._id, this.defaultSharesPerPerson);
      });
    }
  }

  onClose(confirmed: boolean) {
    if (confirmed && !this.inEditMode) {
      this.close.emit({
        providerId: PublisherId.StoryProtocol,
        shortId: this.id,
        opts: {
          ipAssetName: this.name,
          ipAssetDescription: this.description,
          isCommercial: this.commercial,
          participants: this.participants.map(p => {
            return {
              accountId: p._id,
              name: p.name,
              percentage: p.percentage
            };
          }),
        }
      });
    } else {
      this.close.emit(undefined);
    }
  }

  get distributionIsValid() {
    const isValid = this.sharesSum === this.allowedShares;
    if (isValid) this.showErrorMessage = false;
    return isValid;
  }

  get sharesSum() {
    return [...this.participantsShares.values()].reduce((acc, curr) => acc + curr, 0);
  }

  get allowedShares() {
    return this.participants.length * this.defaultSharesPerPerson;
  }

  get underSharesLimit() {
    return this.allowedShares > this.sharesSum;
  }

  get overSharesLimit() {
    return this.sharesSum > this.allowedShares;
  }

  get errorMessage() {
    return this.overSharesLimit ? `You need to free up ${this.sharesSum - this.allowedShares} shares` : `You still have ${this.allowedShares - this.sharesSum} shares to distribute`;
  }

  get registrationDisabled() {
    return this.inEditMode || this.requestPending || !this.participants.length;
  }

  floorPercentage(percentage: number) {
    return (Math.floor(percentage * 100) / 100);
  }

  getParticipantShares(userId: string) {
    return this.participantsShares.get(userId);
  }

  onSharesChange(userId: string, event: Event) {
    const shares = safeInt(Number((event.target as HTMLInputElement).value), 0, this.allowedShares);
    this.participantsShares.set(userId, shares);
    (event.target as HTMLInputElement).value = shares.toString();
  }

  cancelSharesChange() {
    if (this.backupParticipantsShares.size > 0) {
      this.participantsShares = new Map(this.backupParticipantsShares);
    }
    this.resetParticipantsPercentage(this.participants, false);
    this.inEditMode = false;
  }
  
  saveSharesChanges() {
    if (this.underSharesLimit || this.overSharesLimit) {
      this.showErrorMessage = true;
      return;
    }
    this.backupParticipantsShares = new Map(this.participantsShares);
    this.resetParticipantsPercentage(this.participants, false);
    this.inEditMode = false;
  }

  distributeEqually(overOrUnder = false) {
    if (overOrUnder) {
      const remains = this.overSharesLimit ? this.sharesSum - this.allowedShares : this.allowedShares - this.sharesSum;
      if (remains !== 0) {
        const toAdd = (this.overSharesLimit ? -1 : 1) * Math.floor(remains / this.participants.length);
        const toAddExtra = (this.overSharesLimit ? -1 : 1) * Math.floor(remains % this.participants.length);
        this.participants.forEach((p, index) => {
          const value = safeInt(Number(this.participantsShares.get(p._id)) + toAdd + (index === 0 ? toAddExtra : 0), 0, this.allowedShares);
          this.participantsShares.set(p._id, value);
        });
        this.distributeEqually(true);
      }
    } else {
      this.resetParticipantsPercentage(this.participants);
    }
  }

  getLicenseItems(may = true) {
    const othersMay = !this.commercial ? StoryProtocolLicenseDetails.othersMay : StoryProtocolLicenseDetails.othersMayCommercial;
    const othersMayNot = !this.commercial ? StoryProtocolLicenseDetails.othersMayNot : StoryProtocolLicenseDetails.othersMayNotCommercial;

    return may ? othersMay: othersMayNot;
  }
}
