import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faInfoCircle } from 'magma/common/icons';
import { ToastService } from 'magma/services/toast.service';
import moment from 'moment';
import { BillingService } from 'services/billing.service';
import { TEAM_PRODUCTS } from 'shared/billing';
import { BillingData, BillingInterval, ProductPrice, UpdateBillingItem } from 'shared/interfaces';
import { getBillingItems } from 'shared/utils';
import Stripe from 'stripe';

export interface TeamBillingChangeIntervalModalInput {
  teamId: string;
  teamSlug: string;
  billingInterval: BillingInterval;
  billingData: BillingData;
  items: Map<string, number>; // stripeProductId: user
}

@Component({
  selector: 'team-billing-change-interval-modal',
  templateUrl: 'team-billing-change-interval-modal.component.pug',
  styleUrls: [
    '../create-team-subscription-modal/create-team-subscription-modal.component.scss',
    './team-billing-change-interval-modal.component.scss'
  ],
})
export class TeamBillingChangeIntervalModalComponent {
  faInfoCircle = faInfoCircle;
  TEAM_PRODUCTS = TEAM_PRODUCTS;
  items: {
    name: string;
    quantity: number;
    price: { [key: string]: ProductPrice },
  }[] = [];
  prices: Map<string, ProductPrice> = new Map();
  openingStripe = false;
  invoiceLoaded = false;
  isAnnual = false;

  sumMonth = 0;
  sumAnnual = 0;
  upcomingInvoice: Stripe.Invoice | undefined;

  afterItems: UpdateBillingItem[] = [];

  @Output() close = new EventEmitter<BillingInterval>();
  @Input() data!: TeamBillingChangeIntervalModalInput;

  constructor(protected billingService: BillingService, private toastService: ToastService) { }

  async ngOnInit() {
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));

    for (const [productId, count] of this.data.items) {
      const product = TEAM_PRODUCTS.get(productId);
      if (!product) {
        DEVELOPMENT && console.error('Invalid product id');
        continue;
      }

      const month = this.prices.get(product.stripePriceId['month']);
      const year = this.prices.get(product.stripePriceId['year']);

      if (!month || !year) {
        this.toastService.error({ message: 'Failed to get product prices. Refresh page' });
        continue;
      }

      this.items.push({ name: product?.name, quantity: count, price: { month, year } });

      this.sumMonth += (this.prices.get(product.stripePriceId['month'])?.amount ?? 0) * count;
      this.sumAnnual += (this.prices.get(product.stripePriceId['year'])?.amount ?? 0) * count;
    }

    this.afterItems = getBillingItems(this.data.billingData, this.data.items, this.afterInterval);

    await this.getUpcomingInvoice();

    this.invoiceLoaded = true;
  }

  get nextChargeDate() {
    // it will set to now if billing interval is changed
    const nextBilling = new Date();
    if (this.afterInterval === 'month') {
      nextBilling.setMonth(nextBilling.getMonth() + 1);
    } else {
      nextBilling.setFullYear(nextBilling.getFullYear() + 1);
    }
    return nextBilling;
  }

  isChangingInterval = false;

  get chargeNow() {
    const charge = (this.upcomingInvoice?.total ?? 0) + (this.upcomingInvoice?.starting_balance ?? 0);
    return charge > 0 ? charge : 0;
  }

  get nextCharge() {
    const amount = this.sumAfter - this.balanceAfterChange;
    return amount > 0 ? amount : 0;
  }

  get proration() {
    return this.sumAfter - (this.upcomingInvoice?.total ?? 0);
  }

  get balance() {
    return -(this.upcomingInvoice?.starting_balance ?? 0);
  }

  get balanceAfterChange() {
    return -(this.upcomingInvoice?.ending_balance ?? 0);
  }

  get nowInterval() {
    return this.data.billingInterval;
  }

  get afterInterval() {
    return this.data.billingInterval === 'month' ? 'year' : 'month';
  }

  get intervalShortNow() {
    return this.nowInterval === 'month' ? 'mo' : 'yr';
  }

  get intervalShortAfter() {
    return this.nowInterval === 'month' ? 'yr' : 'mo';
  }

  get intervalLongNow() {
    return this.nowInterval === 'month' ? 'month' : 'year';
  }

  get intervalLongAfter() {
    return this.nowInterval === 'month' ? 'year' : 'month';
  }

  get sumNow() {
    return this.nowInterval === 'month' ? this.sumMonth : this.sumAnnual;
  }

  get sumAfter() {
    return this.nowInterval === 'month' ? this.sumAnnual : this.sumMonth;
  }

  get balanceTooltip() {
    const dayNumber = Math.abs(moment().diff(this.data.billingData.nextChargeDate, 'days'));
    const proratedAmount = -(this.upcomingInvoice?.lines.data.filter((item: any) => item.proration).reduce((acc, item) => acc + item.amount, 0) ?? 0);
    return `${dayNumber} remaining days in current billing period will be prorated ($${proratedAmount / 100}) towards subsequent billing periods`;
  }

  async confirmIntervalChange() {
    try {
      this.isChangingInterval = true;
      await this.billingService.updateTeamSubscription(this.data.teamId, this.afterItems, true);
      this.close.emit();
    } catch (e) {
      this.toastService.error({ message: 'Failed to change billing interval', subtitle: e.message });
    }
    this.isChangingInterval = false;
  }

  cancel() {
    this.close.emit();
  }

  private async getUpcomingInvoice() {
    if (!this.data.billingData?.stripe?.customerId && !this.data.billingData?.stripe?.subscriptionId && !this.afterItems) return;
    if (this.data.billingData?.expiry?.isExpired) return;

    this.upcomingInvoice = await this.billingService.getTeamUpcomingInvoice({
      customerId: this.data.billingData.stripe?.customerId,
      subscriptionId: this.data.billingData.stripe?.subscriptionId,
      items: this.afterItems
    });
  }
}
