import { Component } from '@angular/core';
import { map } from 'rxjs';
import { AuthService } from 'services/auth.service';
import { UserService } from 'services/user.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.pug',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  constructor(private userService: UserService, private authService: AuthService) {
  }

  user$ = this.userService.user$.pipe(map(user => user?.email ?? user?.name));

  async signOut() {
    await this.authService.logout();
  }
}
