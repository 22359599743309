// Makes specified properties (K) in T optional, Partial for single properties not entire object.
export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// Makes specified properties (K) in T required, Required for single properties not entire object.
export type Mandatory<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

// Picks all properties from type T which are common with type K
export type PickByValue<T, K> = Pick<T, { [Key in keyof T]-?: T[Key] extends K ? Key : any }[keyof T]>;

// Defines type of function which is constructor for T
export type Constructor<T> = new(...args: any[]) => T;

export function isError(err: any): err is Error {
  return err instanceof Error && !!err.message;
}

export type ReplaceType<BaseType, ReplacedKey extends keyof BaseType, NewType> = Omit<BaseType, ReplacedKey> & { [key in ReplacedKey]: NewType };
