import { Component } from '@angular/core';
import { signUps } from 'magma/common/data';
import { Permission } from 'magma/common/interfaces';
import { BillingService } from 'services/billing.service';
import { TeamService } from 'services/team.service';

@Component({
  selector: 'team-settings',
  templateUrl: './team-settings.component.pug',
  styleUrls: ['./team-settings.component.scss'],
})
export class TeamSettingsComponent {
  readonly samlActive = signUps.includes('saml');
  isSelfHosted = IS_HOSTED;

  constructor(private teamService: TeamService, private billingService: BillingService) {
    // prefetch prices
    this.billingService.getAllProductsPrices().catch(e => DEVELOPMENT && console.error(e));
  }

  get canManageTeam() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeam);
  }

  get canManageTeamRoles() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamRoles);
  }

  get canManageTeamBilling() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamBilling);
  }

  get canAddTeamMembers() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamMembers);
  }
}
