import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AiModelType, AsyncJob, DreamboothLoraOutput } from 'magma/common/aiInterfaces';
import { faEllipsisV } from 'magma/generated/fa-icons';
import { ToastService } from 'magma/services/toast.service';
import { AiService } from 'services/ai.service';
import { ModalService } from 'services/modal.service';
import { RpcService } from 'services/rpc.service';
import { TeamsQuery } from 'services/team.query';
import { generateAiThumbnailImageUrl } from 'util/util';

@UntilDestroy()
@Component({
  selector: 'ai-jobs',
  templateUrl: './ai-jobs.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './ai-jobs.component.scss',
  ],
})
export class AiJobsComponent {
  generateAiThumbnailImageUrl = generateAiThumbnailImageUrl;
  readonly faEllipsisV = faEllipsisV;

  jobs: AsyncJob[] = [];
  openedJobs = new Set<string>();
  thumbnailSize = 150;

  constructor(private aiService: AiService, private modals: ModalService, private rpc: RpcService, private toastService: ToastService,
    private teamsQuery: TeamsQuery) { }

  get teamId() {
    return this.teamsQuery.getActive()?._id;
  }

  toggleVisibility(jobId: string) {
    this.openedJobs.has(jobId) ? this.openedJobs.delete(jobId) : this.openedJobs.add(jobId);
  }

  async ngOnInit() {
    await this.refresh();
    this.aiService.observeJobs(this.teamId ?? null).pipe(untilDestroyed(this)).subscribe(statusUpdate => {
      const { jobId, status, progress, error, output } = statusUpdate;
      const job = this.jobs.find(j => j.jobId === jobId);
      if (job) {
        job.status.progress = progress;
        job.status.status = status;
        if (error) job.error = error;
        if (output) job.output = output;
      } else {
        void this.refresh();
      }
    });

    this.rpc.isConnected$.pipe(untilDestroyed(this)).subscribe(status => {
      if (status) this.refresh().catch(e => DEVELOPMENT && console.error(e));
    });
  }

  async queueDreamboothJob() {
    try {
      if (await this.modals.queueAsyncJob(undefined)) {
        await this.refresh();
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to retry job', subtitle: e.message });
    }
  }

  async refresh() {
    this.jobs = await this.aiService.getJobs(this.teamId ?? null);
  }

  async retry(job: AsyncJob) {
    try {
      if (await this.modals.queueAsyncJob({ name: job.name, ...job.input, inputImageMetadata: job.inputImageMetadata })) {
        await this.refresh();
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to retry job', subtitle: e.message });
    }
  }

  async resume(job: AsyncJob, output: DreamboothLoraOutput) {
    try {
      if (await this.modals.queueAsyncJob({ name: job.name, ...job.input, inputImageMetadata: job.inputImageMetadata, resumeFromCheckpoint: { checkpoint: output.checkpoint, jobId: job.jobId } })) {
        await this.refresh();
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to retry job', subtitle: e.message });
    }
  }

  async remove(job: AsyncJob) {
    try {
      await this.aiService.removeJob(job.jobId);
      await this.refresh();
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove job', subtitle: e.message });
    }
  }

  async removeOutput(job: AsyncJob, output: DreamboothLoraOutput) {
    try {
      await this.aiService.removeJobOutput(job.jobId, output.id);
      await this.refresh();
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove job', subtitle: e.message });
    }
  }

  async cancel(job: AsyncJob) {
    try {
      await this.aiService.cancelJob(job.jobId);
      await this.refresh();
    } catch (e) {
      this.toastService.error({ message: 'Failed to cancel job', subtitle: e.message });
    }
  }

  private createModelDescription(job: AsyncJob, output: DreamboothLoraOutput) {
    return `# ${job.name}\n\n` +
      `Created at ${job.createdAt}\n\n` +
      `Checkpoint: ${output.checkpoint}\n\n` +
      'Data:\n\n ```' + JSON.stringify(job.input, null, 2) +  '```';
  }

  async createAiModelFromJob(job: AsyncJob, output: DreamboothLoraOutput) {
    try {
      if (!job.output) throw new Error('Job output is missing');

      if (output.type !== 'dreambooth:output:lora') throw new Error(`Can't convert this output to Lora`);

      if (await this.modals.createAiModel({
        rId: output.model,
        name: job.name,
        description: this.createModelDescription(job, output),
        tag: job.input.instancePrompt,
        type: AiModelType.Lora,

        enabled: false,

        baseModel: job.input.baseModel,
        thumbnails: output.thumbnails,

        permissions: { users: [], teams: [] },
        createdAt: new Date(),
        team: job.team
      })) {
        await this.refresh();
        this.toastService.success({ message: 'Ai model created' });
      }

    } catch (e) {
      this.toastService.error({ message: 'Failed to create model', subtitle: e.message });
    }
  }

  isJobCompleted(job: AsyncJob) {
    return job.status?.status === 'completed';
  }

  isJobFailed(job: AsyncJob) {
    return job.status?.status === 'failed';
  }

  canJobBeCanceled(job: AsyncJob) {
    return job.status?.status === 'running' || job.status?.status === 'queued';
  }

  getOutputUrl(output: DreamboothLoraOutput) {
    return `/api/ai/job/model/${output.model}`;
  }

  getInputImageFilesLength(job: AsyncJob) {
    return Object.keys(job.inputImageFiles).length;
  }
}
