import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MAX_PROJECT_NAME_LENGTH } from 'magma/common/constants';
import { Permission, ProjectType, Feature } from 'magma/common/interfaces';
import { faCheck } from 'magma/generated/fa-icons';
import { ProjectService } from 'services/projects.service';
import { TeamMembersService } from 'services/team-members.service';
import { TeamService } from 'services/team.service';
import { ProjectShareTypes, TEAM_PROJECT_PRIVILIEGES, TEAM_PROJECT_PRIVILIEGES_NUMBERS } from 'shared/constants';
import { ProjectData } from 'shared/interfaces';
import { faBookAlt, faHashtag } from 'magma/common/icons';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { BlogService } from '../../../services/blog.service';
import { COMMUNITY_HUB_TEAM_ID } from '../../../../../shared/posts';

@Component({
  selector: 'create-project-modal',
  templateUrl: './create-project-modal.component.pug',
  styleUrls: ['./create-project-modal.component.scss'],
})
export class CreateProjectModalComponent implements OnInit {
  readonly maxLength = MAX_PROJECT_NAME_LENGTH;
  readonly privilieges = TEAM_PROJECT_PRIVILIEGES;
  readonly priviliegeNumbers = TEAM_PROJECT_PRIVILIEGES_NUMBERS;
  readonly checkIcon = faCheck;
  readonly projectTypes = [
    {
      type: ProjectType.Project as any,
      title: 'Project',
      subtitle: 'Create and store folders and drawings',
      icon: faHashtag
    },
    {
      type: ProjectType.ContentPage as any,
      title: 'Content page',
      subtitle: 'Embed articles, videos and links',
      icon: faBookAlt
    },
  ];

  @Input() data!: ProjectData;
  @Output() close = new EventEmitter<ProjectData | undefined>();
  error = '';
  title = '';
  constructor(
    private projectService: ProjectService,
    private teamService: TeamService,
    private teamMemberService: TeamMembersService,
    private features: FeatureFlagService,
    private blogService: BlogService,
  ) { }
  ngOnInit() {
    this.title = this.data._id ? this.data.name : 'New project';
  }
  get team() {
    return this.teamService.getTeam(this.data.team);
  }
  async submit() {
    try {
      const { name, team, shareType, type } = this.data;

      if (!this.isNewProject) {
        await this.projectService.updateProject(this.data._id, { name, shareType }).toPromise();
        const communityHubProject = this.blogService.isCommunityHubProject(this.data._id);
        if (communityHubProject) {
          Object.assign(communityHubProject, this.data);
          this.close.emit(communityHubProject);
        } else {
          const editedProject = this.projectService.getProject(this.data._id);
          if (editedProject) this.close.emit(editedProject);
        }
      } else {
        const newProject = await this.projectService.createNewProject({ name, team, shareType, type });
        if (this.data.team === COMMUNITY_HUB_TEAM_ID) {
          this.blogService.communityHubProjects.push(newProject as ProjectData);
        }
        this.close.emit(newProject);
      }
    } catch (e) {
      this.error = e.message;
    }
  }
  onClose() {
    this.close.emit();
  }
  getLabel(shareType: ProjectShareTypes) {
    return (this.privilieges.find(p => p.value === shareType) ?? this.privilieges[0]).label;
  }
  get isNewProject() {
    return !this.data._id;
  }
  get hasAccess() {
    return this.isNewProject ? this.canCreateProject : this.canUpdateProject;
  }
  get canCreateProject() {
    return this.teamService.hasPermissionFlag(Permission.CanCreateProjects);
  }
  get canUpdateProject() {
    return this.teamService.hasPermissionFlag(Permission.CanUpdateProjects);
  }
  get iconForCurrentProjectType() {
    return this.projectTypes.find(projectType => projectType.type === this.data.type)?.icon;
  }
  setProjectType(type: string) {
    this.data.type = type as ProjectType;
  }
  get hasContentPages() {
    return this.features.isFeatureSupported(Feature.ContentPages);
  }
}
