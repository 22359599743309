import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faSpinner, faTimes } from 'magma-editor/src/ts/common/icons';
import { PublisherId, PublicationStatus, StoryProtocolPublicationPublisher, StoryProtocolPublicationParticipant, ArtworkLicensingData } from 'magma-editor/src/ts/common/interfaces';
import { revisionThumbPath } from 'magma-editor/src/ts/common/clientUtils';
import { UserService } from 'services/user.service';
import { PublishersService } from 'magma/services/publishersService';


@Component({
  selector: 'artwork-pending-license-modal',
  templateUrl: './artwork-pending-license-modal.component.pug',
  styleUrls: ['./artwork-pending-license-modal.component.scss'],
})
export class ArtworkPendingLicenseModal {
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly spinnerIcon = faSpinner;

  constructor(private userService: UserService, private publishers: PublishersService) { }

  @Output() close = new EventEmitter<{ license: ArtworkLicensingData | undefined } | undefined>();
  @Input() set data({ revision, status }: any) {
    this.revision = revision; // if this is licensed

    if (this.revision) {
      const storyProtocolInfo = this.revision.publishedIn?.StoryProtocol;
      this.thumb = new URL(revisionThumbPath(revision.shortId, revision.cacheId), window.location.origin).href;
      this.name = storyProtocolInfo?.artworkName || 'Unknown artwork name';
      this.publisher = storyProtocolInfo ? storyProtocolInfo.publisher : undefined;
      this.participants = storyProtocolInfo?.participants || [];
      this.commercial = !!storyProtocolInfo?.license.isCommercial;

      if (status) {
        this.updateUserStatus(status === PublicationStatus.Accepted ? true : false)
        .catch(e => DEVELOPMENT && console.error(e));
      }
    } else {
      DEVELOPMENT && console.error('ArtworkPendingLicenseModal: Revision not found');
    }
  }

  name = '';
  participants: StoryProtocolPublicationParticipant[] = [];
  publisher: StoryProtocolPublicationPublisher | undefined;
  thumb = '';
  commercial = false;
  revision: ArtworkLicensingData['revision'] | undefined;
  isLoading = { accept: false, reject: false };
  
  get isPublisher() {
    return this.user?._id === this.publisher?._id;
  }

  get publisherName() {
    return this.publisher?.name || 'Publisher';
  }

  get title() {
    return this.isPublisher ? 'Registration process in progress' : `${this.publisherName} is requesting to register artwork`;
  }

  get user() {
    return this.userService.user;
  }

  get usersList() {
    return this.participants.filter(u => u?.accountId !== this.user?._id);
  }

  get hasStatusSubmited() {
    const participant = this.participants.find(p => p.accountId === this.user?._id);
    const status = participant?.status;
    return !participant || (status === PublicationStatus.Accepted || status === PublicationStatus.Rejected);
  }

  userPercentage(userId: string) {
    const userInfo = this.participants.find(u => u?.accountId === userId);
    return userInfo ? userInfo.percentage : 0;
  }

  userStatus(status: number) {
    switch (status) {
      case PublicationStatus.Pending: return 'Pending';
      case PublicationStatus.Accepted: return 'Approved';
      case PublicationStatus.Rejected: return 'Rejected';
      default:
        break;
    }
  }

  isUserApproved(status: number) {
    return status === PublicationStatus.Accepted;
  }

  isUserRejected(status: number) {
    return status === PublicationStatus.Rejected;
  }

  async updateUserStatus(approve = true) {
    if (this.revision && !this.isLoading.accept && !this.isLoading.reject) {
      this.isLoading = { accept: approve, reject: !approve };
      const newStatus = approve ? PublicationStatus.Accepted : PublicationStatus.Rejected;
      const data = await this.publishers.updateUserPublicationStatus(PublisherId.StoryProtocol, this.revision?.shortId.toString(), newStatus);
      this.isLoading = { accept: false, reject: false };
      this.close.emit({ license: data.licensing });
    }
  }

  cancel() {
    if (this.revision) {
      this.publishers.cancelPublication(PublisherId.StoryProtocol, this.revision?.shortId)
      .then(() => this.close.emit({ license: undefined }))
      .catch(e => DEVELOPMENT && console.error(e));
    }
  }
}
