import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faFolderPlus, faChevronRight, faInfoCircle } from 'magma/common/icons';
import { storageGetJson, storageSetJson } from 'magma/services/storage';
import { ModalService } from 'services/modal.service';
import { HiddenProjectsDescriptions } from 'shared/interfaces';

interface PageNav {
  title: string;
  routerLink: string;
}

// TODO: remove this component, instead just use breadcrumbs
@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.pug',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  newFolderIcon = faFolderPlus;
  faChevronRight = faChevronRight;
  infoIcon = faInfoCircle;

  @Input() showRightItems = true;
  @Input() loading = false;
  @Input() pageTitle = '';
  @Input() navs: PageNav[] = [];
  @Input() hiddenProjectDescription: string | boolean | null = false;
  @Input() projectId: string | null = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modals: ModalService,
  ) {
  }

  async newFolder() {
    const parentFolderId = this.activatedRoute.snapshot.queryParams.folder;
    await this.modals.createNewFolder(parentFolderId);
  }

  showProjectDescription() {
    if (!this.projectId) return;
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions') ?? { ids: {} };
    hiddenProjects.ids[this.projectId] = false;
    storageSetJson('hidden-project-descriptions', hiddenProjects);
  }
}
