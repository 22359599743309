import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  EDUCATION_LINK, MAX_SESSIONS_FREE, MAX_IMAGE_LAYERS_FREE, MAX_IMAGE_LAYERS_PRO, MAX_SESSIONS_BLAZE,
  MAX_USERS_PER_DRAWING_FREE, MAX_USERS_PER_DRAWING_PRO, DAY
} from 'magma/common/constants';
import { voiceChatUrl } from 'magma/common/data';
import { Analytics, BillingInterval, Permission } from 'magma/common/interfaces';
import { sendGAEvent } from 'magma/common/utilsAnalytics';
import { CouponService } from 'magma/services/couponService';
import { ErrorReporter } from 'magma/services/errorReporter';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { SubscriptionService } from 'magma/services/subscription';
import { ToastService } from 'magma/services/toast.service';
import { Subject, auditTime, tap } from 'rxjs';
import { BillingService } from 'services/billing.service';
import { ModalService } from 'services/modal.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamsQuery } from 'services/team.query';
import { UserService } from 'services/user.service';
import {
  BlazePlan, FusionPlan, SubscriptionPlanType, TEAM_PRODUCTS
} from 'shared/billing';
import { TRIAL_PERIOD_DAYS } from 'shared/constants';
import { ProductPrice, TeamData } from 'shared/interfaces';
import { hasPermissionFlag } from 'shared/utils';

interface InputData {
  openedBy: string,
  type: 'team' | 'individual',
  forceTeamId?: string;
  plan?: SubscriptionPlanType
}

@UntilDestroy()
@Component({
  selector: 'upgrade-modal',
  templateUrl: 'upgrade-modal.component.pug',
  styleUrls: ['upgrade-modal.component.scss'],
})
export class UpgradeModalComponent implements OnInit, OnDestroy {
  readonly educationLink = EDUCATION_LINK;
  enterpriseSalesLink = PRODUCT_INFO.enterpriseSalesLink;

  @Output() close = new EventEmitter<void>();
  @Output() modalClass = new EventEmitter<string>();
  @Output() modalIgnoreKeys = new EventEmitter<boolean>();

  @Input() data: InputData = { openedBy: '', type: 'individual' };

  selectedPlan = BlazePlan;

  period: BillingInterval = 'month';
  coupon: string | undefined = undefined;
  partner: string | undefined = undefined;
  percentOff = 10;
  durationInMonths = 0;
  monthsForFree = 0;
  signingIn = false;
  plan = 1;
  MAX_SESSIONS_FREE = MAX_SESSIONS_FREE;
  MAX_SESSIONS_PRO = MAX_SESSIONS_BLAZE;
  MAX_IMAGE_LAYERS_FREE = MAX_IMAGE_LAYERS_FREE;
  MAX_IMAGE_LAYERS_PRO = MAX_IMAGE_LAYERS_PRO;
  MAX_USERS_PER_DRAWING_FREE = MAX_USERS_PER_DRAWING_FREE;
  MAX_USERS_PER_DRAWING_PRO = MAX_USERS_PER_DRAWING_PRO;
  isYearly = false;
  blazeQuantity = 1;
  autoUpdateBilling = false;
  selectedTeam: TeamData | undefined;
  get noYearlyPlan() {
    // Stripe does not handle durations in months for yearly plans
    return this.durationInMonths && this.durationInMonths !== 12;
  }
  get hasYearlyPlan() {
    return !this.noYearlyPlan;
  }
  get hasVoiceChat() {
    return !!voiceChatUrl;
  }
  get monthlyOff() {
    return this.canUseCoupon ? Math.round(this.selectedPlan.monthly * (100 - this.percentOff) / 100) : this.selectedPlan.monthly;
  }
  get yearlyOff() {
    return this.canUseCoupon ? Math.round(this.selectedPlan.yearly * (100 - this.percentOff) / 100) : this.selectedPlan.yearly;
  }
  get canClose() {
    return !this.partner;
  }
  get hasTrial() {
    return this.data.type === 'individual' && (!this.userService.user?.subscriptionStatus?.status ||
      this.userService.user.subscriptionStatus.status === 'incomplete');
  }
  private interval: any;
  product = TEAM_PRODUCTS.get(this.selectedPlan.code)!;
  teams: TeamData[] = [];
  prices: ProductPrice[] = [];
  sumNow = 0;
  refreshingSummary = false;
  refreshSummary$ = new Subject<void>();
  openingStripe = false;

  brushesPromoImg = require('/assets/brushes-release-promo.png');

  constructor(
    private errorReporter: ErrorReporter,
    private subscriptionService: SubscriptionService,
    private couponService: CouponService,
    private loginSignupService: LoginSignupService,
    private userService: UserService,
    private toastService: ToastService,
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private billingService: BillingService,
    private modalsService: ModalService
  ) {
  }
  async ngOnInit() {
    if (this.subscriptionService.preselectedPeriod && this.userService.user?.userType === 'anonymous') {
      this.period = this.subscriptionService.preselectedPeriod;
      this.signingIn = true;
      this.modalClass.emit('modal-lg');
      this.subscriptionService.preselectedPeriod = undefined;
    }

    if (this.data?.plan) {
      this.selectedPlan = this.data.plan;
    }

    this.teams = this.teamsQuery.getAll();

    if (this.data.forceTeamId) {
      this.selectedTeam = this.teams.find(t => t._id === this.data.forceTeamId);
    } else {
      this.handleCoupon(); // this will ignore coupon for teams, change it after (if) adding team coupons
      this.selectedTeam = this.teamsQuery.getActive();
      if (!this.canUpgradeTeam(this.selectedTeam)) this.selectedTeam = undefined;
      if (!this.canUpgradeUser) this.data.type = 'team';
    }
    this.prices = await this.billingService.getAllProductsPrices();

    this.refreshSummary$.pipe(tap(() => this.refreshingSummary = true), auditTime(1000), untilDestroyed(this)).subscribe(async () => {
      const price = this.period === 'month' ? this.selectedPlan.monthlyCode : this.selectedPlan.yearlyCode;
      const items = [{ price, quantity: this.itemQuantity, product: this.selectedPlan.code }];
      if (this.canUpgrade) {
        await this.getUpcomingInvoice(items);
      }
      this.refreshingSummary = false;
    });
    this.refreshSummary$.next();

    sendGAEvent('Stripe checkout', 'Started Pro checkout', this.data.openedBy);
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  handleCoupon() {
    const coupon = this.couponService.getCoupon();

    if (coupon) {
      this.data.type = 'individual';
      this.couponService.getPromo()
        .then(info => {
          if (info) {
            this.percentOff = info.percentOff;
            this.durationInMonths = info.durationInMonths;
            if (info.percentOff === 100) {
              this.monthsForFree = info.durationInMonths;
            }
            this.partner = info.partner;
            this.coupon = coupon;
            this.modalIgnoreKeys.emit(!this.canClose);
          } else {
            this.coupon = undefined;
          }

          this.loginSignupService.trackEvent(Analytics.OpenUpgradeModal, {
            label: this.coupon ? 'pricing-with-promo' : 'pricing',
            openedBy: this.data.openedBy,
            campaign: this.partner,
          });
        })
        .catch(e => DEVELOPMENT && console.error(e));
    } else {
      this.loginSignupService.trackEvent(Analytics.OpenUpgradeModal, {
        label: 'pricing',
        openedBy: this.data.openedBy,
        campaign: this.partner,
      });
    }
  }
  closeModal() {
    this.close.emit();
  }
  get isFusionPlan() {
    return this.selectedPlan === FusionPlan;
  }
  get userHasPro() {
    return !!this.userService.user?.pro;
  }
  upgrade(period: BillingInterval) {
    if (this.userService.user?.userType === 'anonymous') {
      this.period = period;
      this.signingIn = true;
      this.modalClass.emit('modal-lg');
      clearInterval(this.interval);
    } else {
      void this.upgradeToPro(period);
    }
  }
  signUpFinished() {
    this.signingIn = false;
    this.modalClass.emit('modal-md');
    this.interval = setInterval(() => {
      if (this.userService.user?.userType === 'anonymous') return;

      clearInterval(this.interval);

      if (this.userService.user?.pro) {
        this.closeModal();
      } else {
        void this.upgradeToPro(this.period);
      }
    }, 100);
  }
  change() {
    this.signingIn = false;
  }
  private async upgradeToPro(period: BillingInterval) {
    try {
      sendGAEvent('Stripe checkout', 'Started Pro trial');
      this.openingStripe = true;
      if (this.data.type === 'individual') {
        await this.subscriptionService.upgradeToPro(period, this.coupon);
      } else {
        // this might be outdated, after creating billing data server will request update if it is different than real number
        if (this.selectedTeam) {
          const quantity = this.autoUpdateBilling ? this.teamMembersQuery.getCount() : this.blazeQuantity;
          await this.billingService.upgradeTeam(this.selectedTeam._id, this.period, quantity, this.autoUpdateBilling, this.selectedPlan);
        } else {
          const quantity = this.autoUpdateBilling ? 1 : this.blazeQuantity;
          await this.billingService.upgradeTeam(undefined, this.period, quantity, this.autoUpdateBilling, this.selectedPlan);
        }
      }
    } catch (e) {
      if (e.message === 'Cancelled') return;
      this.errorReporter.reportError('Upgrade to pro failed', e);
      this.toastService.error({ message: e.message });
    }
    this.openingStripe = false;
  }

  changePeriod(period: BillingInterval) {
    this.period = period;
    this.refreshSummary$.next();
  }

  updateBlazeQuantity() {
    this.blazeQuantity = this.blazeQuantity | 0;
    if (this.blazeQuantity < 1) this.blazeQuantity = 1;
    this.refreshSummary$.next();
  }

  increaseLicenseNumber() {
    this.blazeQuantity++;
    this.updateBlazeQuantity();
  }

  selectTeam(t: TeamData | undefined) {
    this.selectedTeam = t;
    this.refreshSummary$.next();
  }

  decreaseLicenseNumber() {
    this.blazeQuantity--;
    this.updateBlazeQuantity();
  }

  canUpgradeTeam(team: TeamData | undefined) {
    if (!team) return false;
    const canManageBilling = hasPermissionFlag(Permission.CanManageTeamBilling, team.userRoles ?? [], null, null);
    return !team.pro && canManageBilling;
  }

  get canUpgrade() {
    return this.data.type === 'team' ? (this.blazeQuantity > 0 && this.blazeQuantity <= this.selectedPlan.planLimit) : true;
  }

  get canUseCoupon() {
    return !!this.coupon && this.isCouponForPlan(this.data.type);
  }

  showTeamSelector() {
    return !this.data.forceTeamId && this.teams.length > 0;
  }

  get canUpgradeUser() {
    return !this.userHasPro;
  }

  isCouponForPlan(plan: 'team' | 'individual') {
    // so far all coupons are for individual subscriptions
    return plan === 'individual';
  }

  updatePlan(plan: string) {
    this.data.type = plan as typeof this.data.type;
    if (this.data.type === 'individual' && this.selectedPlan.code !== BlazePlan.code) { this.selectedPlan = BlazePlan; }
    this.refreshSummary$.next();
  }

  get upgradeLabel() {
    return this.data.type === 'team' || !this.hasTrial ? 'Continue to payment' : 'Start a free 7-day trial';
  }

  get intervalLabel() {
    return this.period === 'month' ? 'month' : 'year';
  }

  get itemQuantity() {
    const teamQuantity = this.autoUpdateBilling ? (this.selectedTeam?.memberCount ?? 1) : this.blazeQuantity;
    return this.data.type === 'team' ? teamQuantity : 1;
  }

  async otherPlans() {
    this.close.emit();
    void this.modalsService.openOtherPlansModal({
      teamId: this.data?.forceTeamId || this.selectedTeam?._id,
      isTeam: this.data.type === 'team',
    });
  }

  get blazeLimitationReachedLink() {
    return `https://magm.ai/blaze-limitation-reached?name=${this.selectedTeam?.slug ?? 'new'}&quantity=${this.blazeQuantity}`;
  }

  get isIntercomAvailable() {
    return !!window.Intercom;
  }

  get trialEnd() {
    const end = new Date(new Date().getTime() + TRIAL_PERIOD_DAYS * DAY);
    return end;
  }

  onAutoBillingChanged() {
    this.refreshSummary$.next();
  }

  private async getUpcomingInvoice(items: { price: string, quantity: number, product: string }[]) {
    if (!this.selectedTeam?.billing?.stripe?.customerId && !this.selectedTeam?.billing?.stripe?.subscriptionId && !items) return;

    const res = await this.billingService.getTeamUpcomingInvoice({
      customerId: this.selectedTeam?.billing?.stripe?.customerId,
      subscriptionId: this.selectedTeam?.billing?.stripe?.subscriptionId,
      items,
      coupon: this.canUseCoupon ? this.coupon : undefined
    });
    this.sumNow = res?.total || 0;
  }
}
