import { ProjectQuery } from 'services/projects.query';
import { TeamMembersService } from 'services/team-members.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { remove, truncate } from 'lodash';
import { entitiesGridSettingsIcon, faArrowDown, faArrowUp, faCheck, faChevronDown, faChevronUp, faCommentAlt, faFilm, faKey, faPlus, farShareAltSquare, gridViewIcon, listViewIcon } from 'magma/common/icons';
import { fromEvent, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PresenceStateService } from 'services/presence-state.service';
import { TeamsQuery } from 'services/team.query';
import { DocumentStatuses, SortBy } from 'shared/constants';
import { EntitiesGridSettings, EntityAuthor, EntityData, GridLayoutType, Participant, RecentEntity } from 'shared/interfaces';
import { EntityType } from 'shared/entities';
import { getDefaultName, getEntityPath, getQueryParamsForFolder } from 'shared/product-info';
import { UserPresence } from 'shared/rpc-interface';
import { createThumbPath } from 'shared/utils';
import { pathToProject } from 'shared/utils-project';
import { belowBreakpointLG, belowBreakpointMD } from 'components/utils';
import { ProjectService } from 'services/projects.service';
import { setOgEntityName } from 'util/util';
import { pushOpenedBy } from 'magma/common/clientUtils';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { EntityToolbarButton } from '../../util/entities-toolbar';
import { TeamService } from 'services/team.service';
import { Permission, SortOrders } from 'magma/common/interfaces';
import { RouterService } from 'services/router.service';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { EntitiesService } from 'services/entities.service';
import moment from 'moment';
import { ManageService } from 'magma/services/manageService';
import { UserService } from 'services/user.service';
import { times } from 'magma/common/utils';
import { PresenceService } from 'services/presence.service';
import { invalidEnumReturn } from 'magma/common/baseUtils';

export interface EntityGridToolbarEvent {
  button: EntityToolbarButton;
  entity: EntityData | EntityData[];
}

interface MouseEventWithEntity {
  event: MouseEvent;
  entity: EntityData;
}

enum SortColumns {
  NAME = 'name',
  SIZE = 'size',
  LAST_ACTIVE = 'openedAt',
  LAST_EDITED = 'lastModified',
  CREATED = 'createdAt',
  REMOVED = 'removedAt',
}

export interface EntityGridSettings {
  page: number;
  size: number;
  sortColumn: SortColumns;
  sortOrder: SortOrders;
  layout: GridLayoutType;
}

export const DEFAULT_ENTITY_GRID_SETTINGS: EntityGridSettings = {
  page: 1,
  size: 10,
  sortColumn: SortColumns.LAST_ACTIVE,
  sortOrder: SortOrders.Descending,
  layout: GridLayoutType.List,
};

const DEFAULT_ARTDESK_COLUMNS = ['participants', 'created'];
const DEFAULT_SEARCH_PAGE_COLUMNS = ['created'];
const DEFAULT_BIN_FOLDER_COLUMNS = ['removed-by', 'days-auto-deletion', 'size'];

const defaultSortOptions = [
  { key: SortColumns.NAME, value: 'Alphabetical' },
  { key: SortColumns.LAST_ACTIVE, value: 'Last Active' },
  { key: SortColumns.CREATED, value: 'Date created' },
  { key: SortColumns.LAST_EDITED, value: 'Last Edited' },
  { key: SortColumns.SIZE, value: 'Size' },
];

const binSortOptions = [
  ...defaultSortOptions,
  { key: SortColumns.REMOVED, value: 'Days to Auto-deletion' },
];

@UntilDestroy()
@Component({
  selector: 'entity-grid',
  templateUrl: './entity-grid.component.pug',
  styleUrls: ['./entity-grid.component.scss'],
})
export class EntityGridComponent implements AfterViewInit, AfterContentChecked {
  readonly farShareAltSquare = farShareAltSquare;
  readonly passwordIcon = faKey;
  readonly faFilm = faFilm;
  readonly entityType = EntityType;
  readonly faCommentAlt = faCommentAlt;
  readonly faPlus = faPlus;
  readonly faChevronDown = faChevronDown;
  readonly faChevronUp = faChevronUp;
  readonly checkIcon = faCheck;
  readonly entitiesGridSettingsIcon = entitiesGridSettingsIcon;
  readonly gridViewIcon = gridViewIcon;
  readonly listViewIcon = listViewIcon;
  readonly arrowUp = faArrowUp;
  readonly arrowDown = faArrowDown;

  SortColumns = SortColumns;

  mouseDownSubject$ = new Subject<MouseEventWithEntity>();
  mouseUpSubject$ = new Subject<MouseEventWithEntity>();

  selectedEntities: EntityData[] = [];
  onDragOverId = '';
  dragTop = 0;
  dragLeft = 0;
  markedForSelection: EntityData | null = null;
  clickPosition: { x: number, y: number } | null = null;
  moveForDragThreshold = 10;
  supportedEntities = PRODUCT_INFO.entities; // TODO: make it an input
  dragEntityCount = 0;
  dragTitle = '';
  dragType = '';
  clickWasInside = false;
  visibleItems: boolean[] = [];

  @Input() presence: Map<string, Observable<UserPresence[]>> | null = null;
  @Input() enableDragDrop = false;
  @Input() showBreadcrumb = false;
  @Input() showDocumentStatus = false;
  @Input() allowCreatingEntityWhenEmpty = true;
  @Input() isGridReadOnly = false;
  @Input() highlightItem = '';
  @Input() isSearchPage = false;
  @Input() fetchingData = false;
  @Input() canFetchMore = false;
  @Input() isBinFolder = false;
  @Input() reachedStorageLimit = false;
  @Input() entitiesTotal = this.entities?.length || 0;
  @Input() isMobile = false;
  @Input() entityGridSettings = DEFAULT_ENTITY_GRID_SETTINGS;
  @Input() recentEntities: RecentEntity[] | null = null;
  @Input() loadingIndicatorForEntity: any;
  @Input() showRecentEntities: boolean | null = false;
  @Input() isUserSettingsLoading = false;

  @Output() dropped = new EventEmitter<EntityData>();
  @Output() entityAction = new EventEmitter<EntityGridToolbarEvent>();
  @Output() documentStatusChanged = new EventEmitter<{ entity: EntityData, status: DocumentStatuses }>();
  @Output() gridSettingsChange = new EventEmitter<Partial<EntityGridSettings | EntitiesGridSettings>>();

  @ViewChild('dataGridContainer', { static: true }) dataGridContainer: ElementRef<HTMLElement> | null = null;
  @ViewChild('gridBody', { static: true }) gridBody: ElementRef<HTMLElement> | null = null;
  private binFolder: HTMLElement | null = null;
  selectEntityOnClick = false;

  displayedColumns = new Set<string>();
  activeEntity: string | undefined = undefined;
  allowedPageSizes = [10, 25, 50, 100];
  moreEntitiesToShow = false;
  allowOpenEntity = true;
  searchQuery = '';
  userDefaultColumns: { [key: string]: string[] | undefined } = {};

  get sortByOptions() {
    return this.isBinFolder ? binSortOptions : defaultSortOptions;
  }

  get pageSize() {
    return this.entityGridSettings.size;
  }

  get currentPage() {
    return this.entityGridSettings.page;
  }

  get numberOfColumns() {
    const defaultColumns = (this.isBinFolder || this.isSearchPage) ? 3 : 2;
    return this.isMobileView ? 2 : this.displayedColumns.size + defaultColumns;
  }

  get isGridView() {
    return this.entityGridSettings.layout === GridLayoutType.Grid && !this.isEmpty;
  }

  get isGridScrolled() {
    const scrolledBy = this.gridBody?.nativeElement.scrollTop || 0;
    return scrolledBy > 10;
  }

  get loadingCells() {
    const ret: number[] = [];

    for (let index = 0; index < this.numberOfColumns; index++) {
      ret.push(index);
    }
    return ret;
  }

  get isEmpty() {
    return this.entities && this.entities.length === 0;
  }

  get numberOfPages() {
    return Math.ceil(this.entitiesTotal / this.entityGridSettings.size);
  }

  isColumnDisplayed(key: string) {
    return !this.isEmpty && this.displayedColumns.has(key);
  }

  changeCurrentPage(page: number) {
    this.gridSettingsChange.emit({ page });
  }

  changePageSize(size: number) {
    this.gridSettingsChange.emit({ size, page: 1 });
  }

  changeGridLayout(toGrid = true) {
    this.gridSettingsChange.emit({ layout: toGrid ? GridLayoutType.Grid : GridLayoutType.List });
  }

  get isEntitiesPage() {
    return !this.isBinFolder && !this.isSearchPage;
  }

  get loadingBars() {
    return times(this.currentSkeletonBars() * 2, i => i + 1);
  }

  currentSkeletonBars() {
    return this.currentPage !== this.numberOfPages ? this.pageSize : Math.ceil(this.entitiesTotal % this.pageSize);
  }

  calculateMaxHeight() {
    const windowHeight = window.innerHeight ? `${window.innerHeight}px` : '100vh';
    const element = document.getElementsByClassName('app-entities-page')[0];
    let offset = this.isSearchPage ? 250 : this.isBinFolder ? 150 : 120;
    offset += 50;
    let childElements = element?.children || [];
    let sum = 0;
    for (let i = 0; i < childElements.length; i++) {
      sum += childElements[i].clientHeight;
    }

    // TODO: remove min-height once the description box is on the sides

    return { 'max-height': `calc(${windowHeight} - ${sum + offset}px)`, 'min-height': '200px' };
  }

  toggleColumn(key: string) {
    if (this.isUserSettingsLoading) return;
    if (this.isColumnDisplayed(key)) this.displayedColumns.delete(key);
    else this.displayedColumns.add(key);
    const columns = Array.from(this.displayedColumns);
    const change = this.isBinFolder ? { binColumns: columns } : this.isSearchPage ? { searchColumns: columns } : { defaultColumns: columns };
    this.gridSettingsChange.emit({ ...change });
  }

  getAuthorForEntity(entity: EntityData): EntityAuthor {
    return entity.author as any as EntityAuthor;
  }

  getSortOrder(column: string) {
    return this.entityGridSettings.sortColumn === column && this.entityGridSettings.sortOrder;
  }

  changeSortByOrder(ascending = false) {
    this.gridSettingsChange.emit({ sortOrder: ascending ? SortOrders.Ascending : SortOrders.Descending, page: 1 });
  }

  updateSortColumn(column: SortColumns, sortOrder?: string) {
    if (!this.fetchingData && this.entities.length) {
      if (sortOrder) {
        this.entityGridSettings.sortOrder = sortOrder === 'asc' ? SortOrders.Ascending : SortOrders.Descending;
      }
      const currentSortColumn = this.entityGridSettings.sortColumn;
      const currentSortOrder = this.entityGridSettings.sortOrder;

      if (sortOrder) {
        this.gridSettingsChange.emit({ sortColumn: column, sortOrder: currentSortOrder === SortOrders.Descending ? SortOrders.Ascending : SortOrders.Descending, page: 1 });
      } else if (column !== currentSortColumn) {
        this.gridSettingsChange.emit({ sortColumn: column, sortOrder: SortOrders.Descending, page: 1 });
      } else {
        this.gridSettingsChange.emit({ sortOrder: currentSortOrder === SortOrders.Descending ? SortOrders.Ascending : SortOrders.Descending, page: 1 });
      }
    }
  }

  getParticipantsList(participants: (string | EntityAuthor | undefined)[]) {
    return participants.filter((p): p is EntityAuthor => !!p).map(p => p as any as Participant);
  }

  getParticipant(participant: EntityAuthor | string | undefined) {
    return participant as any as Participant;
  }

  getProjectName(name: string | undefined) {
    // workaround: remove once 'copy copy' is fixed
    const maxLength = 50;

    if (!name) return name;
    return truncate(name, { length: maxLength });
  }

  constructor(
    private router: Router,
    private routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private teamsQuery: TeamsQuery,
    private projectService: ProjectService,
    public dragDropService: EntityDragDropNotificationService,
    private teamService: TeamService,
    private teamMemberService: TeamMembersService,
    private entitiesService: EntitiesService,
    private projectQuery: ProjectQuery,
    private manage: ManageService,
    public userService: UserService,
    private presenceService: PresenceService,
    private changeDetector: ChangeDetectorRef,
    _presenceStateService: PresenceStateService,// included to force tracking presence
  ) {
    this.dragDropService.isDragging$
      .pipe(
        distinctUntilChanged(),
        untilDestroyed(this),
      ).subscribe(isDragging => {
        if (isDragging) {
          this.dragDropService.dragStartSubject.next(this.selectedEntities);
          this.dragEntityCount = this.selectedEntities.length;
          this.dragTitle = this.selectedEntities[0]?.name ?? '';
          this.dragType = this.selectedEntities[0]?.type ?? '';
          this.allowOpenEntity = false;
        } else {
          this.clearSelections();
          this.allowOpenEntity = true;
        }
      });

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map(() => {
        this.updateDisplayedColumns();
        this.clearSelections();
      }),
      untilDestroyed(this),
    ).subscribe();

    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      const { defaultColumns, binColumns, searchColumns } = user?.gridSettings || {};
      this.userDefaultColumns = { defaultColumns, binColumns, searchColumns };
      this.updateDisplayedColumns();
    });

    this.activatedRoute.queryParams
      .subscribe(params => {
        const { comments, q } = params;
        this.searchQuery = q;
        if (comments) {
          this.displayedColumns.add('comments');
        }
      });

    this.setupDragAndDropEvents();
  }

  get isMobileView() {
    return belowBreakpointLG() || this.isMobile;
  }

  updateDisplayedColumns() {
    const { defaultColumns, binColumns, searchColumns } = this.userDefaultColumns || {};
    const queryParams = this.activatedRoute.snapshot.queryParams;

    this.displayedColumns.clear();
    let columns: string[] = queryParams['comments'] ? ['comments'] : [];
    if (this.isSearchPage) {
      columns = [...columns, ...(searchColumns || DEFAULT_SEARCH_PAGE_COLUMNS)];
    } else if (this.isBinFolder) {
      columns = binColumns || DEFAULT_BIN_FOLDER_COLUMNS;
    } else {
      columns = defaultColumns || DEFAULT_ARTDESK_COLUMNS;
    }
    columns.forEach(c => this.displayedColumns.add(c));
  }

  ngAfterViewChecked() {
    // Check the condition here and update moreEntitiesToShow
    const element = this.gridBody?.nativeElement;
    const newMoreEntitiesToShow = !!element && element.scrollHeight > element.clientHeight;

    // Only update if the value has changed to avoid the error
    if (newMoreEntitiesToShow !== this.moreEntitiesToShow) {
      this.moreEntitiesToShow = newMoreEntitiesToShow;
    }

    this.checkEntitiesVisibility();

    this.changeDetector.detectChanges();
  }

  get isReadOnly() {
    return this.teamService.hasPermissionFlag(Permission.CanUpdateEntities);
  }

  get canCreateEntities() {
    return this.teamService.hasPermissionFlag(Permission.CanCreateEntities, this.project, undefined);
  }

  get dragGhostTransform() {
    return `translate(${this.dragLeft}px, ${this.dragTop}px)`;
  }

  get sortedBy() {
    const { sortColumn } = this.entityGridSettings;
    let name = undefined;

    if (this.sortByOptions.some(option => option.key === sortColumn)) {
      name = this.sortByOptions.find(option => option.key === sortColumn)?.value;
    }

    return name;
  }

  sortedByComment(column: SortColumns, order: string) {
    const ascending = order === 'asc';

    switch (column) {
      case SortColumns.NAME:
        return ascending ? '(A-Z)' : '(Z-A)';
      case SortColumns.CREATED:
      case SortColumns.REMOVED:
      case SortColumns.LAST_ACTIVE:
      case SortColumns.LAST_EDITED:
        return ascending ? 'ascending' : 'descending';
      case SortColumns.SIZE:
        return ascending ? 'low to high' : 'high to low';
      default:
        return invalidEnumReturn(column, '');
    }
  }

  get isSortByAscending() {
    return this.entityGridSettings.sortOrder === SortOrders.Ascending;
  }


  folderId$ = this.activatedRoute.queryParams.pipe(map(params => params.folder));
  folder$ = this.folderId$.pipe(switchMap(folderId => folderId ? this.entitiesService.get(folderId) : of(undefined)));

  activeTeam$ = this.teamsQuery.selectActive();
  teamId$ = this.activeTeam$.pipe(map(team => team?._id));
  projectId$ = this.projectService.projectId$;

  get project() {
    return this.projectQuery.getActive();
  }

  @HostListener('click')
  clickInside() {
    this.clickWasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.clickWasInside) {
      this.activeEntity = undefined;
      this.clearSelections();
      this.markedForSelection = null;
    }
    this.clickWasInside = false;
  }

  @HostListener('window:resize')
  resize() {
    this.checkEntitiesVisibility();
  }

  setupDragAndDropEvents() {
    const mouseUpDocument$ = fromEvent<MouseEvent>(document, 'mouseup').pipe(
      tap((event) => this.onDocumentMouseUp(event)),
      untilDestroyed(this),
    );

    const mouseUpEntityGrid$ = this.mouseUpSubject$.pipe(
      filter(({ event }) => this.filterGeneralDragging(event)),
      tap(({ event, entity }) => this.onMouseUp(event, entity)),
    );

    const mouseUp$ = merge(
      mouseUpDocument$,
      mouseUpEntityGrid$,
    ).pipe(tap(() => {
      this.dragDropService.isDragging$.next(false);
      this.clickPosition = null;
    }));

    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove').pipe(
      filter(event => this.filterDragTreshold(event)),
      tap(event => this.onMouseMove(event)),
      takeUntil(mouseUp$),
    );

    this.mouseDownSubject$.pipe(
      filter(({ event }) => this.filterGeneralDragging(event)),
      tap(({ event, entity }) => this.onMouseDown(event, entity)),
      switchMap(() => mouseMove$),
      untilDestroyed(this),
    ).subscribe();
  }

  private entityObservable?: Subscription;

  @Input()
  get entities() {
    return this._entities;
  }

  set entities(entityList: EntityData[]) {
    this._entities = entityList;
    this.entityObservable?.unsubscribe();
    this.entityObservable = this.presenceService.monitorPresenceOnEntities(entityList.map(e => e._id))
      .pipe(untilDestroyed(this))
      .subscribe(([entityId, presence]) => {
        this._entities = this._entities.reduce((acc: EntityData[], entity) => {
          if (entity._id === entityId) {
            entity.isLive = !!Object.values(presence).length;
          }
          acc.push(entity);
          return acc;
        }, []);
      });

    if (this.highlightItem) {
      const foundEntity = this.entities?.find((entity) => entity._id === this.highlightItem);
      if (foundEntity) {
        this.selectEntity(foundEntity);
      }
    }

    this.checkEntitiesVisibility();
  }
  _entities: EntityData[] = [];

  ngAfterViewInit() {
    this.binFolder = document.getElementById('binFolder');
    this.updateDisplayedColumns();
    this.checkEntitiesVisibility();
  }

  get participantNumber() {
    const layoutCount = this.isGridView ? -1 : 0;
    if (belowBreakpointMD()) {
      return 3 + layoutCount; // 160px
    }
    if (belowBreakpointLG()) {
      return 4 + layoutCount; // 180px
    }
    return 5 + layoutCount;
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  entityTrackBy(index: number, item: EntityData) {
    return item._id;
  }

  getDefaultName(type: string) {
    return getDefaultName(type);
  }

  openIfDragDropDisabled(entity: EntityData) {
    this.activeEntity = entity._id;
    if (!this.enableDragDrop) {
      this.openEntity(null, entity);
    }
  }

  openEntity(event: Event | null, entity: EntityData) {
    if (this.isBinFolder) return;
    event?.stopImmediatePropagation();

    if (entity.type !== EntityType.Folder) {
      setOgEntityName(entity.name!);
      pushOpenedBy(entity.shortId, 'my-artworks');
    }

    this.routerService.navigateToEntity(entity, 'entities-page');
  }

  private onMouseDown(event: MouseEvent, entity: EntityData) {
    this.markedForSelection = entity;

    window.getSelection()?.removeAllRanges();

    this.clickPosition = { x: event.clientX, y: event.clientY };
  }

  onMouseUpOnGrid(event: MouseEvent) {
    this.onDragOverId = '';
  }

  private onMouseUp(event: MouseEvent, entity: EntityData) {
    if (this.selectEntityOnClick) return;
    if (this.dragDropService.isDragging$.value && entity.type === EntityType.Folder && !this.isEntitySelected(entity)) {
      this.dropped.emit(entity);

      this.onDragOverId = '';
      this.clearSelections();

      return;
    }

    if (entity._id?.toString() === this.markedForSelection?._id?.toString()) {
      if (event.ctrlKey || event.metaKey) {
        if (!this.isEntitySelected(entity)) this.addEntityToSelectedEntitiesList(entity);
        else this.deselectEntity(entity);
      } else if (event.shiftKey) {
        const displayedEntities = this.isGridView ? document.getElementsByClassName('entity-grid-card') : document.getElementsByTagName('tr');

        if (!this.entities) return;

        const lastSelectedEntity =
          this.selectedEntities && this.selectedEntities.length > 0 ?
            this.selectedEntities[this.selectedEntities.length - 1] :
            null;

        let lastSelectedEntityIndex = 0;
        let currentlySelectedEntityIndex = 0;

        if (lastSelectedEntity) {
          lastSelectedEntityIndex = [...displayedEntities].findIndex((el) => el.id === lastSelectedEntity._id);
          currentlySelectedEntityIndex = [...displayedEntities].findIndex((el) => el.id === entity._id);

          const startIndex = lastSelectedEntityIndex > currentlySelectedEntityIndex ?
            currentlySelectedEntityIndex :
            lastSelectedEntityIndex;
          const endIndex = lastSelectedEntityIndex > currentlySelectedEntityIndex ?
            lastSelectedEntityIndex :
            currentlySelectedEntityIndex;

          if (startIndex > -1 && endIndex > startIndex) {
            for (let index = startIndex; index <= endIndex; index++) {
              let correctIndex = this.entities.findIndex((entity) => entity._id === displayedEntities[index].id);
              this.addEntityToSelectedEntitiesList(this.entities![correctIndex]);
            }
          }
        } else {
          this.selectEntity(entity);
        }
      } else {
        this.selectEntity(entity);
      }
    }

    this.markedForSelection = null;
  }

  onMouseEnter(entity: EntityData) {
    if (this.enableDragDrop && this.dragDropService.isDragging$.value && entity.type === EntityType.Folder && !this.isEntitySelected(entity)) {
      this.onDragOverId = entity._id;
    }
  }

  onMouseLeave(entity: EntityData) {
    if (this.onDragOverId === entity._id) {
      this.onDragOverId = '';
    }
  }

  selectEntity(entity: EntityData) {
    this.selectedEntities = [entity];
  }

  addEntityToSelectedEntitiesList(entity: EntityData, toggle = false) {
    if (!this.isEntitySelected(entity)) {
      this.selectedEntities.push(entity);
    } else if (toggle) {
      this.deselectEntity(entity);
    }
  }

  addRangeToSelectedEntitiesList(untilEntity: EntityData) {
    this.selectedEntities.push(untilEntity);
  }

  deselectEntity(entity: EntityData) {
    remove(this.selectedEntities, e => e._id === entity._id);
  }

  isEntitySelected(entity: EntityData) {
    return !!this.selectedEntities.some(e => e._id === entity._id);
  }

  filterDragTreshold(event: MouseEvent) {
    const xMoved = Math.abs(event.clientX - (this.clickPosition?.x ?? 0));
    const yMoved = Math.abs(event.clientY - (this.clickPosition?.y ?? 0));
    return xMoved > this.moveForDragThreshold || yMoved > this.moveForDragThreshold;
  }

  filterGeneralDragging(event: MouseEvent) {
    return this.enableDragDrop && event.button === 0;
  }

  onMouseMove(event: MouseEvent) {
    if (this.markedForSelection) {
      this.addEntityToSelectedEntitiesList(this.markedForSelection);
      this.markedForSelection = null;
    }
    this.dragDropService.isDragging$.next(true);
    this.dragLeft = event.clientX + 10;
    this.dragTop = event.clientY + 15;
    window.getSelection()?.removeAllRanges(); // Needed for Safari
  }

  hasParent(element: HTMLElement | null, htmlClassName: string): boolean {
    if (!element || element.classList.contains('datagrid-host')) {
      return false;
    }

    if (element.classList.contains(htmlClassName)) {
      return true;
    }

    return this.hasParent(element.parentElement, htmlClassName);
  }

  onDocumentMouseUp(event: MouseEvent) {
    const isMouseEventInsideDataGrid = this.dataGridContainer?.nativeElement.contains(event.target as any);
    const isMoveToBin = this.binFolder?.contains(event.target as Node);

    if (isMoveToBin) {
      this.onAction(this.selectedEntities, { id: 'bin' });
    }
    if (!isMouseEventInsideDataGrid) {
      this.clearSelections();
    }
  }

  onDocumentMouseDown(event: MouseEvent) {
    const isMouseEventInsideDataGrid = this.dataGridContainer?.nativeElement.contains(event.target as any);
    if (!isMouseEventInsideDataGrid) {
      this.clearSelections();
    }
  }

  @HostListener('document:keyup', ['$event'])
  onDocumentKeyUp(event: KeyboardEvent) {
    if (!this.enableDragDrop) {
      return;
    }

    if (event.key === 'Escape') {
      this.clearSelections();
      this.markedForSelection = null;
    }
  }

  isDroppable(entity: EntityData) {
    return entity.type === EntityType.Folder && !this.isEntitySelected(entity);
  }

  clearSelections() {
    this.selectedEntities = [];
  }

  onDocumentStatusChanged(entity: EntityData, status: DocumentStatuses) {
    this.documentStatusChanged.emit({ entity, status });
  }

  onAction(entity: EntityData | EntityData[], button: EntityToolbarButton, menu?: ContextMenu) {
    if (this.reachedStorageLimit) {
      if (button.id === 'clone' || button.id === 'restore') {
        void this.manage.reachedStorageLimit();
        return;
      }
    }
    this.entityAction.emit({ entity, button });
    if (!button.clickedTitle) menu?.close();
  }

  getPresence(entityId: string) {
    return this.presence?.get(entityId);
  }

  thumbPath(entity: EntityData) {
    // TODO: fix for team drawings
    return createThumbPath(entity.shortId, entity.cacheId);
  }

  getTeamNameForEntity(entity: EntityData) {
    return this.teamService.getTeam(entity.team)?.name ?? 'My Artdesk';
  }

  getProjectNameForEntity(entity: EntityData) {
    return typeof entity.project === 'string' ? this.projectService.getProject(entity.project)?.name : entity.project?.name;
  }

  getTeamAvatar(teamId: string) {
    return this.teamService.getTeam(teamId)?.avatar;
  }

  getEntityPath(entity: EntityData) {
    return getEntityPath(entity, this.teamService.getTeam(entity.team));
  }

  getQueryParamsForFolder = getQueryParamsForFolder;

  getLocationPath(entity: EntityData) {
    const team = this.teamService.getTeam(entity.team);
    const project = typeof entity.project === 'string' ? entity.project : entity.project?._id;

    if (team && project) {
      return pathToProject(team.slug, project);
    } else {
      return `/my/${PRODUCT_INFO.home?.route}`;
    }
  }

  getQueryParamsForLocation({ folder }: EntityData) {
    return folder ? { folder } : {};
  }

  getDeletionDate(updateDate: Date | string) {
    const date = new Date(updateDate);
    date.setDate(date.getDate() + 30);
    return moment(date).diff(moment(), 'days');
  }

  openDeletedEntity(entity: EntityData) {
    if (entity.type === 'Folder') {
      this.entityAction.emit({ entity, button: { id: 'restore-folder' } });
    }
  }

  isEntityVisible(index: number): boolean {
    return this.visibleItems[index];
  }

  checkEntitiesVisibility() {
    const element = this.gridBody?.nativeElement;

    if (!element) return;

    this.moreEntitiesToShow = !(element.scrollTop + element.clientHeight === element.scrollHeight);

    const elementRect = element.getBoundingClientRect();

    // Iterate through the items and determine their visibility
    for (let index = 0; index < this.entities.length; index++) {
      const item = document.getElementById(this.entities[index]._id);
      if (!item) continue;

      const itemRect = item.getBoundingClientRect();
      const isTopVisible = itemRect.top >= elementRect.top && itemRect.top <= elementRect.bottom;
      const isBottomVisible = itemRect.bottom <= elementRect.bottom && itemRect.bottom >= elementRect.top;

      // Update the visibility status for the item
      this.visibleItems[index] = isTopVisible || isBottomVisible;
    }
  }

  isFolder(entity: EntityData) {
    return entity.type === EntityType.Folder;
  }

  toggleEntityView(toGrid = false) {
    if (this.isEmpty) return;

    this.changeGridLayout(toGrid);
  }
}
