import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { belowBreakpointMD } from 'components/utils';
import { blazeIcon } from 'magma/common/icons';
import { DEFAULT_SIMPLE_TABLE_PARAMS, SimpleTableColumn, SimpleTableParams, SortOrders } from 'magma/common/interfaces';
import { faCheck, faCopy, faRedo, faTrash } from 'magma/generated/fa-icons';
import { ToastService } from 'magma/services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SimpleTableService } from 'services/simple-table.service';
import { UserService } from 'services/user.service';
import { ApiKeyData } from 'shared/interfaces';
import { toPromise } from 'shared/utils';

@UntilDestroy()
@Component({
  selector: 'account-api',
  templateUrl: './account-api.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-api.component.scss',
  ],
})
export class AccountApiComponent {
  readonly faTrash = faTrash;
  readonly faRedo = faRedo;
  readonly faCheck = faCheck;
  readonly faCopy = faCopy;
  readonly proIcon = blazeIcon;

  showCopied = false;
  apiKeys: ApiKeyData[] = [];
  _apiKeys: ApiKeyData[] = [];
  generateApiTokenModalOpen = false;
  simpleTableParams = {...DEFAULT_SIMPLE_TABLE_PARAMS, sortBy: 'createdAt', sortDirection: SortOrders.Descending};

  columns: SimpleTableColumn[] = [
    { name: 'API Key', inMobileView: true },
    { name: 'Name', key: 'name', sortable: true },
    { name: 'Scope' },
    { name: 'Created', key: 'createdAt', sortable: true },
    { name: 'Actions', inMobileView: true },
  ];
  isLoading = false;

  private reloadApiKeys$ = new BehaviorSubject(null);

  constructor(private userService: UserService, private toastService: ToastService, private simpleTableService: SimpleTableService) {
    this.reloadApiKeys$.pipe(
      switchMap(() => this.userService.getApiKeys()),
      tap(() => this.isLoading = true),
      untilDestroyed(this),
    ).subscribe(value => {
      this._apiKeys = value;
      this.syncTable();
      this.isLoading = false;
    });
  }

  copyTrigger() {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 4000);
  }

  toggleGenerateApiTokenModal() {
    this.generateApiTokenModalOpen = !this.generateApiTokenModalOpen;
  }

  async generateApiKey() {
    try {
      await toPromise(this.userService.generateApiKey());
      this.reloadApiKeys();
    } catch (e) {
      if (e.message?.startsWith('Your email has not been verified')) {
        this.toastService.error({ message: 'Failed to generate API key', subtitle: 'Please verify your email address before generating api keys' });
      } else {
        this.toastService.error({ message: 'Failed to generate API key', subtitle: e.message });
      }
    }
  }

  async regenerateApiKey(key: ApiKeyData) {
    try {
      await toPromise(this.userService.regenerateApiKey(key._id));
      this.reloadApiKeys();
    } catch (e) {
      this.toastService.error({ message: 'Failed to regenerate API key', subtitle: e.message });
    }
  }

  async revokeApiKey(key: ApiKeyData) {
    try {
      await toPromise(this.userService.revokeApiKey(key._id));
      this.reloadApiKeys();
    } catch (e) {
      this.toastService.error({ message: 'Failed to revoke API key', subtitle: e.message });
    }
  }

  reloadApiKeys() {
    this.reloadApiKeys$.next(null);
  }

  get numberOfPages() {
    return Math.ceil((this.simpleTableParams.total || 0) / this.simpleTableParams.pageSize);
  }

  get isMobileView() {
    return belowBreakpointMD();
  }

  sortingTable(data: SimpleTableParams) {
    this.simpleTableParams = { ...data };
    this.syncTable();
  }

  syncTable() {
    const result = this.simpleTableService.sortAndPaginateArray(this._apiKeys, this.simpleTableParams.sortBy, this.simpleTableParams.sortDirection, this.simpleTableParams.currentPage, this.simpleTableParams.pageSize);
    this.apiKeys = result.data;
    this.simpleTableParams.total = result.totalItems;
  }
}
