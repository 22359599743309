import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageService } from 'magma/services/manageService';
import { ToastService } from 'magma/services/toast.service';

export interface FolderNamingModalInput {
  name?: string;
  forRename?: boolean;
  header: string;
  label: string;
}

@Component({
  selector: 'add-drawing-reference-window-modal',
  templateUrl: 'add-drawing-reference-window-modal.component.pug',
})
export class AddDrawingReferenceWindowModal {
  readonly maxLength = 50;
  @Output() close = new EventEmitter<string | undefined>();
  @Input() data: any;
  drawingId = '';

  constructor(private manage: ManageService, private toast: ToastService) { }

  private isValidId(id: string | undefined): boolean {
    // TODO: Add more checks for the id
    return !!id && id.length > 0;
  }

  parseIdFromUrl(urlOrId: string): string | undefined {
    const regex = /\/d\/([^/]+)$/;

    if (urlOrId.includes('/d/')) {
      const match = urlOrId.match(regex);
      const extractedId = match ? match[1] : undefined;
      return this.isValidId(extractedId) ? extractedId : undefined;
    } else {
      return this.isValidId(urlOrId) ? urlOrId : undefined;
    }
  }
  async submit() {
    const drawingId = this.parseIdFromUrl(this.drawingId);

    if (drawingId) {
      if (drawingId.length !== 10) {
        this.toast.error({ message: 'Failed to link drawing', subtitle: 'Unvalid drawing id' });
      } else {
        this.manage.openDrawingReferenceWindow(drawingId).then(e => {
          this.close.emit(drawingId);
        }).catch(e => {
          this.toast.error({ message: 'Failed to link drawing', subtitle: e.message });
          this.onClose();
        });
      }
    } else {
      this.onClose();
    }
  }
  onClose() {
    this.close.emit(undefined);
  }
}
