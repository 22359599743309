import type { Userflow } from 'userflow.js';
import { OnboardingService } from 'magma/services/onboarding.service';
import { UserService } from './user.service';
import { Injectable , NgZone } from '@angular/core';
import { UntilDestroy , untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from './modal.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { options } from 'magma/common/data';
import { storageGetNumber } from 'magma/services/storage';
import { isUserflowId , UserflowEvent, UserFlowId } from 'magma/common/constants';
import { TeamsQuery } from './team.query';
import { EntityData, UserData } from '../../../shared/interfaces';
import { EntityType } from 'shared/entities';
import { findByName } from 'magma/common/baseUtils';
import { setSettingsHideBrushes, setSettingsSliders, DEFAULT_MAIN_TOOLS_ORDER, setSettingsColumns } from 'magma/common/settings';
import { AuthService } from './auth.service';
import { ToastService } from 'magma/services/toast.service';
import { ErrorReporter } from 'magma/services/errorReporter';
import { Settings, ToolId } from 'magma/common/interfaces';
import { distinctUntilChanged } from 'rxjs/operators';
import { applySettingsPartial } from 'magma/services/settingsService';
import { onboardingFlowToReadableString } from '../../../shared/analytics';
import { RealModel } from 'magma/services/real-model';
import { SECOND } from 'magma/common/constants';
import { storageRemoveItem, storageSetNumber } from 'magma/services/storage';
import { clearMask } from 'magma/common/mask';
import { Editor } from 'magma/services/editor';
import { moveLayerToBottom } from 'magma/services/layerActions';
import { ownLayer, selectLayer } from 'magma/services/layerActions';
import { addAndSelectLayer } from 'magma/services/layerActions';
import { AI_RENDER_TYPES, RenderTypeId } from 'magma/common/aiInterfaces';
import { Analytics } from 'magma/common/interfaces';
import { logAction } from 'magma/common/actionLog';
import { scheduleSaveSettings } from 'magma/services/settingsService';

enum UserIdleState {
  Idle = 'idle',
  Active = 'active',
  AwaitingFirstAction = 'awaiting-first-action',
}

const CONFERENCE_MODE_TIMEOUT_STORAGE_KEY = 'conferenceModeTimeout';
const FLOW_RETRY_TIMEOUT = 2.5 * SECOND;
const FLOW_RETRY_MAX_RETIRES = 3;

const LOG = false;
function onboardingLog(...args: any[]) {
  DEVELOPMENT && LOG && console.log(`[onboarding]`, ...args);
}

@Injectable({providedIn: 'root'})
@UntilDestroy()
export class PortalOnboardingService extends OnboardingService {
  private idleState = UserIdleState.Active;
  private idleTimeToLogout = 0;
  private logoutTimeout: NodeJS.Timeout | undefined = undefined;
  private warningTimeout: NodeJS.Timeout | undefined = undefined;
  private readonly flowStartingRetryFlows = new Map<UserFlowId, number>([]);
  private readonly windowEventHandlers = new Map<UserflowEvent, Function>();
  private readonly userflowObjectEventHandlers = new Map<string, (...args: any[]) => void>();

  constructor(
    protected zone: NgZone,
    protected http: HttpClient,
    protected router: Router,
    protected teamsQuery: TeamsQuery,
    protected auth: AuthService,
    private userService: UserService,
    private modals: ModalService,
    private toasts: ToastService,
    private errorReporter: ErrorReporter,
  ) {
    super();
    onboardingLog('Constructing PortalOnboardingService - user.hasOnboarding: ', this.userService.user?.hasOnboarding);

    this.windowEventHandlers.set(UserflowEvent.InvokeConferenceLogout, this.invokeConferenceLogout);
    this.windowEventHandlers.set(UserflowEvent.NavigateToDrawingByName, this.navigateToDrawingByName);
    this.windowEventHandlers.set(UserflowEvent.JumpToDemoDrawing, this.jumpToDemoDrawing);
    this.windowEventHandlers.set(UserflowEvent.SetEditorSettings, this.setEditorSettings);
    this.windowEventHandlers.set(UserflowEvent.CompletedOnboardingFlow, this.completeFlow);
    this.windowEventHandlers.set(UserflowEvent.ClearInpaintingMask, this.clearAiInpaintingMask);
    this.windowEventHandlers.set(UserflowEvent.CreateLayerAtTheBottom, this.createLayerAtTheBottom);
    this.windowEventHandlers.set(UserflowEvent.SelectLayerWithIndex, this.selectLayerWithIndex);
    this.windowEventHandlers.set(UserflowEvent.SelectAiRenderMode, this.selectAiRenderMode);
    this.windowEventHandlers.set(UserflowEvent.EnsureToolsInToolbar, this.ensureToolsInToolbar);
    this.windowEventHandlers.set(UserflowEvent.EnsureTwoColumns, this.ensureTwoColumns);

    this.userflowObjectEventHandlers.set('flowEnded', this.markUserActive);
    this.userflowObjectEventHandlers.set('checklistEnded', this.markUserActive);

    this.userService.user$.pipe(
      distinctUntilChanged((previous, current) => previous?._id === current?._id),
      untilDestroyed(this),
    ).subscribe(async (user) => {
      onboardingLog(`deployedOnboardingFlows: "${(user?.onboardingFlows ?? []).map(onboardingFlowToReadableString).join(', ')}"`);
      if (user && this.auth.loggedIn && this.hasOnboarding) {
        try {
          const noTimeoutsYet = !this.logoutTimeout && !this.warningTimeout;
          if (this.isConferenceMode && noTimeoutsYet) {
            await this.authenticate(user, 'conferenceMode');
            this.revokeUserflowListeners();
            this.setupUserflowListeners();
            this.idleTimeToLogout = storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
            this.scheduleNextIdleTimeouts();
          } else {
            const [onboardingFlowToRun] = user.onboardingFlows ?? [];
            if (onboardingFlowToRun) {
              await this.authenticate(user, 'deployedFlow');
              this.revokeUserflowListeners();
              this.setupUserflowListeners();
              this.startFlow(onboardingFlowToRun);
            }
          }
        } catch (e) {
          DEVELOPMENT && console.error(e);
          this.errorReporter.reportError(e);
        }
      }
    });
  }

  startConferenceMode(idleTimeToLogout: number){
    this.idleTimeToLogout = idleTimeToLogout * SECOND;
    storageSetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY, this.idleTimeToLogout);
    this.performConferenceLogout().catch((e) => {
      this.errorReporter.reportError(e);
    });
  }

  stopConferenceMode() {
    this.idleTimeToLogout = 0;
    storageRemoveItem(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
    this.clearTimeouts();
  }

  private clearTimeouts(){
    this.idleState = UserIdleState.Active;
    this.warningTimeout && clearTimeout(this.warningTimeout);
    this.logoutTimeout && clearTimeout(this.logoutTimeout);
    for (const key of this.flowStartingRetryFlows.keys()) {
      this.flowStartingRetryFlows.set(key, -1);
    }
  }

  private setupUserflowListeners(){
    for (const [event, handler] of this.windowEventHandlers.entries()) {
      window.addEventListener(event as any, handler as any);
    }
    for (const [event, handler] of this.userflowObjectEventHandlers.entries()) {
      this.userflow?.on(event, handler);
    }
    window.addEventListener('beforeunload', () => { this.revokeUserflowListeners(); });
  }

  private revokeUserflowListeners() {
    for (const [event, handler] of this.windowEventHandlers.entries()) {
      window.removeEventListener(event as any, handler as any);
    }
    for (const [event, handler] of this.userflowObjectEventHandlers.entries()) {
      this.userflow?.off(event, handler);
    }
  }

  startFlow(id: UserFlowId, retries = true) {
    if (!this.userflow) return;
    if (this.userflow.isIdentified() || !retries) {
      this.flowStartingRetryFlows.delete(id);
      this.userflow.start(id)
        .then(() => {
          return this.http.post(`/api/onboarding/flow/${id}/start`, { }).toPromise();
        })
        .catch((e) => {
          DEVELOPMENT && console.error(e);
          this.errorReporter.reportError(e);
        });
    } else if (retries) {
      const currentRetries = (this.flowStartingRetryFlows.get(id) ?? 0);
      if (currentRetries < FLOW_RETRY_MAX_RETIRES) {
        if (currentRetries === -1) {
          this.flowStartingRetryFlows.delete(id);
        } else {
          this.flowStartingRetryFlows.set(id, currentRetries + 1);
          setTimeout(() => { this.startFlow(id); }, FLOW_RETRY_TIMEOUT);
        }
      } else {
        this.flowStartingRetryFlows.delete(id);
        throw new Error(`Failed to start flow 3 times (user not identified). Aborting next attempts.`);
      }
    }
  }

  get canStartConferenceMode() {
    return this.hasOnboarding && !!this.userService.isSuperAdmin();
  }

  get isConferenceMode() {
    return !!((!this.userService.user || this.hasOnboarding) && storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY));
  }

  get hasOnboarding() {
    return !!this.userService.user?.hasOnboarding && !!options.userflowToken;
  }

  private get idleTimeToWarn () { return Math.round(this.idleTimeToLogout * 3 / 4); }

  async ensureAuthenticated(){
    if (!this.userflow?.isIdentified()) {
      const user = this.userService.user;
      if (!options.userflowToken) throw new Error('Can\'t ensureUserflow because userflowToken is missing!');
      if (!user) throw new Error('Can\'t ensureUserflow because user is missing!');
      if (!user.hasOnboarding) throw new Error('This user can\'t use onboarding!');
      await this.authenticate(user, 'ensureAuthenticated');
      this.revokeUserflowListeners();
      this.setupUserflowListeners();
    }
  }

  private async authenticate(user: UserData, caller: string) {
    if (!options.userflowToken || !this.hasOnboarding) {
      this.stopConferenceMode();
    } else {
      const version = document.getElementsByTagName('html')[0].dataset.hash;
      if (!this.userflow) {
        logAction(`Loading userflow (caller: ${caller})`);
        this.model?.trackEvent(Analytics.LoadUserflow, { caller, version });
        this.userflow = (await import('userflow.js') as any as { default: Userflow }).default;
      }
      if (!this.userflow) throw new Error('Failed to load userflow.js');
      this.userflow.init(options.userflowToken);
      this.userflow.setBaseZIndex(1100); // to hide userflow behind our modals in particular "you're about to be logged out"
      return this.userflow.identify(user._id, {
        email: user.email,
        domain: window.location.origin,
        teams: this.teamsQuery.getAll().map(t => t.slug).join(','),
        version,
      });
    }
  }

  displayWarningModal() {
    return this.modals.idleLogoutWarning().then((swapAccounts) => {
      if (swapAccounts) {
        return this.performConferenceLogout();
      } else {
        this.clearTimeouts();
        this.scheduleNextIdleTimeouts();
      }
    });
  }

  async performConferenceLogout() {
    this.clearTimeouts();
    await this.userflow?.endAll();
    this.modals.closeAll();
    await this.auth.logout('');
  }

  startConferenceCycle() {
    this.idleTimeToLogout = storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
    this.http.post<{
      data: { email: string; password: string; }
    }>('api/onboarding/start-conference-cycle', {}).toPromise()
      .then((res) => {
        if (!res) throw new Error('Failed to obtain response from start-conference-cycle!');
        return this.auth.login({
          email: res.data.email,
          password: res.data.password,
          rememberMe: false,
        });
      })
      .then(() => {
        setSettingsSliders('full');
        setSettingsHideBrushes(false);
        this.idleState = UserIdleState.AwaitingFirstAction;
        this.scheduleNextIdleTimeouts();
        return this.router.navigate(['/my/artworks']);
      })
      .then(() => this.startFlow(UserFlowId.ConferenceModeChecklist))
      .catch((e) => {
        DEVELOPMENT && console.error(e);
        this.errorReporter.reportError(e);
      });
  }

  private scheduleNextIdleTimeouts() {
    if (this.idleState !== UserIdleState.AwaitingFirstAction) {
      this.idleState = UserIdleState.Idle;
    }

    this.zone.runOutsideAngular(() => {
      window.addEventListener('pointerup', () => {
        this.idleState = UserIdleState.Active;
      }, { once: true });
    });

    this.logoutTimeout = setTimeout(() => {
      if (this.isConferenceMode && this.auth.loggedIn) {
        if (this.idleState === UserIdleState.Idle) {
          this.performConferenceLogout().catch((e) => {
            DEVELOPMENT && console.error(e);
            this.errorReporter.reportError(e);
          });
        } else {
          this.scheduleNextIdleTimeouts();
        }
      }
    }, this.idleTimeToLogout);

    this.warningTimeout = setTimeout(() => {
      if (this.isConferenceMode && this.auth.loggedIn) {
        if (this.idleState === UserIdleState.Idle) {
          this.displayWarningModal().catch((e) => {
            DEVELOPMENT && console.error(e);
            this.errorReporter.reportError(e);
          });
        } else {
          clearTimeout(this.logoutTimeout!);
          this.scheduleNextIdleTimeouts();
        }
      }
    }, this.idleTimeToWarn);
  }

  private readonly markUserActive = () => { this.idleState = UserIdleState.Active; };

  private readonly invokeConferenceLogout = () => {
    this.performConferenceLogout().catch((e) => {
      this.errorReporter.reportError(e);
    });
  };

  private readonly navigateToDrawingByName = async ({ detail: desiredDrawingName }: { detail: string; }) => {
    const team = this.teamsQuery.getActive();
    onboardingLog(`Navigating to drawing by name: '${desiredDrawingName}' (in team '${team?.name})'`);
    if (!team) throw new Error('No active team!');

    const {data: entities} = (await this.http.get(`api/teams/${team._id}/entities`).toPromise()) as {
      data: EntityData[]
    };
    const entity = findByName(entities, desiredDrawingName) as EntityData | undefined;
    if (!entity) throw new Error('Drawing not found!');
    if (entity.type !== EntityType.Drawing) throw new Error('Requested entity is not a drawing!');

    this.router.navigate(['d', entity.shortId]).catch((e) => {
      DEVELOPMENT && console.error(e);
      this.errorReporter.reportError(e);
    });
  };

  private readonly jumpToDemoDrawing = async ({ detail: desiredFlowId }: { detail: UserFlowId; }) => {
    try {
      onboardingLog(`Jumping to demo drawing for flow '${desiredFlowId}'`);
      if (!desiredFlowId) throw new Error(`Missing desiredFlowId in "${UserflowEvent.JumpToDemoDrawing}" event`);
      const shortId = await this.http.get(`/api/onboarding/flow/${desiredFlowId}/drawing`).toPromise();
      await this.router.navigate(['d', shortId]);
    } catch (e) {
      this.toasts.error({message: 'Something went wrong when starting your onboarding flow.'});
      this.errorReporter.reportError('Something went wrong when starting your onboarding flow.', e, {desiredFlowId});
    }
  };

  private readonly setEditorSettings = async ({ detail }: { detail: Partial<Settings> }) => {
    try {
      onboardingLog(`Setting editor settings from userflow`, detail);
      if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SetEditorSettings}"`);
      applySettingsPartial(this.model, detail);
    } catch (e) {
      this.toasts.error({message: 'Something went wrong when continuing your onboarding flow.'});
      this.errorReporter.reportError('Something went wrong when continuing your onboarding flow.', e, {detail});
    }
  };

  private readonly clearAiInpaintingMask = () => {
    onboardingLog(`Clearing Ai inpainting mask`);
    const editor = (this.model as RealModel).editor;
    if (editor) {
      clearMask(editor.aiTool.inpaintMask);
      editor.apply(() => { });
    }
  };

  private readonly completeFlow = async ({ detail: flowId }: { detail: UserFlowId }) => {
    onboardingLog(`Completing flow '${onboardingFlowToReadableString(flowId)}'`);
    if (!isUserflowId(flowId)) throw new Error(`Missing/invalid flowId in "${UserflowEvent.CompletedOnboardingFlow}" event`);
    this.http.post(`/api/onboarding/flow/${flowId}/complete`, { }).toPromise().catch(reportError);
  };

  private readonly createLayerAtTheBottom = async () => {
    onboardingLog(`Creating layer at the bottom`);
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.CreateLayerAtTheBottom}"`);
    const editor = this.model.editor as Editor;
    if (editor.activeLayer) {
      await addAndSelectLayer(editor);
      setTimeout(() => {
        if (editor.activeLayer) {
          moveLayerToBottom(editor, editor.activeLayer);
          editor.apply(() => { });
        }
      }, 300);
    }
  };

  private readonly selectLayerWithIndex = async ({ detail: index }: { detail: number}) => {
    onboardingLog(`Selecting layer with index ${index}`);
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SelectLayerWithIndex}"`);
    const editor = this.model.editor as Editor;
    const layer = editor.drawing.layers[index];
    if (layer) {
      await ownLayer(editor, layer, true);
      selectLayer(editor, layer, true);
    }
  };

  private readonly selectAiRenderMode = ({ detail: desiredRenderType }: { detail: RenderTypeId }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SelectAiRenderMode}"`);
    const editor = this.model.editor as Editor;
    const validRenderTypeId = Object.values(RenderTypeId).includes(desiredRenderType);
    const renderType = AI_RENDER_TYPES.find(t => t.id === desiredRenderType);
    if (!validRenderTypeId || !renderType) {
      DEVELOPMENT && console.warn(`Unrecognised AI render mode - ${desiredRenderType}`);
      return;
    }
    editor.aiTool.selectRenderType(renderType);
  };

  private readonly ensureToolsInToolbar = ({ detail: tools }: { detail: ToolId[] }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.EnsureToolsInToolbar}"`);
    const editor = this.model.editor as Editor;
    const { mainToolsOrder, hiddenToolsOrder } = this.model.settings;

    for (const tool of tools) {
      const indexInHiddenTools = hiddenToolsOrder.findIndex((t) => t === tool);
      if (indexInHiddenTools !== -1) {
        const toolDefaultPosition = DEFAULT_MAIN_TOOLS_ORDER.findIndex((t) => t === tool);
        this.model.settings.mainToolsOrder.splice(toolDefaultPosition, 0, tool);
        this.model.settings.hiddenToolsOrder.splice(indexInHiddenTools, 1);
        scheduleSaveSettings(this.model);
      }
    }
  };

  private readonly ensureTwoColumns = () => {
    setSettingsColumns(true);
  };
}
