import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faTimes, storyprotocolIcon } from 'magma-editor/src/ts/common/icons';
import { DrawingRevision, RequestArtworkRegistrationResponse } from 'magma-editor/src/ts/common/interfaces';

@Component({
  selector: 'requesting-registration-notification-modal',
  templateUrl: './requesting-registration-notification-modal.component.pug',
})
export class RequestingRegistrationNotificationModal {
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly storyprotocolIcon = storyprotocolIcon;

  @Output() close = new EventEmitter<RequestArtworkRegistrationResponse | null>();
  @Input() set data({ revision }: { revision: DrawingRevision }) {
    const { publisher } = revision.publishedIn?.StoryProtocol || {};
    this.publisherName = publisher?.name;
    this.publisherAvatar = publisher?.avatar;
  }

  publisherName: string | undefined;
  publisherAvatar: string | undefined;

  openIPSplit() {
    this.close.emit(RequestArtworkRegistrationResponse.View);
  }

  sendResponse(accept = false) {
    this.close.emit(accept ? RequestArtworkRegistrationResponse.Accept : RequestArtworkRegistrationResponse.Reject);
  }

  cancel() {
    this.close.emit();
  }
}
