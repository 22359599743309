import { Component } from '@angular/core';
import { DAY } from 'magma/common/constants';
import { faInfoCircle, farExclamationCircle, blazeIcon, faUsers } from 'magma/common/icons';
import { BillingService } from 'services/billing.service';
import { ModalService } from 'services/modal.service';
import { PaymentService } from 'services/payment.service';
import { UserService } from 'services/user.service';
import { ToastService } from 'magma/services/toast.service';
import { ErrorReporter } from 'magma/services/errorReporter';
import { delay } from 'magma/common/promiseUtils';
import { BaseBillingComponent } from 'components/base-billing.component';
import { CustomerBillingInfo } from 'shared/interfaces';
import { AppService } from 'services/app.service';
import { BlazePlan, FreePlan, getSubscriptionPlanByEnum } from 'shared/billing';
import { SubscriptionPlan } from 'magma/common/interfaces';

@Component({
  selector: 'account-billing',
  templateUrl: './account-billing.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-billing.component.scss',
  ],
})
export class AccountBillingComponent extends BaseBillingComponent {
  freePlan = FreePlan;
  faUsers = faUsers;
  blazeIcon = blazeIcon;
  infoIcon = faInfoCircle;
  errorIcon = farExclamationCircle;
  cancelAnswers = [
    'I only needed it for one project',
    'It was difficult to learn how to use it',
    'I didn\'t have time to use it',
    'I didn\'t have anyone to draw with',
    'I prefer other drawing tools',
    'It was too expensive',
    'Some important features were missing',
    'Customer service was unsatisfactory',
    `I've returned my drawing tablet`,
    'My drawing tablet was lost / broken',
    'Other',
  ];

  constructor(
    private billingService: BillingService,
    private paymentService: PaymentService,
    private modals: ModalService,
    userService: UserService,
    toastService: ToastService,
    errorReporter: ErrorReporter,
    private appService: AppService,
  ) {
    super(userService, toastService, errorReporter);
  }

  ngOnInit() {
    this.loadBillingData()
      .catch(e => this.showAndReportError(e));
  }

  hasActiveSubscription() {
    return !!this.billingData?.stripe;
  }

  private async loadBillingData() {
    const { billing } = await this.billingService.getUserBillingInformation();
    this.billingData = billing;
    this.billingLoaded = true;
  }
  get plan() {
    return getSubscriptionPlanByEnum(this.user?.pro ?? SubscriptionPlan.Free);
  }
  get inheritedPro() {
    return this.user?.pro && !this.user.proSources?.includes('paid');
  }
  get forcedPro() {
    if (!this.user) return false;

    if (this.user.proSources?.includes('paid')) return false; // allow users to cancel or manage paid subscription

    return this.user.forcePro ||
      (this.user.forceProUntil && this.user.forceProUntil > (new Date().toISOString())) ||
      this.inheritedPro;
  }
  get customerFacingPlanName() {
    return this.billingData?.plan === 'pro' ? 'blaze' : this.billingData?.plan;
  }
  get isSingleUser() {
    return this.billingData?.stripe?.items[0].quantity === 1;
  }
  get usersQuantity() {
    return this.billingData?.stripe?.items[0].quantity ?? 0;
  }
  get isTrial() {
    return !!this.billingData?.trialing;
  }
  get trialDaysLeft() {
    const days = Math.ceil((this.validUntil.getTime() - Date.now()) / DAY);
    return `${days} ${days === 1 ? 'day' : 'days'} left`;
  }
  async changePlan() {
    const newInterval = await this.modals.changePlan(this.billingData?.interval ?? undefined, this.isTrial);

    if (newInterval) {
      try {
        this.isChangingPlan = true;
        const items = [
          {
            id: this.billingData?.stripe?.items[0].id,
            product: BlazePlan.code,
            price: newInterval === 'month' ? BlazePlan.monthlyCode : BlazePlan.yearlyCode,
            quantity: this.billingData?.stripe?.items[0].quantity ?? 1
          },
        ];
        await this.billingService.updateUserSubscription(items);
        await this.loadBillingData();
      } catch (e) {
        this.showAndReportError(e);
      } finally {
        this.isChangingPlan = false;
      }
    }
  }
  async cancelSubscription() {
    const takeEffectDate = this.billingData?.validUntil ?? new Date();
    const cancel = await this.modals.cancelSubscription({ takeEffectDate, trial: this.isTrial });

    if (cancel) {
      try {
        this.isCancellingPlan = true;
        await this.billingService.cancelUserSubscription();
        await this.loadBillingData();
        this.showCancelledSubscription = true;
        this.endOfCancelledSubscription = this.billingData?.validUntil ?? new Date();
      } catch (e) {
        this.showAndReportError(e);
      } finally {
        this.isCancellingPlan = false;
      }
    }
  }
  async restartSubscription() {
    try {
      this.isRestartingPlan = true;
      await this.billingService.restartUserSubscription();
      await this.loadBillingData();
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isRestartingPlan = false;
    }
  }

  // payment method
  async updateCard() {
    try {
      this.hideForms();
      const checkout = await this.billingService.updateUserPaymentInformation();
      this.paymentService.redirectToStripeCheckout(checkout);
    } catch (e) {
      this.showAndReportError(e);
    }
  }

  async retryPayment() {
    try {
      this.isRetrying = true;
      this.hideForms();
      await this.billingService.retryPayment();
      await delay(2000);
      await this.loadBillingData();
      if (!this.isFailed) this.toastService.success({ message: 'Payment succeeded' });
    } catch (e) {
      this.showAndReportError(e);
    } finally {
      this.isRetrying = false;
    }
  }

  async submitBillingInfo(billingInfo: CustomerBillingInfo) {
    await this.submitForm(async () => {
      await this.billingService.updateUserBillingInformation(billingInfo);
      if (this.billingData) this.billingData.billingInfo = { ...billingInfo };
      await this.loadBillingData();
      this.updatingBillingInfo = false;
    }, 'Billing info updated');
  }

  async submitCancelFeedback() {
    const reasons = [...this.cancelChecked];
    const otherIndex = reasons.indexOf('Other');

    if (otherIndex !== -1) {
      reasons[otherIndex] += `: ${this.otherReason}`;
    }

    if (!reasons.length) return;

    await this.billingService.cancelationUserReasons(reasons);
    this.showCancelledSubscription = false;
  }

  async contactUs() {
    await this.appService.onContactSupport();
  }

  createTeam() {
    void this.modals.createTeam();
  }

  upgrade() {
    void this.modals.upgradeModal('account billing', 'individual');
  }
}
