import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { ServerConstant } from 'components/utils';
import { EntitiesQuery } from 'services/entities.query';
import { ProjectQuery } from 'services/projects.query';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { EntityData, Participant, ProjectData, TeamData, TeamType, HiddenProjectsDescriptions, UserData } from 'shared/interfaces';
import { EntityType } from 'shared/entities';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { storageGetBoolean, storageGetJson, storageRemoveItem, storageSetBoolean, storageSetJson } from 'magma/services/storage';
import { ModalService } from 'services/modal.service';
import { faClock, faHashtag, faPlus, faChevronDown, faChevronUp, faPlusCircle, faExclamationTriangle, faUserPlus, faCogs, faSignOutAlt, faPodcast, faInfoCircle, faGlobe, faKey, blazeIcon, sparkIcon, farExclamationTriangle, faTrash, faLockAlt, exclamationIcon, faBookAlt, faMegaphone, xMarkCircleIcon, aiTool, faTimes, faSearch, faChevronRight, faCog } from 'magma/common/icons';
import { UserService } from 'services/user.service';
import { EntityActions } from '@datorama/akita';
import { getLastProject, routeToTeam, userStorageLimit } from 'shared/utils';
import { routeToProject } from 'shared/utils-project';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import type { LicenseJson } from '@codecharm/license-tools';
import { ToastService } from 'magma/services/toast.service';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { contactSupportIntercom } from 'magma/common/utils';
import { PresenceService } from 'services/presence.service';
import { OnlineUsersService } from 'services/online-users.service';
import { TeamMembersService } from 'services/team-members.service';
import { Feature, NOTIFICATION_ARTDESK_ID, Permission, TREE_ARTDESK_ID, ProjectType, SelectMode, SubscriptionPlan } from 'magma/common/interfaces';
import { ManageService } from 'magma/services/manageService';
import { BillingService } from 'services/billing.service';
import { PortalSubscriptionService } from '../../services/subscription.service';
import { ProjectService } from 'services/projects.service';
import { disableCreatingTeams, disableJoiningAndLeavingTeams, disableMyArtdesk } from 'magma/common/data';
import { BlogService } from '../../services/blog.service';
import { COMMUNITY_HUB_TEAM_ID, COMMUNITY_HUB_TEAM_NAME } from '../../../../shared/posts';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { CHANGELOG_LINK } from 'magma/common/constants';
import { isMobile } from 'magma/common/utils';
import { safeInt } from 'magma/common/toolUtils';
import { debounce } from 'lodash';
import { getSubscriptionPlanByEnum } from 'shared/billing';
import { invalidEnumReturn } from 'magma/common/baseUtils';

const homeRouteName = PRODUCT_INFO.home.route;
const { storageReadMoreLink, enterpriseSalesLink, contactUsLink } = PRODUCT_INFO;
const discordInviteLink = PRODUCT_INFO.discordInviteLinks?.drawingBuddiesPage;
const DEFAULT_MAX_MEMBERS_TO_SHOW = 10;

@UntilDestroy()
@Component({
  selector: 'team-selector',
  templateUrl: 'team-selector.component.pug',
  styleUrls: ['team-selector.component.scss'],
})
export class TeamSelectorComponent {
  TeamType = TeamType;
  NOTIFICATION_ARTDESK_ID = NOTIFICATION_ARTDESK_ID;
  TREE_ARTDESK_ID = TREE_ARTDESK_ID;
  @ServerConstant() readonly license?: LicenseJson;
  @Output() close = new EventEmitter<void>();
  readonly storageReadMoreLink = storageReadMoreLink;
  readonly discordInviteLink = discordInviteLink;
  readonly contactUsLink = contactUsLink;
  readonly enterpriseSalesLink = enterpriseSalesLink;
  readonly plusIcon = faPlus;
  readonly cogIcon = faCog;
  readonly downIcon = faChevronDown;
  readonly upIcon = faChevronUp;
  readonly rightIcon = faChevronRight;
  readonly keyIcon = faKey;
  readonly globeIcon = faGlobe;
  readonly warningIcon = faExclamationTriangle;
  readonly warningIcon2 = farExclamationTriangle;
  readonly newProjectIcon = faPlusCircle;
  readonly addUserIcon = faUserPlus;
  readonly settingsIcon = faCogs;
  readonly signOutIcon = faSignOutAlt;
  readonly liveNowIcon = faPodcast;
  readonly recentIcon = faClock;
  readonly projectIcon = faHashtag;
  readonly contentPageIcon = faBookAlt;
  readonly infoIcon = faInfoCircle;
  readonly blazeIcon = blazeIcon;
  readonly freeIcon = sparkIcon;
  readonly trashIcon = faTrash;
  readonly faLock = faLockAlt;
  readonly exclamationIcon = exclamationIcon;
  readonly aiIcon = aiTool;
  readonly dangerIcon = xMarkCircleIcon;
  readonly whatsNewIcon = faMegaphone;
  readonly faGlobe = faGlobe;
  readonly changelogUrl = CHANGELOG_LINK;
  readonly changelogTarget = IS_HOSTED ? '_blank' : '_self';
  readonly DEFAULT_MAX_MEMBERS_TO_SHOW = DEFAULT_MAX_MEMBERS_TO_SHOW;
  readonly closeIcon = faTimes;
  readonly searchIcon = faSearch;
  myDocumentsName = `${PRODUCT_INFO.home.name}`;
  myDocumentsRoute = `/my/${homeRouteName}`;
  activeTeam$ = this.teamsQuery.selectActive();
  teams$ = this.teamsQuery.selectAll();
  teamProjects$ = this.activeTeam$.pipe(
    switchMap((team) => {
      if (!team) return [];

      return this.projectsQuery.selectAll().pipe(
        map(projects => projects.filter(p => p.team === team._id).sort(byOrder)),
      );
    }),
  );
  liveCount = 0;
  isDragging = false;
  dragOverProject: ProjectData | 'my' | undefined = undefined;
  dragOverTeam: string | undefined = undefined;
  actions$ = this.entitiesQuery.selectEntityAction([EntityActions.Add, EntityActions.Remove, EntityActions.Update]);
  canCreateTeams = !disableCreatingTeams;
  canLeaveTeam = !disableJoiningAndLeavingTeams;
  maxOnlineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
  maxOfflineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
  memberTotal = 0;
  notificationCounters: Map<string, number> = new Map();
  selectedEntities: EntityData[] = [];
  memberAvatars: string[] | undefined = undefined;
  enableHomeButtonTransition = false;
  membersIds: string[] = [];
  onlineMembersIds: Set<string> = new Set();
  reload$ = new BehaviorSubject(null);
  teamMembersCollection = new Map<string, Participant>();
  onlineMembers: Participant[] = [];
  offlineMembers: Participant[] = [];
  searchMemberQ = '';

  get isMembersSearch() {
    return !!this.searchMemberQ.length;
  }

  updateDisplayedMembers() {
    const userMap = (isOnline: boolean) => (u: Participant) => ({ ...u, isOnline, plan: getSubscriptionPlanByEnum(u.pro) });
    {
      const maxLengthToShow = this.isMembersSearch ? this.teamMembersCollection.size : this.maxOnlineMembersToShow;
      this.onlineMembers = Array.from(this.teamMembersCollection.values()).filter(m => this.onlineMembersIds.has(m._id))
        .map(userMap(true))
        .slice(0, maxLengthToShow);
    }
    {
      const maxLengthToShow = this.isMembersSearch ? this.teamMembersCollection.size : this.maxOfflineMembersToShow;
      this.offlineMembers = Array.from(this.teamMembersCollection.values())
        .filter(m => !this.onlineMembersIds.has(m._id))
        .map(userMap(false))
        .slice(0, maxLengthToShow);
    }
  }

  get onlineMembersLength() {
    return this.isMembersSearch ? this.onlineMembers.length : this.onlineMembersIds.size;
  }

  get offlineMembersLength() {
    return this.isMembersSearch ? this.offlineMembers.length : safeInt(this.membersIds.length - this.onlineMembersIds.size, 0, Number.MAX_SAFE_INTEGER);
  }

  get storageLimit() {
    return getStorageLimit(this.userService.user, this.activeTeam);
  }

  get storageUsed() {
    return getStorageUsed(this.userService.user, this.activeTeam);
  }

  get plan() {
    if (this.activeTeam) {
      return getSubscriptionPlanByEnum(this.activeTeam.pro ?? SubscriptionPlan.Free);
    } else {
      return getSubscriptionPlanByEnum(this.userService.user?.pro ?? SubscriptionPlan.Free);
    }
  }

  get userPlan() {
    const pro = this.userService.user?.pro;
    return pro ? getSubscriptionPlanByEnum(pro) : undefined;
  }

  get teamPlanName() {
    const pro = this.activeTeam?.pro;
    return pro ? getSubscriptionPlanByEnum(pro).name : 'Spark (Free)';
  }

  planForMember(member: Participant) {
    const pro = member.pro ?? SubscriptionPlan.Free;
    if (pro === SubscriptionPlan.Free) return undefined;
    return getSubscriptionPlanByEnum(pro);
  }

  setSearchMember = debounce((e: string) => {
    this.searchMemberQ = e;
    if (this.searchMemberQ.length && this.activeTeam) {
      this.teamMembersCollection.clear();
      this.teamMembersService.getTeamMembersByName(this.activeTeam._id, this.searchMemberQ).then(members => {
        members.forEach(member => {
          this.teamMembersCollection.set(member._id, {
            ...member.user,
            role: this.teamMembersService.isTeamOwner(member.user._id) ? 'owner' : 'all',
            isOnline: false,
          });
        });
        this.updateDisplayedMembers();
      }).catch(e => DEVELOPMENT && console.error(e));
    } else {
      this.reload$.next(null);
    }
  }, 300);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public teamsQuery: TeamsQuery,
    private userService: UserService,
    private projectsQuery: ProjectQuery,
    private dragDropService: EntityDragDropNotificationService,
    private modals: ModalService,
    private entitiesQuery: EntitiesQuery,
    private toastService: ToastService,
    private notificationService: IAppNotificationService,
    private presenceService: PresenceService,
    private onlineUsersService: OnlineUsersService,
    private teamMembersService: TeamMembersService, // do not remove
    private manage: ManageService,
    private teamService: TeamService, // do not remove
    private billingService: BillingService,
    private subscriptionService: PortalSubscriptionService,
    private projectService: ProjectService,
    private featureFlagService: FeatureFlagService,
    private blogService: BlogService
  ) {
    timer(10).pipe(untilDestroyed(this)).subscribe(() => {
      this.enableHomeButtonTransition = true;
    });

    this.notificationService.notificationCounters$.pipe(untilDestroyed(this)).subscribe((counters) => {
      this.notificationCounters = counters;
    });

    this.dragDropService.dragListener$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(team => {
      this.isDragging = team !== undefined;
    });

    this.teamsQuery.selectActive().pipe(
      map(team => team?._id),
      distinctUntilChanged(),
    ).subscribe(() => {
      this.maxOfflineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
      this.maxOnlineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
      this.membersIds = [];
      this.teamMembersCollection.clear();
      this.searchMemberQ = '';
    });

    this.teamsQuery.selectActive().pipe(
      map(team => team?._id),
      distinctUntilChanged(),
      switchMap(teamId => this.presenceService.monitorEntitiesCount(teamId)),
      untilDestroyed(this),
    ).subscribe(count => this.liveCount = count);

    combineLatest([
      this.teamsQuery.selectActive().pipe(
        map(team => team?._id),
        distinctUntilChanged(),
        switchMap(teamId => teamId ? this.onlineUsersService.observeContext(teamId) : of(new Set<string>())),
      ),
      this.teamsQuery.selectActive().pipe(
        map(team => team?._id),
        distinctUntilChanged(),
        switchMap(teamId => teamId ? this.teamMembersService.getTeamMembersIds(teamId) : of(new Array<string>)),
      ),
    ]).pipe(
      untilDestroyed(this),
    ).subscribe(([onlineList, members]) => {
      this.membersIds = members;
      this.onlineMembersIds = new Set(Array.from(onlineList).filter(m => members.includes(m)));
      this.memberTotal = members.length;
      this.teamMembersCollection.clear();
      this.reload$.next(null);
    });

    this.reload$.subscribe(() => {
      if (this.activeTeam) {
        const onlineIds = Array.from(this.onlineMembersIds).slice(0, this.maxOnlineMembersToShow);
        const offlineIds = this.membersIds.filter(m => !this.onlineMembersIds.has(m)).slice(0, this.maxOfflineMembersToShow);
        const membersIds = [...onlineIds, ...offlineIds].filter(m => !Array.from(this.teamMembersCollection.values()).some(p => p._id === m));
        if (membersIds.length) {
          this.teamMembersService.getTeamMembersByIds(this.activeTeam._id, membersIds).then(members => {
            members.forEach(member => {
              this.teamMembersCollection.set(member._id, {
                ...member.user,
                role: this.teamMembersService.isTeamOwner(member.user._id) ? 'owner' : 'all',
                isOnline: false,
              });
            });
            this.updateDisplayedMembers();
          }).catch(e => DEVELOPMENT && console.error(e));
        } else {
          this.updateDisplayedMembers();
        }
      }
    });

    this.dragDropService.dragStartSubject.pipe(untilDestroyed(this)).subscribe(list => {
      this.selectedEntities = list;
    });

    this.dragDropService.isDragging$.pipe(untilDestroyed(this)).subscribe((isDragging) => {
      if (!isDragging) {
        this.selectedEntities = [];
      }
    });
  }

  get teamType() {
    return this.activeTeam && this.billingService.getTeamType(this.activeTeam);
  }

  get isActiveTeamStarter() {
    return this.teamType === TeamType.Private || this.teamType === TeamType.Public;
  }

  get isNotProPlusTeam() {
    return !this.activeTeam || this.isActiveTeamStarter;
  }

  get isPrivateTeam() {
    return this.teamType === TeamType.Private;
  }

  get isSelfPaid() {
    return (this.user?.proSources?.length === 1) && (this.user?.proSources[0] === 'paid');
  }

  get isPro() {
    return !!this.user?.pro;
  }

  get isTeamPro() {
    return !!this.teamsQuery.getActive()?.pro;
  }

  get canManageTeam() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeam);
  }

  get canManageTeamBilling() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamBilling);
  }

  get hasDreamboothFeature() {
    // TODO it will work only for user feature flags
    return this.featureFlagService.isFeatureSupported(Feature.AiDreambooth);
  }

  get activeTeam() {
    return this.teamsQuery.getActive();
  }

  get activeTeamIdForNotifications() {
    return this.activeTeam?._id ?? NOTIFICATION_ARTDESK_ID;
  }

  get activeTeamName() {
    if (this.isCommunityHub) {
      return COMMUNITY_HUB_TEAM_NAME;
    } else if (this.activeTeam?.name) {
      return this.activeTeam.name;
    } else {
      return 'My Artdesk';
    }
  }

  get hideResources() {
    return storageGetBoolean('hide-resources');
  }

  set hideResources(value) {
    storageSetBoolean('hide-resources', value);
  }

  get user() {
    return this.userService.user;
  }

  get usersClosed() {
    return storageGetBoolean('team-selector-users-closed');
  }
  set usersClosed(value) {
    storageSetBoolean('team-selector-users-closed', value);
  }

  get onlineUsersClosed() {
    return storageGetBoolean('team-selector-online-users-closed');
  }
  set onlineUsersClosed(value) {
    storageSetBoolean('team-selector-online-users-closed', value);
  }

  get offlineUsersClosed() {
    return storageGetBoolean('team-selector-offline-users-closed');
  }
  set offlineUsersClosed(value) {
    storageSetBoolean('team-selector-offline-users-closed', value);
  }

  get canReorderProjects() {
    return this.teamService.hasPermissionFlag(Permission.CanUpdateProjects);
  }

  get canDeleteEntities() {
    const team = this.teamsQuery.getActive();
    if (!team) return true;

    if (this.teamService.hasPermissionFlag(Permission.CanDeleteEntities)) return true;

    for (const project of team.projects || []) { // HACK: projects is sometimes not iterable for some reason
      if (this.teamService.hasPermissionFlag(Permission.CanDeleteEntities, project)) {
        return true;
      }
    }

    return false;
  }

  get canViewTeamMembers() {
    const team = this.teamsQuery.getActive();
    if (!team) return true;

    return this.teamService.hasPermissionFlag(Permission.CanViewTeamMembers);
  }

  async ngOnInit() {
    const url = this.router.url;
    if (url.includes('upgrade-storage') || storageGetBoolean('open-upgrade-storage')) {
      this.upgradeToPro('upgrade-storage');
      storageRemoveItem('open-upgrade-storage');
    }
  }

  get isIntercomAvailable() {
    return !!window.Intercom;
  }

  get storageIsFull() {
    return this.storageUsed >= this.storageLimit;
  }

  get storageAlmostFull() {
    const used = this.storageUsed;
    const limit = this.storageLimit;
    return used >= (limit * 0.9) && used < limit;
  }

  get storageIsOk() {
    const used = this.storageUsed;
    const limit = this.storageLimit;
    return used < (limit * 0.9);
  }

  get storagePercentage() {
    const percentage = Math.floor(this.storageUsed * 100 / this.storageLimit);
    return percentage > 100 ? 100 : percentage;
  }

  get hasNoLimitStorage() {
    return !this.manage.isStorageLimitActive(this.activeTeam);
  }

  loadMoreMembers(online = true) {
    if (online) {
      this.maxOnlineMembersToShow += DEFAULT_MAX_MEMBERS_TO_SHOW;
    } else {
      this.maxOfflineMembersToShow += DEFAULT_MAX_MEMBERS_TO_SHOW;
    }
    this.reload$.next(null);
  }

  showLessMembers(online = true) {
    if (online) {
      this.maxOnlineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
    } else {
      this.maxOfflineMembersToShow = DEFAULT_MAX_MEMBERS_TO_SHOW;
    }
    this.updateDisplayedMembers();
  }

  hasMore(onlineMembers = true) {
    if (onlineMembers) {
      return this.onlineMembersLength > this.maxOnlineMembersToShow;
    } else {
      return this.offlineMembersLength > this.maxOfflineMembersToShow;
    }
  }

  contactUs() {
    contactSupportIntercom(['Business inquiries']).catch(e => DEVELOPMENT && console.error(e));
  }

  storageLimitReachedAlert(team?: TeamData) {
    if (!this.manage.isStorageLimitActive(team)) return false;

    const user = this.userService.user;
    const used = getStorageUsed(user, team);
    const limit = getStorageLimit(user, team);
    return used >= limit;
  }

  artspaceMarkedForDeletion(team: TeamData) {
    return !!team.deletionDate;
  }

  isTeamLocked(team: TeamData) {
    return team.requireSamlAuthentication;
  }

  async reorderProjects(projects: ProjectData[]) {
    if (!projects.length) return;
    await this.projectService.reorder(projects[0].team, projects.map(p => p._id));
  }

  isActiveProject(projectId: string) {
    const activeProject = this.projectsQuery.getActiveId() === projectId;
    const isCommunityHubProject = !!this.blogService.communityHubProjects.some(project => project._id === projectId);
    const urlMatch = this.router.url.includes(projectId);
    return (isCommunityHubProject || activeProject) && urlMatch;
  }

  setDropTarget(project: ProjectData) {
    if (this.canMoveEntityTo(project)) {
      this.dragOverProject = project;
    }
  }

  droppedOn(project: ProjectData) {
    this.dragDropService.dragListener$
      .pipe(
        take(1),
        tap(() => {
          if (this.canMoveEntityTo(project)) {
            this.dragDropService.dropped(project._id);
          }
        }),
        untilDestroyed(this),
      ).subscribe();
  }

  async droppedOnTeam(team: TeamData | null) {
    if (this.selectedEntities.length === 0) return;

    const selectedFolder = await this.modals.selectFolder({
      pickedFolderIds: this.selectedEntities.filter(e => e.type === EntityType.Folder).map(e => e._id),
      teamId: team?._id,
      showArtdesk: !team && !disableMyArtdesk,
      mode: SelectMode.Move
    });

    if (selectedFolder) {
      this.dragDropService.dropped(selectedFolder.projectId, selectedFolder.entityId);
    }
  }

  createTeam() {
    void this.modals.createTeam();
  }

  openMyArtdesk() {
    this.openTeam(undefined);
  }

  openTeamSettings() {
    void this.router.navigate(routeToTeam(this.activeTeam!.slug, 'settings'));
    this.closeDrawer();
  }

  openTeamSettingsMembers() {
    void this.router.navigate(routeToTeam(this.activeTeam!.slug, ['settings', 'members']));
    this.closeDrawer();
  }

  async manageSubscription() {
    void this.subscriptionService.manageSubscription();
    this.closeDrawer();
  }

  openTeam(team: TeamData | undefined) {
    if (team) {
      if (team.requireSamlAuthentication) {
        void this.router.navigate(routeToTeam(team.slug, 'reauthenticate'));
        this.closeDrawer();
        return;
      }

      const projectId = getLastProject(team.slug) ?? this.projectsQuery.getAll().find(p => p.team === team._id)?._id;
      if (projectId) {
        const projectEntity = this.projectsQuery.getEntity(projectId);
        void this.router.navigate(routeToProject(team.slug, projectEntity?.team === team._id ? projectId : undefined));
      } else {
        void this.router.navigate(routeToTeam(team.slug));
      }
    } else {
      void this.router.navigate(['/my']);
    }
  }

  async leaveTeam() {
    const team = this.activeTeam;
    if (!team) return;

    try {
      const isOwner = this.teamService.hasPermissionFlag(Permission.isTeamOwner);
      const isLastOwner = isOwner && this.teamMembersService.countTeamMembersWithOwnerRole() <= 1;
      if (await this.modals.leaveTeam({ teamName: team.name, isLastOwner })) {
        if (isLastOwner) {
          void this.router.navigate(routeToTeam(team.slug, ['settings', 'members']));
        } else {
          await this.teamService.leaveTeam(team._id);
          void this.router.navigate(['/my']);
        }
      }
    } catch (e) {
      this.toastService.error({ message: `Failed to leave "${team.name}" artspace`, subtitle: e.message });
    }
  }

  async inviteUsers() {
    if (this.activeTeam) {
      await this.modals.inviteTeamMembers({
        team: this.activeTeam,
        canManageInvites: this.teamService.hasPermissionFlag(Permission.CanManageInvites),
      });
    }
  }

  async createProject() {
    const team = this.activeTeam;
    if (team) {
      const project = await this.modals.createProject(team._id);
      if (project) {
        void this.router.navigate(routeToProject(team.slug, project._id));
        this.closeDrawer();
      }
    } else if (!IS_HOSTED && this.userService.user && this.isCommunityHub) {
      const project = await this.modals.createProject(COMMUNITY_HUB_TEAM_ID, { type: ProjectType.ContentPage });
      if (project) {
        this.blogService.getCommunityHubProjects()
          .then(() => this.router.navigate(['community-hub', project._id]))
          .catch((e) => {
            DEVELOPMENT && console.error(e);
            reportError(e);
          });
        this.closeDrawer();
      }
    }
  }

  upgradeToPro(reason: string) {
    this.manage.upgrade(reason, this.activeTeam ? 'team' : 'individual');
  }

  async newFolder() {
    const parentFolderId = this.activatedRoute.snapshot.queryParams.folder;
    const created = await this.modals.createNewFolder(parentFolderId);
    if (created) this.closeDrawer();
  }

  closeDrawer() {
    this.close.emit();
  }

  routeToProject(team: TeamData, project: ProjectData) {
    return routeToProject(team.slug, project._id);
  }

  routeToCommunityHub(projectId?: string) {
    if (!projectId) {
      return `/community-hub`;
    } else {
      return `/community-hub/${projectId}`;
    }
  }

  routeToRecent(team: TeamData) {
    return routeToTeam(team.slug, 'recent');
  }

  isDescriptionHidden(projectId: string) {
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions');
    return hiddenProjects?.ids[projectId];
  }

  showProjectDescription(projectId: string) {
    let hiddenProjects = storageGetJson<HiddenProjectsDescriptions>('hidden-project-descriptions') ?? { ids: {} };
    delete hiddenProjects.ids[projectId];
    storageSetJson('hidden-project-descriptions', hiddenProjects);
  }

  get isCommunityHub() {
    return window.location.pathname.includes('community-hub');
  }
  get communityHubProjects() {
    return this.blogService.communityHubProjects;
  }

  reorderArtspaces(teams: TeamData[]) {
    this.userService.reorderArtspaces(teams).catch((e) => {
      this.toastService.error({ message: 'Failed to reorder artspaces', subtitle: e.message });
    });
  }

  getProjectIcon(project: ProjectData) {
    if (this.isDragging && this.dragOverProject === project && this.canMoveEntityTo(project)) {
      return this.plusIcon;
    } else {
      switch (project.type) {
        case ProjectType.Project: return this.projectIcon;
        case ProjectType.ContentPage: return this.contentPageIcon;
        default: return invalidEnumReturn(project.type, this.projectIcon);
      }
    }
  }

  canMoveEntityTo(project: ProjectData) {
    return project.type === ProjectType.Project;
  }

  get canStartTrial() {
    return !this.userService.user?.pro && (!this.userService.user?.subscriptionStatus?.status ||
      this.userService.user.subscriptionStatus.status === 'incomplete');
  }

  get isMobile() {
    return isMobile;
  }
}

function byOrder<T extends { order?: number }>(a: T, b: T) {
  return (a.order ?? 0) - (b.order ?? 0);
}

function getStorageUsed(user: UserData | undefined | null, team: TeamData | undefined) {
  return team ? (team.storageUsage?.used ?? 0) : (user?.storageUsage ?? 0);
}

function getStorageLimit(user: UserData | undefined | null, team: TeamData | undefined) {
  return team ? (team.storageUsage?.limit ?? 1) : (user ? userStorageLimit(user) : 1);
}
