import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpgradeTeamPlansComponentInput } from '../upgrade-team-plans/upgrade-team-plans.component';

@Component({
  selector: 'upgrade-team-subscription-modal',
  templateUrl: 'upgrade-team-subscription-modal.component.pug',
  styleUrls: ['upgrade-team-subscription-modal.component.scss'],
})
export class UpgradeTeamSubscriptionModalComponent {
  @Output() close = new EventEmitter<void>();
  @Input() data!: UpgradeTeamPlansComponentInput;

  constructor() { }

  cancel() {
    this.close.emit();
  }
}
