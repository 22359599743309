import { ErrorHandler, Injectable } from '@angular/core';
import { nextError } from './errorReporter';

@Injectable({ providedIn: 'any' })
export class AppErrorHandler extends ErrorHandler {
  handleError(error: any) {
    super.handleError(error);
    nextError({ message: 'ErrorHandler', error, data: { stack: (new Error()).stack } });
  }
}
