import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDateFormat' })
export class CustomDateFormatPipe implements PipeTransform {
  transform(value: string | Date | undefined): string {
    if (!value) return '';

    const currentDate = new Date();
    const date = new Date(value);

    if (isSameDay(currentDate, date)) {
      return `Today, ${formatTime(date)}`;
    } else if (isSameDay(getYesterday(currentDate), date)) {
      return `Yesterday, ${formatTime(date)}`;
    } else if (isLastFiveDaysOfWeek(currentDate, date)) {
      return `${formatDayOfWeek(date)}, ${formatTime(date)}`;
    } else {
      return `${formatDate(date)}`;
    }
  }
}

function isSameDay(a: Date, b: Date): boolean {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

function getYesterday(currentDate: Date): Date {
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  return yesterday;
}

function isLastFiveDaysOfWeek(currentDate: Date, date: Date): boolean {
  const diffInDays = (currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24);
  return diffInDays >= 1 && diffInDays <= 5 && date.getDay() >= 1 && date.getDay() <= 5;
}

function formatDayOfWeek(date: Date): string {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return daysOfWeek[date.getDay()];
}

function formatTime(date: Date): string {
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}

function formatDate(date: Date): string {
  return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}
