import { ProductPrice } from '../../../../../shared/interfaces';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from 'services/billing.service';
import { BlazePlan, FusionPlan, TEAM_PRODUCTS, FreePlan } from 'shared/billing';
import { blazeIcon, faGraduationCap, faLayerGroup, faUser, faUserPlus, sparkIcon, fusionIcon, fusionForEduIcon } from 'magma/common/icons';
import { ModalService } from 'services/modal.service';


export interface UpgradeTeamPlansComponentInput {
  showSpark?: boolean;
  teamId?: string;
  isTeam?: boolean;
}

@Component({
  selector: 'upgrade-team-plans',
  templateUrl: 'upgrade-team-plans.component.pug',
  styleUrls: ['upgrade-team-plans.component.scss'],
})
export class UpgradeTeamPlansComponent implements OnInit {
  readonly proIcon = blazeIcon;
  readonly sparkIcon = sparkIcon;
  readonly fusionIcon = fusionIcon;
  readonly fusionForEduIcon = fusionForEduIcon;
  readonly faUserPlus = faUserPlus;
  readonly faUser = faUser;
  readonly faLayerGroup = faLayerGroup;
  readonly faGraduationCap = faGraduationCap;
  readonly freePlan = FreePlan;
  readonly fusionPlan = FusionPlan;
  readonly blazePlan = BlazePlan;

  TEAM_PRODUCTS = TEAM_PRODUCTS;
  prices: Map<string, ProductPrice> = new Map();

  @Input() data: UpgradeTeamPlansComponentInput | undefined;// = { showSpark: true, teamId: '6540ea7ac3b3b57eb4aef276' };
  @Output() close = new EventEmitter<void>();

  constructor(private billingService: BillingService, private modals: ModalService) { }

  async ngOnInit() {
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));
  }

  cancel() {
    this.close.emit();
  }

  selectBlaze() {
    void this.modals.upgradeModal('', 'team', this.data?.teamId, BlazePlan);
    this.cancel();
  }

  selectFusion() {
    void this.modals.upgradeModal('', 'team', this.data?.teamId, FusionPlan);
    this.cancel();
  }

  get blazePrice() {
    return this.prices.get(BlazePlan.code)?.amount ?? 0;
  }

  get fusionPrice() {
    // TODO replace it after creating products in stripe
    return this.prices.get(FusionPlan.code)?.amount ?? 0;
  }

  get fusionEduPrice() {
    // TODO replace it after creating products in stripe
    return 800;
  }

  get isIntercomAvailable() {
    return !!window.Intercom;
  }

}
