import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { uniq } from 'lodash';
import { PRESENTATION_INACTIVE_HOST_TIME } from 'magma/common/constants';
import { PresentationModeStartModal, PresentationModeStartState, User } from 'magma/common/interfaces';
import { hasPermission } from 'magma/common/userRole';

@Component({
  selector: 'start-presentation-modal',
  templateUrl: './start-presentation-modal.component.pug',
  styleUrls: ['./start-presentation-modal.component.scss']
})
export class StartPresentationModal {
  readonly url = window.location.href;
  hideUiInitially = false;
  followHostLocationInitially = true;
  followHostViewInitially = true;
  hideSequencePanel = true;
  // makeProjectPublic = true;
  isForced = false;
  selectedActiveCanvasUsers: User[] = [];
  selectedActiveSequenceUsers: User[] = [];
  selectedUsersTab = 'from-active-canvas';
  
  @Output() close = new EventEmitter<PresentationModeStartState>();
  @Input() data: PresentationModeStartModal | undefined = undefined;

  constructor() { }

  get users(): User[] {
    return this.data?.currentCanvasUsers || [];
  }
  get activeCanvasUsers() {
    return this.users;
  }
  get sequenceUsers(): User[] {
    return this.data?.currentSequenceUsers || [];
  }
  get drawing() {
    return this.data?.drawing;
  }
  get hasObligeJoiningFeature() {
    return this.data?.hasObligeJoiningFeature;
  }
  get hostTimeout() {
    return PRESENTATION_INACTIVE_HOST_TIME;
  }

  hasCoPresenterPermission(user: User) {
    const drawing = this.data?.drawing;
    return drawing && hasPermission(drawing, user, 'takeOverPresentation');
  }

  setSelectedUsers(users: User[], onSequence = false) {
    if (onSequence) {
      this.selectedActiveSequenceUsers = users;
      this.selectedActiveCanvasUsers = this.users.filter(user => !!users.some(u => u.uniqId === user.uniqId));
    } else {
      this.selectedActiveCanvasUsers = users;
      this.selectedActiveSequenceUsers = this.sequenceUsers.filter(user => (this.selectedActiveSequenceUsers.includes(user) && !this.users.some(u => u.uniqId === user.uniqId)) || !!users.some(u => u.uniqId === user.uniqId));
    }
  }

  onClose(confirmed = false) {
    const invitedUsers = uniq([...this.selectedActiveCanvasUsers.map(u => u.uniqId), ...this.selectedActiveSequenceUsers.map(u => u.uniqId)]);

    if (confirmed) {
      this.close.emit({
        participantsUiHidden: this.hideUiInitially,
        invitedUsers: invitedUsers,
        isForced: this.isForced,
        followingHostLocationEnforced: this.followHostLocationInitially,
        followingHostViewportEnforced: this.followHostViewInitially,
        hideSequencePanel: this.hideSequencePanel,
      });
    } else {
      this.close.emit(undefined);
    }
  }
}
