import { Component, EventEmitter, Input, Output } from '@angular/core';
import { farExclamationTriangle } from 'magma/generated/fa-icons';

export interface CancelSubscriptionModalData {
  takeEffectDate: Date;
  trial: boolean;
  artspace?: boolean;
  userCount?: number;
}

@Component({
  selector: 'cancel-subscription-modal',
  templateUrl: 'cancel-subscription-modal.component.pug',
})
export class CancelSubscriptionModal {
  warningIcon = farExclamationTriangle;
  @Input() data: CancelSubscriptionModalData = { takeEffectDate: new Date(0), trial: false };
  @Output() close = new EventEmitter<boolean>();
  cancel() {
    this.close.emit(false);
  }
  confirm() {
    this.close.emit(true);
  }
}
