import { Component } from '@angular/core';
import { BlogService, ReadingPostMethod } from '../../services/blog.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Post } from '../../../../shared/posts';
import { faCogs, faPlus, farEllipsisV, faTrash } from 'magma/common/icons';
import { ModalService } from '../../services/modal.service';
import { PostModalMode } from '../modals/post-modal/post-modal.component';
import { Permission } from 'magma/common/interfaces';
import { UserService } from '../../services/user.service';
import { TeamService } from 'services/team.service';
import { TeamsQuery } from '../../services/team.query';

@UntilDestroy()
@Component({
  selector: 'content-page',
  templateUrl: 'content-page.component.pug',
  styleUrls: ['content-page.component.scss'],
})
export class ContentPageComponent {
  readonly newPostIcon = faPlus;
  readonly deleteIcon = faTrash;
  readonly settingsIcon = faCogs;
  readonly farEllipsisV = farEllipsisV;

  constructor(
    private blogService: BlogService,
    private activatedRoute: ActivatedRoute,
    private modals: ModalService,
    private userService: UserService,
    private teamService: TeamService,
    private teamQuery: TeamsQuery,
  ) { }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(untilDestroyed(this))
      .subscribe((params) => {
        this.blogService.setupBlog(params['project']!);
      });
  }

  ngOnDestroy() {
    this.blogService.setupBlog(null);
  }

  get loading() {
    return this.blogService.loadingPosts;
  }

  get missingPermissions() {
    return this.blogService.missingPermissions;
  }

  get project() {
    return this.blogService.project;
  }

  get posts() {
    return this.blogService.posts;
  }

  get canManage() {
    return this.blogService.canManage;
  }

  get canUpdateProjects() {
    if (this.isCommunityHub) {
      return this.userService.user?.isSuperAdmin;
    } else {
      return this.teamService.hasPermissionFlag(Permission.CanUpdateProjects);
    }
  }

  get disableNewPostButton() {
    return this.blogService.disableCreating;
  }

  get isCommunityHub() {
    return this.blogService.insideCommunityHubProject;
  }

  moveProjectToLeft(projectId: string, post: Post) {
    this.blogService.moveProjectToLeft(projectId, post);
  }

  moveProjectToRight(projectId: string, post: Post) {
    this.blogService.moveProjectToRight(projectId, post);
  }

  create(projectId: string) {
    this.modals.openPostModal({ mode: PostModalMode.Creating, projectId })
      .catch((e) => { DEVELOPMENT && console.error(e); });
  }

  open(projectId: string, post: Post) {
    this.blogService.openPost(post, ReadingPostMethod.ContentPage);
  }

  highlight(projectId: string, post: Post) {
    this.blogService.togglePostHighlight(projectId, post)
      .catch((e) => {
        DEVELOPMENT && console.log(e);
      });
  }

  edit(projectId: string, post: Post) {
    this.modals.openPostModal({ mode: PostModalMode.Editing, post, projectId })
      .catch((e) => { DEVELOPMENT && console.error(e); });
  }

  remove(projectId: string, post: Post) {
    this.modals.openPostModal({ mode: PostModalMode.Removing, post, projectId })
      .catch((e) => { DEVELOPMENT && console.error(e); });
  }

  async projectSettings() {
    if (this.project) {
      const project = await this.modals.editProject(this.project);
      if (project) this.blogService.project = project;
    }
  }

  get canUpdateProject() {
    if (this.isCommunityHub) {
      return !!this.userService.user?.isSuperAdmin;
    } else {
      return this.teamService.hasPermissionFlag(Permission.CanUpdateProjects);
    }
  }

  async deleteProject() {
    if (this.project) {
      await this.modals.deleteProject(this.project);
    }
  }
}
