import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Component, ChangeDetectorRef } from '@angular/core';
import { UserService } from 'services/user.service';
import { faInfoCircle } from 'magma/generated/fa-icons';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { Analytics } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'account-notifications',
  templateUrl: './account-notifications.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-notifications.component.scss',
  ],
})
export class AccountNotificationsComponent {
  warningIcon = faInfoCircle;
  inAppNotifications = false;
  muteAppNotifications = false;
  emailNotifications = false;
  educationalContent = false;
  storageEmailNotifications = false;
  eventsNotifications = false;
  artspacesContent = false;

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    private loginSignupService: LoginSignupService,
  ) {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.inAppNotifications = !user?.hideNewFeatureNotifications;
      this.emailNotifications = !!user?.newsletter;
      this.muteAppNotifications = user?.muteAppNotifications ?? false;
      this.educationalContent = !(user?.hideEducationalContent ?? false);
      this.storageEmailNotifications = !(user?.disableStorageEmails ?? false);
      this.eventsNotifications = !(user?.hideEventsAnnouncements ?? false);
      this.artspacesContent = !(user?.hideArtspacesAnnouncements ?? false);
    });
  }

  get user() {
    return this.userService.user;
  }

  setInAppNotifications(value: boolean) {
    this.userService.save({ hideNewFeatureNotifications: !value })
      .then(() => this.toastService.success({ message: 'Your notification preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your notification preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.inAppNotifications = !value;
        this.cdr.markForCheck();
      });

    if (value) this.loginSignupService.trackEvent(Analytics.DisableFeatureNotifications);
  }

  setEducationalContent(value: boolean) {
    this.userService.save({ hideEducationalContent: !value })
      .then(() => this.toastService.success({ message: 'Your notification preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your notification preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.educationalContent = !value;
        this.cdr.markForCheck();
      });
  }

  setAppEmailNotifications(value: boolean) {
    this.userService.save({ muteAppNotifications: !value })
      .then(() => this.toastService.success({ message: 'Your notification preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your notification preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.muteAppNotifications = value;
        this.cdr.markForCheck();
      });
  }

  setStreamsAndEvents(value: boolean) {
    this.userService.save({ hideEventsAnnouncements: !value })
      .then(() => this.toastService.success({ message: 'Your notification preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your notification preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.eventsNotifications = value;
        this.cdr.markForCheck();
      });
  }

  setArtspacesContent(value: boolean) {
    this.userService.save({ hideArtspacesAnnouncements: !value })
      .then(() => this.toastService.success({ message: 'Your notification preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your notification preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.artspacesContent = value;
        this.cdr.markForCheck();
      });
  }

  setEmailNotifications(value: boolean) {
    this.userService.save({ newsletter: value })
      .then(() => this.toastService.success({ message: 'Your email preferences has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your email preferences', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.emailNotifications = !value;
        this.cdr.markForCheck();
      });
  }

  setStorageEmailNotifications(value: boolean) {
    this.userService.save({ disableStorageEmails: !value })
      .then(() => this.toastService.success({ message: 'Your email preferences about storage limits has been updated' }))
      .catch(e => {
        this.toastService.error({ message: 'Failed to save your email preferences about storage limits', subtitle: e.message });
        // restore previous state
        this.cdr.detectChanges();
        this.storageEmailNotifications = !value;
        this.cdr.markForCheck();
      });
  }
}
