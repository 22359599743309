import { Injectable } from '@angular/core';
import { SortOrders } from 'magma/common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SimpleTableService {
  sortAndPaginateArray<T>(
    array: T[],
    sortBy: string,
    sortDirection: SortOrders,
    currentPage: number,
    itemsPerPage: number
  ): { data: T[]; totalItems: number } {
    if (sortBy && sortDirection) {
      array = array.sort((a, b) => {
        const aValue = this.getNestedFieldValue(a, sortBy);
        const bValue = this.getNestedFieldValue(b, sortBy);

       // Handle cases where the nested field doesn't exist by treating them as "biggest" values
       if (aValue === null && bValue === null) return 0;
       if (aValue === null) return 1;
       if (bValue === null) return -1;

        if (sortDirection === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else if (sortDirection === 'desc') {
          return aValue < bValue ? 1 : -1;
        }

        return 0;
      });
    }

    const totalItems = array.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = array.slice(startIndex, endIndex);

    return { data: paginatedData, totalItems };
  }

  private getNestedFieldValue(object: any, field: string): any {
    const keys = field.split('.');
    let value = object;

    for (const key of keys) {
      if (value[key] !== undefined) {
        value = value[key];
      } else {
        return null; // Handle cases where the nested field does not exist
      }
    }

    return value;
  }
}
