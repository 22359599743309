import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TeamInviteMembersInput } from 'components/modals/team-invite-members/team-invite-members.component';
import { hasFlag, setFlag } from 'magma/common/baseUtils';
import { Permission } from 'magma/common/interfaces';
import { arrayToTextField, textFieldToArray } from 'magma/common/utils';
import { ToastService } from 'magma/services/toast.service';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { TeamData, TeamFlags } from 'shared/interfaces';

@UntilDestroy()
@Component({
  selector: 'team-settings-access',
  templateUrl: './team-settings-access.component.pug',
  styleUrls: ['../../account-common.component.scss', './team-settings-access.component.scss'],
})
export class TeamSettingsAccessComponent {
  activeTeam$ = this.teamsQuery.selectActive();
  isUpdatingSignupFilter = false;
  isEditingSignupFilter = false;
  signupFilter = '';
  signupFilterEnabled = false;
  team: TeamData | undefined = undefined;
  teamMembers$ = this.teamMembersQuery.selectAll();

  get inviteData(): TeamInviteMembersInput | undefined {
    return this.team && { team: this.team, canManageInvites: this.canManageInvites };
  }

  get canManageInvites() {
    return this.teamService.hasPermissionFlag([Permission.CanManageInvites]);
  }

  get canManageBilling() {
    return this.teamService.hasPermissionFlag([Permission.CanManageTeamBilling]);
  }

  get canUpdateTeam() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeam);
  }

  constructor(
    private teamsQuery: TeamsQuery,
    private teamService: TeamService,
    private toastService: ToastService,
    private teamMembersQuery: TeamMembersQuery,
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(t => {
      this.team = t;
      if (this.team) {
        this.signupFilter = arrayToTextField(this.team.signupFilter);
        this.signupFilterEnabled = hasFlag(this.team.flags, TeamFlags.SignupFilterEnabled);
      } else {
        this.toastService.error({ message: 'Failed to get team data' });
      }
    });
  }

  async enableDomainFilter(enable: boolean) {
    try {
      const flags = setFlag(this.team?.flags, TeamFlags.SignupFilterEnabled, enable);
      await this.teamService.updateTeam(this.team!, { flags });
      this.signupFilterEnabled = enable;
      this.toastService.success({ message: `Domain filter has been ${enable ? 'enabled' : 'disabled'}` });
    } catch (e) {
      this.toastService.error({ message: 'Failed to toggle domain filter', subtitle: e.message });
    }
  }

  async updateSignupFilter() {
    try {
      await this.teamService.updateTeam(this.team!, { signupFilter: textFieldToArray(this.signupFilter) });
      this.toastService.success({ message: 'Domain filter has been updated' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to update domain filter', subtitle: e.message });
    }
  }
}
