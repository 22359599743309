import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { Model } from 'magma/services/model';
import { TeamService } from 'services/team.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { storageSetString } from 'magma/services/storage';
import { AFTER_LOGIN_REDIRECT_URL_KEY } from 'components/login-signup-page.component';


@UntilDestroy()
@Component({
  selector: 'reauthenticate',
  templateUrl: 'reauthenticate.component.pug',
  styleUrls: ['reauthenticate.component.scss'],
})
export class ReauthenticateComponent {
  slug = '';
  constructor(
    private model: Model,
    private loginSignupService: LoginSignupService,
    private teamService: TeamService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.slug = params.team;
    });
  }

  get username() {
    return this.model.user.name;
  }
  get email() {
    return this.model.user.email;
  }
  get userDisplayName() {
    return this.email ?? this.username;
  }

  reauthenticate() {
    const team = this.teamService.getTeamBySlug(this.slug);
    if (team) {
      if (team.requireSamlAuthentication) {
        storageSetString(AFTER_LOGIN_REDIRECT_URL_KEY, `/s/${team.slug}`);
        window.location.href = `/auth/organization/${team._id}`;
        return;
      }
    }
  }

  async signOut() {
    await this.loginSignupService.signOut();
  }
}
