import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'idle-logout-modal',
  templateUrl: './idle-logout-modal.component.pug',
  styleUrls: ['./idle-logout-modal.component.scss'],
})
export class IdleLogoutModalComponent {
  @Output() close = new EventEmitter<boolean>();
  class = 'idle-logout-modal';
}
