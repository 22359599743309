import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectMode, SelectedFolder } from 'magma/common/interfaces';


export interface SelectFolderModalInput {
  pickedFolderIds?: string[];
  teamId: string | undefined;
  showArtdesk: boolean;
  mode: SelectMode;
  preselectedFolder?: SelectedFolder;
  showFiles?: boolean;
}

@Component({
  selector: 'select-folder-modal',
  templateUrl: './select-folder-modal.component.pug',
  styleUrls: ['./select-folder-modal.component.scss'],
})
export class SelectFolderModalComponent {
  @Input() data!: SelectFolderModalInput;
  @Output() close = new EventEmitter<SelectedFolder>();
  selectedFolder: SelectedFolder | undefined;

  onClose() {
    this.close.emit();
  }

  onSelected(folderData: SelectedFolder) {
    this.selectedFolder = folderData;
  }

  confirm() {
    this.close.emit(this.selectedFolder);
  }

  get headerLabel() {
    switch (this.data.mode) {
      case SelectMode.Create:
        return 'Change location';
      case SelectMode.Move:
        return 'Move to';
      case SelectMode.Select:
        return 'Select file';
    }
  }

  get confirmButtonLabel() {
    switch (this.data.mode) {
      case SelectMode.Create:
        return 'OK';
      case SelectMode.Move:
        return 'Move';
      case SelectMode.Select:
        return 'Select';
    }
  }
}
