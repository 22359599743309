import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'services/auth.service';
import { UserService } from 'services/user.service';
import { map } from 'rxjs';

@Component({
  selector: 'error-404',
  templateUrl: './not-found-page.component.pug',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent {
  header = 'Oops, there\'s nothing here';
  title = 'It seems that either this document does no longer exist, or you don\'t have permission to view it.';

  constructor(private userService: UserService, private authService: AuthService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(({ title, header }) => {
      if (title) this.title = title;
      if (header) this.header = header;
    });
  }

  user$ = this.userService.user$.pipe(map(user => user?.email ?? user?.name));

  async signOut() {
    await this.authService.logout();
  }
}
