import { Component } from '@angular/core';
import { LoginSignupService } from 'magma/services/login-signup.service';
import { Model } from 'magma/services/model';

@Component({
  selector: 'no-access-or-not-found',
  templateUrl: 'no-access-or-not-found.component.pug',
  styleUrls: ['no-access-or-not-found.component.scss'],
})
export class NoAccessOrNotFoundPageComponent {
  constructor(
    private model: Model,
    private loginSignupService: LoginSignupService,
  ) { }

  get username() {
    return this.model.user.name;
  }
  get email() {
    return this.model.user.email;
  }
  get userDisplayName() {
    return this.email ?? this.username;
  }

  async signOut() {
    await this.loginSignupService.signOut();
  }
}
