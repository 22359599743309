import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { EntityData } from 'shared/interfaces';

@UntilDestroy()
@Component({
  selector: 'bin-folder',
  templateUrl: 'bin-folder.component.pug',
  styleUrls: ['bin-folder.component.scss'],
})
export class BinFolderComponent {
  title = 'Bin Folder';
}
