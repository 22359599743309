import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Permission } from 'magma/common/interfaces';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { UserService } from 'services/user.service';
import { routeToTeam } from 'shared/utils';

@Injectable()
export class TeamBinFolderGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
    private teamService: TeamService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    return combineLatest([
      this.userService.user$,
      this.teamsQuery.selectEntity(route.params.team),
      this.teamService.teamsReady$.pipe(filter(s => s)),
    ]).pipe(map(([user, team]) => {
      if (user && user.userType !== 'anonymous' && team) {
        if (this.teamService.hasPermissionFlag(Permission.CanDeleteEntities)) {
          return true;
        } else {
          for (const project of team.projects) {
            if (this.teamService.hasPermissionFlag(Permission.CanDeleteEntities), project) {
              return true;
            }
          }

          void this.router.navigate(routeToTeam(team.slug));
          return false;
        }
      } else {
        void this.router.navigate(['/my']);
        return false;
      }
    }));
  }
}
