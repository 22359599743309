import { Component } from '@angular/core';
import { faSpinner } from 'magma/common/icons';

@Component({
  selector: 'magma-spinner',
  templateUrl: 'spinner.component.pug',
})
export class SpinnerComponent {
  readonly spinnerIcon = faSpinner;

  constructor() { }
}
