import { BehaviorSubject, of } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_ROLE_TYPES, PERMISSION_DATA, PERMISSION_DATA_VERSION_HISTORY, ROLE_TYPES_READ_ONLY, ROLE_TYPES_READ_ONLY_PERMISSIONS, RoleType, TeamData, UpdateRole } from 'shared/interfaces';
import { PERMISSION_DATA_ENTITIES, PERMISSION_DATA_POSTS, PERMISSION_DATA_PROJECTS, PERMISSION_DATA_TEAMS, PermissionData } from './../../../../shared/interfaces';
import { arrayToTextField, hasFlags, setFlags, textFieldToArray } from 'magma/common/utils';
import { faBullseyeArrow, faInfoCircle, faSearch } from 'magma/common/icons';
import { CreateRole } from 'shared/interfaces';
import { BitFlags, Permission } from 'magma/common/interfaces';
import { ROLE_NAME_MAX_LENGTH } from 'shared/constants';
import { TEAM_PRODUCTS } from 'shared/billing';
import { getLowestPossibleProduct } from 'shared/utils';
import { isEqual } from 'lodash';
import { switchMap } from 'rxjs/operators';

export interface PermissionFlagsModalInput extends CreateRole {
  _id: string;
  team: TeamData;
  create?: boolean;
}

@Component({
  selector: 'permission-flags-modal',
  templateUrl: 'permission-flags-modal.component.pug',
  styleUrls: ['./permission-flags-modal.component.scss'],
})
export class PermissionFlagsModal {
  @Output() close = new EventEmitter<UpdateRole>();
  @Input() data!: PermissionFlagsModalInput;

  isSaving = false;

  faInfoCircle = faInfoCircle;
  faSearch = faSearch;
  faBullseyeArrow = faBullseyeArrow;

  ROLE_NAME_MAX_LENGTH = ROLE_NAME_MAX_LENGTH;
  TEAM_PRODUCTS = TEAM_PRODUCTS;

  filter$ = new BehaviorSubject<string>('');

  projects = '';
  entities = '';
  name = '';

  autoAssign = false;

  selectedFlags = new Map<number, boolean>();
  requiredProduct: string | undefined;

  entitiesGroup$ = this.filter$.pipe(switchMap(filter => of(PERMISSION_DATA_ENTITIES.filter(p => this.permissionFilter(p, filter)))));
  teamsGroup$ = this.filter$.pipe(switchMap(filter => of(PERMISSION_DATA_TEAMS.filter(p => this.permissionFilter(p, filter)))));
  projectsGroup$ = this.filter$.pipe(switchMap(filter => of(PERMISSION_DATA_PROJECTS.filter(p => this.permissionFilter(p, filter)))));
  versionHistoryGroup$ = this.filter$.pipe(switchMap(filter => of(PERMISSION_DATA_VERSION_HISTORY.filter(p => this.permissionFilter(p, filter)))));
  postsGroup$ = this.filter$.pipe(switchMap(filter => of(PERMISSION_DATA_POSTS.filter(p => this.permissionFilter(p, filter)))));

  constructor() { }

  ngOnInit() {
    if (!this.data.create) {
      for (const permission of PERMISSION_DATA) {
        this.selectedFlags.set(permission.permission, hasFlags([permission.permission], this.data.flags));
      }
      this.name = this.data.name;
      this.projects = arrayToTextField(this.data.projects);
      this.entities = arrayToTextField(this.data.entities);
      this.autoAssign = !!this.data.type && DEFAULT_ROLE_TYPES.includes(this.data.type);
    } else {
      for (const permission of PERMISSION_DATA) {
        this.selectedFlags.set(permission.permission, false);
      }
    }
    this.updateRequiredProduct();
  }

  permissionFilter(p: PermissionData, filter: string) {
    return p.name.toLowerCase().includes(filter) || p.description.toLowerCase().includes(filter);
  }

  updateRequiredProduct() {
    const productId = this.getMinimumProductRequired();
    if (productId) {
      this.requiredProduct = TEAM_PRODUCTS.get(productId)?.name ?? 'Invalid product';
    } else {
      this.requiredProduct = undefined;
    }
  }

  updateFlag(flag: Permission, value: boolean) {
    this.selectedFlags.set(flag, value);
    this.updateRequiredProduct();
  }

  closeModal() {
    this.close.emit();
  }

  getSelectedFlags() {
    let flags: BitFlags<Permission>[] = [];
    for (const [flag, value] of this.selectedFlags.entries()) {
      if (value) {
        setFlags(flag, flags);
      }
    }
    return flags;
  }

  async saveChanges() {
    this.isSaving = true;
    const flags = this.getSelectedFlags();
    const projects = textFieldToArray(this.projects);
    const entities = textFieldToArray(this.entities);

    const res: UpdateRole = {};

    if (!ROLE_TYPES_READ_ONLY.includes(this.data.type)) {
      res.type = this.autoAssign ? RoleType.Default : RoleType.Custom;
    }
    if (!isEqual(projects, this.data.projects)) res.projects = projects;
    if (!isEqual(entities, this.data.entities)) res.entities = entities;
    if (this.name !== this.data.name) res.name = this.name;
    if (!ROLE_TYPES_READ_ONLY_PERMISSIONS.includes(this.data.type) && flags !== this.data.flags) res.flags = flags;

    this.isSaving = false;
    this.close.emit(res);
  }

  get isReadOnly() {
    return ROLE_TYPES_READ_ONLY.includes(this.data.type);
  }

  get arePermissionsReadOnly() {
    return ROLE_TYPES_READ_ONLY_PERMISSIONS.includes(this.data.type);
  }

  get modalHeader() {
    return this.data.create ? 'Create role' : 'Edit role';
  }

  get confirmButtonLabel() {
    return this.data.create ? 'Create' : 'Save';
  }

  get nameTooltip() {
    return this.isReadOnly ? 'Can\'t edit name of this role' : undefined;
  }

  get autoAssignTooltip(): string | undefined {
    if (this.data.type === RoleType.Everyone) return 'This role is always assigned to each user';
    if (this.data.type === RoleType.Owner) return 'This role is can be assigned only to one user';
    return undefined;
  }

  getMinimumProductRequired() {
    const requiredProductsForPermission = new Map(PERMISSION_DATA.map(p => [p.permission, p.requiredProduct]));
    const requiredProduct = new Set<string>();

    for (const [flag, value] of this.selectedFlags.entries()) {
      if (value) {
        const product = requiredProductsForPermission.get(flag);
        if (product) {
          product.forEach(p => requiredProduct.add(p));
        }
      }
    }

    return getLowestPossibleProduct(requiredProduct);
  }
}
