import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TeamInviteMembersInput } from '../team-invite-members/team-invite-members.component';
import { Permission } from 'magma/common/interfaces';
import { TeamService } from 'services/team.service';

@Component({
  selector: 'team-invite-members-modal',
  templateUrl: './team-invite-members-modal.component.pug',
  styleUrls: ['./team-invite-members-modal.component.scss'],
})
export class TeamInviteMembersModalComponent {
  @Input() data!: TeamInviteMembersInput;
  @Output() close = new EventEmitter();

  constructor(private teamService: TeamService) { }

  get canManageBilling() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamBilling);
  }

  onClose() {
    this.close.emit();
  }
}
