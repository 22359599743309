import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DAY, HOUR, MONTH, WEEK, YEAR } from 'magma/common/constants';
import { leftArrowLightIcon, nineDotsIcon } from 'magma/common/icons';
import { faCheck, faChevronDown } from 'magma/generated/fa-icons';
import { Model } from 'magma/services/model';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap, tap, map, catchError } from 'rxjs/operators';
import { AppService } from 'services/app.service';
import { SearchBarService } from 'services/search-bar.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { QUERY_FOR_TYPE } from 'shared/constants';
import { EntityData, ProjectData, SearchResponse, UserData } from 'shared/interfaces';

export interface CombinedSearchResult {
  documents?: { records: EntityData[]; total: number; isLoading?: boolean; };
  users?: { records: UserData[]; total: number; isLoading?: boolean; };
  projects?: { records: ProjectData[]; total: number; isLoading?: boolean; };
}

@UntilDestroy()
@Component({
  templateUrl: './search-results.component.pug',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnDestroy {
  readonly checkIcon = faCheck;
  readonly leftIcon = leftArrowLightIcon;
  readonly faChevronDown = faChevronDown;
  readonly nineDotsIcon = nineDotsIcon;
  queryForType = QUERY_FOR_TYPE;
  queryFor = QUERY_FOR_TYPE.DOCUMENTS;
  commentsCollection = ['', 'with', 'with-unresolved'];
  sortColumn = 'name';
  showOnlyDocuments = true; // TODO: PRODUCT_INFO.showOnlyDocuments;
  filterResults = [
    { queryFor: QUERY_FOR_TYPE.DOCUMENTS },
    ... !this.showOnlyDocuments ? [{ queryFor: QUERY_FOR_TYPE.PROJECTS }] : [],
    ... !this.showOnlyDocuments ? [{ queryFor: QUERY_FOR_TYPE.USERS }] : [],
  ];
  lastActive = [0, DAY, 3 * DAY, WEEK, MONTH, YEAR];
  fetchingData = false;
  canFetchMore = false;
  dropdowns = { placeIsOpen: false, lastActiveIsOpen: false, commentsIsOpen: false };
  entities: { records: EntityData[], total: number } = { records: [], total: 0 };

  updateEntities(data: { records: EntityData[], total: number }) {
    this.entities = data;
  }

  private pageSize$ = new BehaviorSubject(15);
  reload$ = new BehaviorSubject(null);
  results$ = combineLatest([
    this.activatedRoute.queryParams,
    this.pageSize$,
    this.reload$,
  ]).pipe(
    tap(() => this.fetchingData = true),
    switchMap(([{ q, place, lastActive, comments }, pageSize]) => {
      if (this.queryForType.DOCUMENTS !== this.queryFor) {
        this.search.q = q;
        this.search.place = place;
        this.search.lastActive = lastActive;
        this.search.comments = comments;

        return this.appService.search({
          q, place, pageSize,
          lastActive: +lastActive,
          comments,
          sortColumn: this.sortColumn,
          queryFor: QUERY_FOR_TYPE.DOCUMENTS,
        });
      }
      return of<SearchResponse>({ records: [], total: 0 });
    }),
    catchError(e => {
      DEVELOPMENT && console.error(e); // TODO: instead show error to user?
      return of<SearchResponse>({ records: [], total: 0 });
    }),
    map(documents => {
      this.fetchingData = false;
      this.canFetchMore = !!documents.records && documents.records.length < documents.total;
      return { documents } as CombinedSearchResult;
    }),
  );

  constructor(
    private teamsQuery: TeamsQuery,
    private model: Model,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private teamService: TeamService,
    public search: SearchBarService,
  ) {
  }

  ngOnDestroy() {
    this.search.reset();
  }

  get teams() {
    return this.teamsQuery.getAll();
  }

  get user() {
    return this.model.user;
  }

  getPlaceName(place: string) {
    if (!place) {
      return 'Any Artspace';
    } else if (place == 'artdesk') {
      return 'My Artdesk';
    } else {
      return this.teamService.getTeamBySlug(place)?.name;
    }
  }

  getTeamAvatar(slug: string) {
    return this.teamService.getTeamBySlug(slug)?.avatar;
  }

  getLastActiveLabel(lastActive: number) {
    if (!lastActive) {
      return 'Anytime';
    } else if (lastActive <= DAY) {
      return `Last ${Math.round(lastActive / HOUR)} hours`;
    } else {
      return `Last ${Math.round(lastActive / DAY)} days`;
    }
  }

  getCommentsLabel(comments: string) {
    if (comments === 'with') {
      return 'With comments';
    } else if (comments === 'with-unresolved') {
      return 'With unresolved comments';
    } else {
      return 'With or without comments';
    }
  }
}
