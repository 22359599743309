import { AfterViewInit, Component, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { farCheck } from 'magma/common/icons';
import { DocumentStatuses } from 'shared/constants';
import { EntityStatus } from 'shared/entities';

const DOCUMENT_STATUSES = Object.keys(DocumentStatuses).map(key => key.toLowerCase());

@UntilDestroy()
@Component({
  selector: 'document-status-dropdown',
  templateUrl: './document-status-dropdown.component.html',
  styleUrls: ['./document-status-dropdown.component.scss'],
})
export class DocumentStatusDropdownComponent implements AfterViewInit, OnDestroy {
  farCheck = farCheck;

  constructor(private elementRef: ElementRef) { }

  @Input()
  set parent(elementRef: ElementRef | null) {
    if (elementRef) {
      this._parent = elementRef;
    }
  }

  get parent() {
    return this._parent;
  }

  private _parent: ElementRef | null = null;

  @Input()
  set selectedStatus(status: DocumentStatuses) {
    this._selectedStatus = status || DocumentStatuses.DRAFT;
  }
  get selectedStatus() {
    return this._selectedStatus;
  }
  private _selectedStatus = DocumentStatuses.DRAFT;

  @Input() lastChange: EntityStatus['lastChange'] | null = null;

  @Output() selectionChanged = new EventEmitter<DocumentStatuses>();

  documentStatuses = DOCUMENT_STATUSES;

  top = 0;
  left = 0;

  ngAfterViewInit() { }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  statusChanged(event: any) {
    this.selectionChanged.emit(event);
  }

  getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }
}
