import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faTimes, storyprotocolIcon, inheritedLicenseIcon } from 'magma/common/icons';
import { ArtworkLicensingData, ClientInheritedLicense } from 'magma/common/interfaces';
import { revisionThumbPath } from 'magma/common/clientUtils';

export const StoryProtocolLicenseDetails = {
  othersMayCommercial: [
    'Purchase the right to use your creation (for a price you set)',
    'Credit you as a creator',
    'Display / publish the work in any medium'
  ],
  othersMayNotCommercial: [
    'Claim your work as their own',
    'Create remixes or other compositions based on your work',
    'Resell your work'
  ],
  othersMay: [
    'Remix this work',
    'Include this work in their own work(s)',
    'Credit you appropriately',
    'Distribute their remix anywhere'
  ],
  othersMayNot: [
    'Resell your original work',
    'Commercialize the remix',
    'Claim credit for the remix (as an original work)'
  ],
};

@Component({
  selector: 'artwork-licensing-modal',
  templateUrl: './artwork-licensing-modal.component.pug',
  styleUrls: ['./artwork-licensing-modal.component.scss'],
})
export class ArtworkLicensingModal {
  readonly inheritedLicenseIcon = inheritedLicenseIcon;
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly storyprotocolIcon = storyprotocolIcon;

  @Output() close = new EventEmitter<void>();
  @Input() set data({ revision, inheritedLicense }: ArtworkLicensingData) {
    this.revision = revision; // if this is licensed
    this.inheritedFrom = inheritedLicense; // if it was remixed from something

    if (inheritedLicense && !revision) {
      this.thumb = new URL(revisionThumbPath(inheritedLicense.shortId, inheritedLicense.cacheId), window.location.origin).href;
    } else if (revision) {
      this.thumb = new URL(revisionThumbPath(revision.shortId, revision.cacheId), window.location.origin).href;
    } else {
      throw new Error('Invalid modal state!');
    }
  }

  inheritedFrom: ClientInheritedLicense | undefined;
  revision: ArtworkLicensingData['revision'] | undefined;
  thumb = '';

  get title() {
    if (this.revision) {
      return 'Registered Intellectual Property';
    } else {
      return 'License of Original Intellectual Property';
    }
  }

  get name() {
    const takeFrom = this.revision || this.inheritedFrom;
    if (!takeFrom) {
      DEVELOPMENT && console.error('Missing title');
      return 'Unknown artwork name';
    }
    return takeFrom.publishedIn?.StoryProtocol?.artworkName ?? 'Unknown artwork name';
  }

  get subtitleVariant() {
    if (this.revision && this.inheritedFrom) {
      return 2;
    } else if (this.inheritedFrom) {
      return 1;
    } else {
      return 0;
    }
  }

  get url() {
    const takeFrom = this.revision || this.inheritedFrom;
    if (!takeFrom) {
      DEVELOPMENT && console.error('Missing title');
      return '';
    }
    return takeFrom.publishedIn?.StoryProtocol?.url ?? '';
  }

  get isCommercial() {
    const takeFrom = this.revision || this.inheritedFrom;
    return !!takeFrom?.publishedIn?.StoryProtocol?.license.isCommercial;
  }

  get participants() {
    const takeFrom = this.revision || this.inheritedFrom;
    return takeFrom?.publishedIn?.StoryProtocol?.participants ?? [];
  }

  get remixedFrom() {
    return {
      url: new URL(`/d/${this.inheritedFrom?.shortId}`, window.location.origin).href,
      name: this.inheritedFrom?.publishedIn.StoryProtocol?.artworkName ?? 'Unknown artwork name',
    };
  }

  redirect() {
    if (!this.url) throw new Error('Invalid redirection url!');
    window.open(this.url, '_blank');
  }


  getLicenseItems(may = true) {
    const othersMay = !this.isCommercial ? StoryProtocolLicenseDetails.othersMay : StoryProtocolLicenseDetails.othersMayCommercial;
    const othersMayNot = !this.isCommercial ? StoryProtocolLicenseDetails.othersMayNot : StoryProtocolLicenseDetails.othersMayNotCommercial;

    return may ? othersMay : othersMayNot;
  }
}
