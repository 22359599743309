import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { belowBreakpointMD } from 'components/utils';
import { SimpleTableColumn, SimpleTableParams, SortOrders } from 'magma/common/interfaces';
import { faCheck, faChevronDown, faChevronUp } from 'magma/generated/fa-icons';

@UntilDestroy()
@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.pug',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent {
  readonly faChevronDown = faChevronDown;
  readonly faChevronUp = faChevronUp;
  readonly checkIcon = faCheck;

  @Input() columns: SimpleTableColumn[] = [];
  @Input() numberOfPages = 1;
  @Input() currentPage = 1;
  @Input() numberOfElements = 0;
  @Input() isLoading = false;
  @Input() defaultParams: SimpleTableParams | undefined = undefined;
  @Output() sort = new EventEmitter<SimpleTableParams>();
  pageSize = 10;
  sortBy = '';
  sortDirection: SortOrders = SortOrders.Ascending;
  allowedPageSizes = [10, 25, 50, 100];

  ngAfterViewInit() {
    if (this.defaultParams?.sortBy) this.sortBy = this.defaultParams?.sortBy;
    if (this.defaultParams?.sortDirection) this.sortDirection = this.defaultParams.sortDirection;
  }

  changeCurrentPage(page: number) {
    this.updateSortingAndPagination({ currentPage: page });
  }

  changePageSize(size: number) {
    this.updateSortingAndPagination({ pageSize: size, currentPage: 1 });
  }

  updateSortColumn(column: string | undefined) {
    if (!column) return;
    if (this.sortBy !== column) {
      this.updateSortingAndPagination({ sortBy: column, sortDirection: SortOrders.Ascending, currentPage: 1 });
    }
    else {
      this.updateSortingAndPagination({ sortDirection: this.sortDirection === SortOrders.Ascending ? SortOrders.Descending : SortOrders.Ascending, currentPage: 1 });
    }
  }

  getSortOrder(column: string | undefined) {
    return column === this.sortBy && this.sortDirection;
  }

  updateSortingAndPagination(data: Partial<SimpleTableParams>) {
    const currentPage = 'currentPage' in data && data.currentPage ? data.currentPage : this.currentPage;
    const pageSize = 'pageSize' in data && data.pageSize ? data.pageSize : this.pageSize;
    const sortBy = 'sortBy' in data && data.sortBy ? data.sortBy : this.sortBy;
    const sortDirection = 'sortDirection' in data && data.sortDirection ? data.sortDirection : this.sortDirection;

    this.sort.emit({ currentPage, pageSize, sortBy, sortDirection });

    this.currentPage = currentPage;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  get isMobileView() {
    return belowBreakpointMD();
  }

  getColumnName(column: SimpleTableColumn) {
    if (typeof column.name === 'string') return column.name;
    return column.name();
  }
}
