import { Injectable } from '@angular/core';
import { Drawing, Feature } from 'magma/common/interfaces';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { options } from 'magma/common/data';
import { ActivationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { TeamService } from './team.service';
import { UserService } from './user.service';
import { TeamsQuery } from './team.query';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Injectable({ providedIn: 'root' })
@UntilDestroy()
export class PortalFeatureFlagService extends FeatureFlagService {
  private userFeatureFlags: string[] = [];
  private teamFeatureFlags: string[] = [];
  private drawingFeatureFlags: string[] = [];
  private features = new Set<string>();

  constructor(
    router: Router,
    private userService: UserService,
    teamsQuery: TeamsQuery,
    _teamsService: TeamService, // here to ensure teams
  ) {
    super();
    this.updateFeatureSet();

    userService.user$.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(user => {
      this.userFeatureFlags = user?.featureFlags ?? [];
      this.updateFeatureSet();
    });

    teamsQuery.selectActive().pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe(team => {
      this.teamFeatureFlags = team?.featureFlags ?? [];
      this.updateFeatureSet();
    });

    router.events.pipe(filter((event): event is ActivationEnd => event instanceof ActivationEnd)).subscribe(event => {
      if (event.snapshot.routeConfig?.path !== PRODUCT_INFO.defaultAppRoute) {
        this.updateDrawing(null);
      }
    });
  }

  updateUserFlags(featureFlags: Feature[]): void {
    this.userService.updateUser({ featureFlags });
  }

  updateDrawing(drawing: Drawing | null): void {
    this.drawingFeatureFlags = drawing?.featureFlags ?? [];
    this.updateFeatureSet();
  }

  private updateFeatureSet() {
    this.features = new Set([
      ...(options.features ?? []),
      ...this.drawingFeatureFlags,
      ...this.userFeatureFlags,
      ...this.teamFeatureFlags,
    ]);
    this.featuresChanged$.next();
  }

  isFeatureSupported(feature: Feature) {
    return this.features.has(feature);
  }
}
