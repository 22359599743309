import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Crumb } from 'components/breadcrumb/breadcrumb.component';
import { belowBreakpointSM } from 'components/utils';
import { combineLatest, of } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { EntitiesService } from 'services/entities.service';
import { EntityDragDropNotificationService } from 'services/entity-drag-drop-notification.service';
import { ProjectQuery } from 'services/projects.query';
import { TeamService } from 'services/team.service';
import { EntityData } from 'shared/interfaces';
import { EntityType } from 'shared/entities';
import { pathToProject } from 'shared/utils-project';

@UntilDestroy()
@Component({
  selector: 'project-breadcrumb',
  template: `
    <breadcrumb class="project-breadcrumb"
      [threshold]='threshold' [trailers]='trailers' [showFirstCrumb]='showFirstCrumb' [highlightDropZones]="!!(isDragging$ | async)"
      [crumbs]="(crumbs$ | async) || []" (onDrop)="onDrop($event)">
    </breadcrumb>
  `,
  styleUrls: ['./project-breadcrumb.component.scss'],
})
export class ProjectBreadcrumb {
  isDragging$ = this.dragDropService.dragListener$.pipe(
    distinctUntilChanged(),
    map((team) => !!team || team === null));

  threshold = belowBreakpointSM() ? 1 : 4;
  trailers = belowBreakpointSM() ? 1 : 2;
  showFirstCrumb = !belowBreakpointSM();

  constructor(
    private entitiesService: EntitiesService,
    private activatedRoute: ActivatedRoute,
    private projectQuery: ProjectQuery,
    private dragDropService: EntityDragDropNotificationService,
    private teamService: TeamService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  @HostListener('window:resize')
  onResize() {
    this.threshold = belowBreakpointSM() ? 1 : 4;
    this.trailers = belowBreakpointSM() ? 1 : 2;
    this.showFirstCrumb = !belowBreakpointSM();
  }

  crumbs$ = combineLatest([
    this.activatedRoute.queryParams,
    this.breadcrumbService.reload$,
  ]).pipe(
    switchMap(([params]) =>
      combineLatest([
        params.folder ? this.entitiesService.getHierarchy(params.folder)
          .pipe(catchError(() => of<EntityData | null>(null))) : of<EntityData | null>(null),
        this.projectQuery.selectActive(),
      ])
    ),
    map(([entityWithHierarchy, project]): Crumb[] => {
      const teamId = project?.team;
      const teamSlug = teamId && this.teamService.getTeamSlug(teamId);
      const projectCrumb: Crumb = {
        type: 'project',
        _id: project?._id,
        name: project?.name || PRODUCT_INFO.home.name,
        depth: -1,
        link: {
          url: project ? pathToProject(teamSlug!, project._id) : '/my',
        },
      };

      if (!entityWithHierarchy) {
        return [projectCrumb] as Crumb[];
      }

      const { _id, name, depth = 0, type } = entityWithHierarchy;

      if (!entityWithHierarchy.folderHierarchy || entityWithHierarchy.folderHierarchy.length === 0) {
        return [projectCrumb, { _id, name, depth, link: null, type }];
      }

      const sortedHierarchy = entityWithHierarchy.folderHierarchy.sort((a, b) => (b.depth || 0) - (a.depth || 0));

      const crumbs = sortedHierarchy.map<Crumb>(hierarchy => {
        return {
          _id: hierarchy._id,
          name: hierarchy.name,
          depth: hierarchy.depth,
          type: hierarchy.type || 'project',
          link: {
            url: project ? pathToProject(teamSlug!, project._id) : '/my',
            ...hierarchy.type === EntityType.Folder ? { query: { folder: hierarchy._id } } : {},
          },
        };
      });

      // current level
      crumbs.push({
        _id,
        name,
        depth: sortedHierarchy.length,
        type: EntityType.Folder,
        link: {
          url: project ? pathToProject(teamSlug!, project._id) : '/my',
          ...type === EntityType.Folder ? { query: { folder: _id } } : {},
        },
      });

      // parent project
      crumbs.splice(0, 0, projectCrumb);

      return crumbs;
    }),
    untilDestroyed(this),
  );

  onDrop(crumb: Crumb) {
    const folder = crumb.type === 'project' ? undefined : crumb._id;
    this.dragDropService.dropped(this.projectQuery.getActiveId() ?? undefined, folder);
  }
}
