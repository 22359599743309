import { PaymentService } from './payment.service';
import { BehaviorSubject, tap } from 'rxjs';
import {
  BillingData, BillingInformation, BillingInterval, CheckoutInfo, CustomerBillingInfo, ProductPrice,
  ScheduledTeamBillingUpdate, TeamData, TeamType, UpdateBillingItem
} from 'shared/interfaces';
import { CreateBillingItem, SubscriptionInvoiceSimulation } from './../../../shared/interfaces';
import { getPlansRequiredForMembers, toPromise } from 'shared/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Stripe from 'stripe';
import { TeamMembersQuery } from './team-members.query';
import { isEqual } from 'lodash';
import { memoize } from 'shared/decorators';
import { ManageService } from 'magma/services/manageService';
import { AppService } from './app.service';
import { HOUR } from 'magma/common/constants';
import { SubscriptionPlanType, updatePlanPrices } from 'shared/billing';

@Injectable()
export class BillingService {
  showBillingPlans$ = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient, private teamMemberQuery: TeamMembersQuery, private paymentService: PaymentService,
    private manage: ManageService, private appService: AppService) {
  }

  @memoize({ max: 50, maxAge: HOUR, promise: true })
  getAllProductsPrices() {
    return toPromise(this.httpClient.get<ProductPrice[]>(`/api/billing/prices`).pipe(tap(updatePlanPrices)));
  }

  @memoize({ max: 50, maxAge: HOUR, promise: true })
  getPrice(priceId: string) {
    return toPromise(this.httpClient.get<ProductPrice>(`/api/billing/price/${priceId}`));
  }

  getTeamBillingInformation(teamId: string) {
    return this.httpClient.get<BillingInformation>(`/api/billing/teams/${teamId}`);
  }

  getTeamBillingPendingChanges(teamId: string) {
    return toPromise(this.httpClient.get<ScheduledTeamBillingUpdate | undefined>(`/api/billing/teams/${teamId}/pending-changes`));
  }

  getUserBillingInformation() {
    return toPromise(this.httpClient.get<BillingInformation>('/api/billing'));
  }

  updateUserSubscription(items: UpdateBillingItem[]) {
    return toPromise(this.httpClient.put<void>('/api/billing/subscriptions', { items }));
  }

  updateTeamSubscription(teamId: string, items: UpdateBillingItem[], forceUpdate = false) {
    return toPromise(this.httpClient.put<void>(`/api/billing/subscriptions/teams/${teamId}`, { items, forceUpdate }));
  }

  getTeamUpcomingInvoice(data: SubscriptionInvoiceSimulation): Promise<Stripe.Invoice> | undefined {
    if (!data.items && !data.subscriptionId) return;
    return toPromise(this.httpClient.post<Stripe.Invoice>(`/api/billing/subscriptions/upcoming-invoice`, data));
  }

  updateTeamBillingInformation(teamId: string, customerBillingData: CustomerBillingInfo) {
    return toPromise(this.httpClient.put<CheckoutInfo>(`/api/billing/teams/${teamId}`, customerBillingData));
  }

  cancelTeamSubscription(teamId: string) {
    return toPromise(this.httpClient.delete<void>(`/api/billing/subscriptions/teams/${teamId}`));
  }

  updateUserBillingInformation(billingData: CustomerBillingInfo) {
    return toPromise(this.httpClient.put<void>('/api/billing', billingData));
  }

  updateTeamPaymentInformation(teamId: string) {
    return toPromise(this.httpClient.put<CheckoutInfo>(`/api/billing/subscriptions/teams/${teamId}/payment`, {}));
  }

  updateUserPaymentInformation() {
    return toPromise(this.httpClient.put<CheckoutInfo>('/api/billing/subscriptions/payment', {}));
  }

  retryTeamPayment(teamId: string) {
    return toPromise(this.httpClient.post<void>(`/api/billing/subscriptions/teams/${teamId}/retry`, {}));
  }

  retryPayment() {
    return toPromise(this.httpClient.post<void>('/api/billing/subscriptions/retry', {}));
  }

  cancelUserSubscription() {
    return toPromise(this.httpClient.delete<void>('/api/billing/subscriptions'));
  }

  createUserSubscription(items: CreateBillingItem[], coupon?: string) {
    const cancelUrl = location.href;
    return toPromise(this.httpClient.post<CheckoutInfo | false>('/api/billing/subscriptions', { items, coupon, cancelUrl }));
  }

  createTeamSubscription(teamId: string, items: CreateBillingItem[], coupon?: string, autoUpdateBilling?: boolean) {
    const cancelUrl = location.href;
    return toPromise(this.httpClient.post<CheckoutInfo | false>(`/api/billing/subscriptions/teams/${teamId}`, { items, autoUpdateBilling, subscription: { items, coupon, cancelUrl } }));
  }

  createNewTeamWithSubscription(items: CreateBillingItem[], coupon?: string, autoUpdateBilling?: boolean) {
    const cancelUrl = location.href;
    return toPromise(this.httpClient.post<CheckoutInfo | false>(`/api/billing/subscriptions/teams`, { items, autoUpdateBilling, subscription: { items, coupon, cancelUrl } }));
  }

  restartTeamSubscription(teamId: string) {
    return toPromise(this.httpClient.post(`/api/billing/subscriptions/teams/${teamId}/restart`, {}));
  }

  updateTeamMemberPro(teamId: string, userId: string, activate: boolean) {
    return toPromise(this.httpClient.post(`/api/billing/subscriptions/teams/${teamId}/activate-pro`, { userId, activate }));
  }

  restartUserSubscription() {
    return toPromise(this.httpClient.post('/api/billing/subscriptions/restart', {}));
  }

  cancelationUserReasons(reasons: string[]) {
    return toPromise(this.httpClient.put<void>(`/api/billing/subscriptions/cancel-reason`, { reasons }));
  }

  cancelationTeamReasons(teamId: string, reasons: string[]) {
    return toPromise(this.httpClient.put<void>(`/api/billing/subscriptions/teams/${teamId}/cancel-reason`, { reasons }));
  }

  setBillingPlanModal(show: boolean) {
    this.showBillingPlans$.next(show);
  }

  getBillingPlanModal() {
    return this.showBillingPlans$.asObservable();
  }

  hasActiveBilling(team: TeamData) {
    return team.billing?.plan === 'team' && !team.billing.expiry?.isExpired;
  }

  getTeamType(team: TeamData): TeamType {
    if (team.pro) return TeamType.Professional;
    if (team.isPublic) return TeamType.Public;
    return TeamType.Private;
  }

  getRequiredTeamType(team: TeamData): TeamType {
    if (team.isPublic) return TeamType.Public;
    if (this.hasActiveBilling(team)) {
      return TeamType.Professional;
    }
    return TeamType.Private;
  }

  private getPlansInBilling(billingData: BillingData | undefined) {
    if (!billingData?.stripe) return {};
    if (billingData?.expiry?.isExpired) return {};

    return new Map(billingData.stripe.items?.reduce(
      (res, i) => {
        if (i.quantity > 0) res.push([i.product, i.quantity]);
        return res;
      }, [] as any
    ));
  }

  async upgradeTeam(teamId: string | undefined, interval: BillingInterval, quantity: number, autoUpdateBilling: boolean, plan: SubscriptionPlanType) {
    const price = interval === 'month' ? plan.monthlyCode : plan.yearlyCode;

    const items = [{ plan: price, quantity }];
    const checkout = teamId ? await this.createTeamSubscription(teamId, items, undefined, autoUpdateBilling) : await this.createNewTeamWithSubscription(items, undefined, autoUpdateBilling);
    if (checkout) this.paymentService.redirectToStripeCheckout(checkout, '?upgraded=true');
  }

  async upgradeCurrentTeam(teamId: string, items: CreateBillingItem[]) {
    const checkout = await this.createTeamSubscription(teamId, items);
    if (checkout) this.paymentService.redirectToStripeCheckout(checkout, '?upgraded=true');
  }

  async isBillingValid(team: TeamData, billingData: BillingData | undefined): Promise<boolean> {
    if (!team.pro) return true;
    const plans = getPlansRequiredForMembers(this.teamMemberQuery.getAll());
    const plansInBilling = this.getPlansInBilling(billingData);

    if (!isEqual(plans, plansInBilling)) {
      return false;
    }
    return true;
  }

  async infoEducationalPlan() {
    await this.manage.addTag('@educational-plan');
    await this.appService.onContactSupport();
  }

  async infoCommunityPlan() {
    await this.manage.addTag('@community-plan');
    await this.appService.onContactSupport();
  }
}
