import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Post, PostType } from '../../../../shared/posts';
import { faPencil, faPlayCircle, faThumbtack, faTrash } from 'magma/common/icons';
import { BlogService } from '../../services/blog.service';
import { faArrowLeft, faArrowRight, faStar } from '../../../../../magma/src/ts/common/icons';

@Component({
  selector: 'posts-grid',
  templateUrl: 'posts-grid.component.pug',
})
export class PostsGridComponent {
  readonly playVideoIcon = faPlayCircle;
  readonly pinIcon = faThumbtack;
  readonly editIcon = faPencil;
  readonly trashIcon = faTrash;
  readonly toLeftIcon = faArrowLeft;
  readonly toRightIcon = faArrowRight;
  readonly highlightIcon = faStar;

  constructor(private blogService: BlogService) { }

  @Input() posts: Post[] = [];
  @Input() canManage = false;
  @Input() communityHub = false;

  @Output() opened = new EventEmitter<Post>();
  @Output() toLeft = new EventEmitter<Post>();
  @Output() toRight = new EventEmitter<Post>();
  @Output() updated = new EventEmitter<Post>();
  @Output() removed = new EventEmitter<Post>();
  @Output() highlighted = new EventEmitter<Post>();

  fallbackThumb = require('assets/posts-fallback-thumb.png');

  onAction(emitter: EventEmitter<Post>, e: Post){
    if (!this.isDisabled(e._id)) {
      emitter.emit(e);
    }
  }

  getTooltip(action: string, post: Post) {
    return `${action} ${post.type}`;
  }

  getReorderTooltip(post: Post, side: 'left' | 'right'){
    return `Move ${post.type} to ${side}`;
  }

  isVideo(post: Post) {
    return post.type === PostType.Video;
  }

  isDisabled(postId: string){
    return this.blogService.awaitingPostUpdates.has(postId);
  }

  isHighlighted(post: Post) {
    return post.highlightOrder !== undefined && post.highlightOrder !== -1;
  }
}
