import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublishersService } from 'magma/services/publishersService';
import { UserService } from './user.service';
import { PublicationStatus, PublisherId } from 'magma/common/interfaces';
import { UserPublishersSettings } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { ArtworkLicensingData, ArtworkPublishingResult, StoryProtocolRegisterRevisionData } from '../../../../magma/src/ts/common/interfaces';
import { navigateToDrawing } from '../../../../magma/src/ts/common/clientUtils';
import { Router } from '@angular/router';
import { Editor } from 'magma/services/editor';
import { EntityData } from '../../../shared/interfaces';
import { toPromise } from 'shared/utils';

@Injectable()
export class PortalPublishersService extends PublishersService {
  constructor(
    private http: HttpClient,
    private usersService: UserService,
    private toastsService: ToastService,
    private router: Router
  ) {
    super();
  }
  async linkAccount(id: PublisherId) {
    const response = await this.http.post<{ data: UserPublishersSettings }>('api/publishers/link/account', { providerId: id }).toPromise();
    if (!response) throw new Error('Invalid response');
    this.usersService.updateUser({ publishers: response.data });
    const user = this.usersService.user;
  }

  async publishRevision(editor: Editor, providerId: PublisherId, opts: StoryProtocolRegisterRevisionData) {
    const registered = editor.model.startTask('Registering on Story Protocol');
    editor.apply(() => { });
    const showErrorToast = (message: string) => {
      this.toastsService.error({ message }); 
      return;
    };
    try {
      const connId = editor.model.connId;
      const isParticipant = !!editor.drawing.participants?.find(p => p.id === this.usersService.user?._id);
      if (!isParticipant) return showErrorToast('Not drawing participants');
      
      const currentPublicationPending = editor.drawing.licensing?.revision?.publishedIn?.StoryProtocol?.status === PublicationStatus.Pending;
      if (currentPublicationPending) return showErrorToast('There\'s a pending registration, please resolve before registering a new one');

      const revision = await editor.model.server.createDrawingRevision(connId, 'Registered on Story Protocol', true);
      const body = { providerId, drawingId: editor.drawing.id, revisionId: revision.shortId, publishingOpts: opts };
      const response = await this.http.post<{ data: ({ result: ArtworkPublishingResult } & { licensing: ArtworkLicensingData }) }>('api/publishers/publish/revision', body).toPromise();
      if (!response) throw new Error('Invalid response');
      editor.drawing.licensing = response.data.licensing;
      await editor.model.server.licenseArtwork(connId, response.data.licensing);
      const participantsLength = response.data.licensing.revision?.publishedIn?.StoryProtocol?.participants?.length || 0;
      this.toastsService.notification({
        title: participantsLength > 1 ? 'We’ve asked our users to approve artwork registration, you can track the status here.' : 'Artwork has been successfully registered',
        actionText: participantsLength > 1 ? undefined : 'View on Story',
        action: participantsLength > 1 ? undefined : () => window.open(response.data.result.url, '_blank'),
      });
      await editor.model.getDrawingRevisions();
    } catch (e) {
      DEVELOPMENT && console.log(e);
      this.toastsService.error({ message: 'There was an error registering your artwork' });
    } finally {
      registered();
      editor.apply(() => { });
    }
  }

  async remixRevision(shortId: string, artworkName: string | undefined) {
    const response = await this.http.post<{ data?: string }>('api/publishers/remix/revision', { revisionId: shortId, artworkName }).toPromise();
    if (!response || !response.data) throw new Error('Invalid response');
    navigateToDrawing(this.router, response.data, 'remixing revision');
  }

  async getMyRegisteredIps(): Promise<any[]> {
    return this.http.get<{ data?: any[] }>('api/publishers/my-published').toPromise()
      .then((response) => {
        return response?.data ?? [];
      });
  }

  getEntity(id: string) {
    return this.http.get<{ data?: EntityData }>(`api/entities/publication/${id}`).toPromise()
      .then((response) => {
        return response?.data;
      });
  }

  async updateUserPublicationStatus(providerId: PublisherId, revisionId: string, status: PublicationStatus) {
    return await toPromise(this.http.post<boolean>(`/api/publishers/revision/status`, { revisionId, status, providerId }));
  }

  async cancelPublication(providerId: PublisherId, revisionId: string) {
    return await toPromise(this.http.post<boolean>(`/api/publishers/cancel/revision`, { revisionId, providerId }));
  }
}
