import { Component } from '@angular/core';
import { SplashScreenService } from 'magma/services/splashScreenService';

@Component({
  selector: 'splash-screens-outlet',
  styleUrls: ['splash-screens-outlet.component.scss'],
  templateUrl: 'splash-screens-outlet.component.pug',
})
export class SplashScreensOutletComponent {
  constructor(public service: SplashScreenService) { }

  get component(){
    return this.service.currentComponent;
  }

  ngOnInit() {
    return this.service.closeSplashScreen();
  }
}
