import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { invalidEnum, removeFileExtension } from 'magma/common/baseUtils';
import { disableMyArtdesk } from 'magma/common/data';
import { Permission } from 'magma/common/interfaces';
import { Modals } from 'magma/services/modals';
import { EntitiesService } from 'services/entities.service';
import { ModalService } from 'services/modal.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';
import { UserService } from 'services/user.service';
import { DEFAULT_DRAWING_NAME } from 'shared/constants';
import { EntityData, ProjectData } from 'shared/interfaces';
import { routeToTeam } from 'shared/utils';
import { routeToProject } from 'shared/utils-project';

@Component({
  selector: 'empty-list-placeholder',
  templateUrl: './empty-list-placeholder.component.pug',
  styleUrls: ['./empty-list-placeholder.component.scss'],
})
export class EmptyListPlaceholder {
  @Input() image!: 'participated' | 'artworks' | 'empty-folder' | 'empty-live-now' | 'empty-team';
  @Input() message?: string;
  @Input() subtitle?: string;
  @Input() folder?: EntityData;
  @Input() team?: string;
  @Input() project?: ProjectData;

  constructor(private modals: Modals,
    private teamService: TeamService,
    private router: Router,
    private userService: UserService,
    private teamsQuery: TeamsQuery,
    private modalsService: ModalService,
    private entitiesService: EntitiesService) { }

  get buttonLabel() {
    return 'Create new Drawing';
  }

  get secondButtonLabel() {
    if (this.image === 'empty-live-now') {
      return this.team ? 'Go back to your Artspace' : 'Go back to your Artdesk';
    } else if (this.showImportPSD) {
      return 'Import PSD';
    } else if (this.image === 'empty-team') {
      return 'Create project';
    }
  }

  createCanvas() {
    const { folder, team, project } = this;
    this.modals.createDrawing({ folder: folder?._id, team, project: project?._id });
  }

  async importPSDFile(files: File[]) {
    const mode = files.length === 1 ? 'separate' : await this.modals.multiFileImportManage(false);

    const user = this.userService.user!;
    const folderId = this.folder?._id;

    switch (mode) {
      case 'separate':
      case 'sequence': {
        let sequence = mode === 'sequence' && files.length > 1 ? 'true' : undefined;

        for (const file of files) {
          const navigate = (mode === 'sequence' || files.length === 1) && file === files[0];
          const name = removeFileExtension(file.name);
          const entity = await this.entitiesService.importEntityWithToast(name, [file], folderId, user, navigate, sequence);
          if (entity) sequence = entity.sequence;
        }
        break;
      }
      case 'layers': {
        const name = removeFileExtension(files[0].name) || DEFAULT_DRAWING_NAME;
        await this.entitiesService.importEntityWithToast(name, files, folderId, user);
        break;
      }
      case undefined: break;
      default: invalidEnum(mode);
    }
  }

  get canCreateEntities() {
    if (!this.project && disableMyArtdesk) return false;

    return this.teamService.hasPermissionFlag(Permission.CanCreateEntities, this.project, this.folder);
  }

  backToArtdesk() {
    const teamSlug = this.team && this.teamService.getTeamSlug(this.team);
    const commands = teamSlug ? routeToTeam(teamSlug) : ['my', 'artworks'];
    void this.router.navigate(commands);
  }

  get isSecondButtonDisplayed() {
    const images = ['empty-folder', 'artworks', 'empty-live-now', 'empty-team'];
    return images.includes(this.image);
  }

  get showImportPSD() {
    const { image } = this;
    return image === 'empty-folder' || image === 'artworks';
  }

  secondButtonCallback() {
    if (this.image === 'empty-live-now') {
      this.backToArtdesk();
    } else if (this.image === 'empty-team') {
      void this.createProject();
    }
  }

  async createProject() {
    const team = this.teamsQuery.getActive();
    if (team) {
      const project = await this.modalsService.createProject(team._id);
      if (project) {
        void this.router.navigate(routeToProject(team.slug, project._id));
      }
    }
  }
}
