import { Injectable, NgZone } from '@angular/core';
import { debounce } from 'lodash';
import { BehaviorSubject, NEVER, merge, of, Subject, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, ignoreElements, tap } from 'rxjs/operators';
import type { VoiceChat, VoiceChatParticipant, VoiceControls, MediasoupVoiceChatProvider } from '@codecharm/voice-chat-client-mediasoup';
import { voiceChatUrl } from 'magma-editor/src/ts/common/data';
import { removeElement, toggleClass } from 'magma-editor/src/ts/common/htmlUtils';
import { Analytics, Feature, OtherAction, User } from 'magma-editor/src/ts/common/interfaces';
import { getUrl } from 'magma-editor/src/ts/common/rev';
import { removeFromMap } from 'magma-editor/src/ts/common/utils';
import { isiOS, isiPhone } from 'magma-editor/src/ts/common/userAgentUtils';
import { removeItem } from 'magma-editor/src/ts/common/baseUtils';
import { Model, havePermission, userHasPermission } from 'magma-editor/src/ts/services/model';
import { storageGetBoolean, storageGetJson, storageGetNumber, storageSetItem } from 'magma-editor/src/ts/services/storage';
import { defaultVoiceChatSettings, VideoCallUser, VoiceChatService, VoiceChatSettings, VoiceChatSounds, VoiceChatUser } from 'magma-editor/src/ts/services/voiceChatService';
import { ErrorReporter } from 'magma-editor/src/ts/services/errorReporter';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SECOND } from 'magma-editor/src/ts/common/constants';
import { FeatureFlagService } from 'magma-editor/src/ts/services/feature-flag.service.interface';
import { VcFeedback } from 'magma-editor/src/ts/components/shared/modals/vc-feedback-modal/vc-feedback-modal';
import { otherActionWithRetry, RealModel } from 'magma/services/real-model';
import { logAction } from 'magma/common/actionLog';
import { isError } from 'magma/common/typescript-utils';
import { ToastService } from 'magma/services/toast.service';

const USE_SOUNDS = true;

interface Participant {
  uniqId: string;
  participant: VoiceChatParticipant<any>;
  video?: HTMLVideoElement;
  presentation?: HTMLVideoElement;
  audio: HTMLAudioElement;
  subscription: Subscription;
  element: HTMLElement | undefined;
}

function createSound(name: string) {
  try {
    const audio = new Audio(getUrl(`sounds/${name}.mp3`));
    audio.load();
    return audio;
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return undefined;
  }
}

function closeStream(stream: MediaStream) {
  for (const track of stream.getTracks()) {
    track.stop();
  }
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ActualVoiceChatService extends VoiceChatService {
  readonly canChangeOutputDevice = ((typeof window.Audio !== 'undefined') && ('setSinkId' in (new Audio())));
  session = false; // true if session exists in current drawing
  drawingId: string | undefined = undefined; // used for reconnecting after refresh
  muted = new Set<string>(); // users muted by themselves
  joined = new Set<string>(); // users joined
  mutedByAdmin = new Set<string>(); // users muted by admin
  onError = new Subject<string>();
  onTalking = new Subject<User | undefined>();
  onVoiceActivated = new BehaviorSubject<boolean>(false);
  settings: VoiceChatSettings = { ...defaultVoiceChatSettings, ...storageGetJson('voice-chat-settings') };
  isConnected = false; // TODO: maybe these should be one state field instead ?
  isConnecting = false;
  userElement: HTMLElement | undefined = undefined;
  model: Model | undefined = undefined;

  private sounds: VoiceChatSounds = (USE_SOUNDS && voiceChatUrl) ? {
    started: createSound('call_started'),
    joined: createSound('call_joined'),
    muted: createSound('call_muted'),
    unmuted: createSound('call_unmuted'),
    leftMe: createSound('call_left_me'),
    leftOther: createSound('call_left_other'),
  } : ({} as any);
  private token$ = new BehaviorSubject<string | undefined>(undefined);
  private voiceChat$ = new BehaviorSubject<VoiceChat | undefined>(undefined);
  private controls$ = new BehaviorSubject<VoiceControls | undefined>(undefined);
  private usersVolumes = new Map<string, number>();
  private usersMuted = new Set<string>();
  private participantsByConnectionId = new Map<string, Participant>();
  private participantsByUniqId = new Map<string, Participant>();
  private userElementsByUniqId = new Map<string, HTMLElement>();
  private initializedSounds = false;
  private chatProvider: MediasoupVoiceChatProvider | undefined = undefined;
  private talkingStack: Participant[] = [];
  private updatedTokenAt = 0;
  private _videoUsers = [] as VideoCallUser[];

  get videoUsers() {
    return this._videoUsers;
  }

  hasVideo() {
    return !!this.selfVideoElement;
  }

  constructor(
    private zone: NgZone,
    private errorReporter: ErrorReporter,
    private features: FeatureFlagService,
    private toastService: ToastService
  ) {
    super();

    if (IS_PORTAL && voiceChatUrl) {
      void this.initLibrary();
    }

    // leave note that we closed the page with connected voice chat, so we knot to reconnect
    window.addEventListener('beforeunload', () => {
      if ((this.isConnected || this.isConnecting) && this.drawingId) {
        storageSetItem('voice-chat-connected', JSON.stringify({ id: this.drawingId, time: Date.now() }));
      }
    });

    try {
      for (const [name, volume, muted] of this.settings.users) {
        if (name && volume !== 1) this.usersVolumes.set(name, volume);
        if (name && muted) this.usersMuted.add(name);
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }

    // TEMP: we changed name of some of the keys, fix them here
    if (!['column', 'focus', 'cursors'].includes(this.settings.videoLayout)) {
      this.settings.videoLayout = 'column';
    }

    if (this.settings.videoEnabled && !features.isFeatureSupported(Feature.Video)) {
      this.settings.videoEnabled = false;
    }
  }

  vcLogs$: Observable<string> = of('');
  vcLogs$subscription: Subscription | undefined = undefined;

  private initObservables() {
    this.zone.runOutsideAngular(() => {
      this.token$.pipe(
        distinctUntilChanged(),
        switchMap(token => {
          try {
            if (token && this.voiceChat) {
              this.updatedTokenAt = performance.now();
              return this.voiceChat.updateToken(token);
            }
          } catch (e) {
            this.errorReporter.reportError('updateToken failed', e);
          }

          return Promise.resolve(token);
        }),
        filter((result): result is undefined | string => typeof result !== 'boolean'),
        switchMap(token => {
          try {
            this.zone.run(() => this.isConnecting = !!token);

            this.vcLogs$subscription?.unsubscribe();
            this.vcLogs$subscription = undefined;
            if (token) {
              return this.chatProvider!.connectSession(voiceChatUrl!, token, (vc: VoiceChat) => {
                this.vcLogs$ = vc.logs$;
                this.vcLogs$subscription = this.vcLogs$.pipe(untilDestroyed(this)).subscribe((log) => {
                  logAction(`[voice] ${log}`);
                });
              }).pipe(finalize(() => {
                this.stop('pipe-finalize');
                this.voiceChat$.next(undefined);
              }));
            }
          } catch (e) {
            this.errorReporter.reportError('connectSession failed', e);
            this.onError.next(`Error: ${e.message}`);
            this.stop('connectSession catch');
          }

          return of(undefined);
        }),
        untilDestroyed(this),
      ).subscribe(voiceChat => {
        this.voiceChat$.next(voiceChat);
      }, e => {
        this.vcLogs$subscription?.unsubscribe();
        this.vcLogs$subscription = undefined;
        this.reset();
        this.selfVideoElement?.remove();
        this.errorReporter.reportError('token$ error', e);
      });

      this.voiceChat$
        .pipe(
          switchMap(async voiceChat => {
            if (!voiceChat) return of(undefined);

            this.applySettings();

            let errorData: any = {};

            try {
              let stream: MediaStream | undefined = undefined;

              const audio = {
                deviceId: this.settings.inputDeviceId,
                noiseSuppression: !this.settings.disableNoiseSupression,
                echoCancellation: !this.settings.disableEchoCancellation,
              };
              errorData.audio = { ...audio };

              try {
                stream = await navigator.mediaDevices.getUserMedia({ audio, video: false });
              } catch (e) {
                let error = e;
                // something threw null here
                if (!error) error = new Error('Error from getUserMedia (null)');
                // reset inputDeviceId and re-try if cannot find device
                if ((error.name === 'NotFoundError' || error.name === 'NotReadableError') && audio.deviceId) {
                  DEVELOPMENT && console.warn('Device not found, switching to default');
                  delete audio.deviceId;
                  stream = await navigator.mediaDevices.getUserMedia({ audio, video: false });
                  this.settings.inputDeviceId = undefined;
                } else {
                  throw error;
                }
              }

              return voiceChat.join(stream!);
            } catch (error) {
              this.zone.run(() => {
                if (error.name === 'NotAllowedError') {
                  DEVELOPMENT && console.error(error);
                  this.onError.next(`Access to microphone is blocked, make sure you allow this website to access the microphone`);
                } else {
                  this.errorReporter.reportError('getUserMedia failed', error, errorData);
                  this.onError.next(`Error: ${error.message}`);
                }

                this.stop('voiceChat$ catch');
              });

              return of(undefined);
            }
          }),
          switchMap(x => x),
          untilDestroyed(this),
        ).subscribe(voiceControls => {
          this.controls$.next(voiceControls);

          if (voiceControls) {
            voiceControls.pushToTalkActivation$.next(false);
            this.applySettings();

            const muted = this.settings.mute || this.settings.deafen;
            if (this.model) {
              otherActionWithRetry(this.model, muted ? OtherAction.MuteVoiceChat : OtherAction.UnmuteVoiceChat, undefined, 'MuteUnmute failed')
                .catch(e => DEVELOPMENT && console.error(e));
            }
            this.zone.run(() => {
              this.applyVideo().catch(e => this.errorReporter.reportError('applyVideo failed', e));
            });
          } else {
            this.onTalking.next(undefined);
            this.zone.run(() => {
              this.participantsByConnectionId.forEach(releaseParticipant);
              this.participantsByConnectionId.clear();
              this.participantsByUniqId.clear();
              this.talkingStack.length = 0;
              this.userElement?.classList.remove('is-talking');
              this.isConnected = false;
              this.isConnecting = false;
            });
          }
        }, e => {
          this.errorReporter.reportError('voiceChat$ error', e);
        });

      this.controls$.pipe(
        switchMap(controls => controls?.isActivated$ ?? NEVER),
        untilDestroyed(this),
      ).subscribe(talking => {
        if (this.userElement && !this.mutedByAdmin.has(this.model?.user.uniqId ?? '')) {
          toggleClass(this.userElement, 'is-talking', talking);
          this.onVoiceActivated.next(talking);
        }
      }, e => {
        this.errorReporter.reportError('controls$ error', e);
      });

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.errors$ : NEVER),
        filter(v => !!v),
        untilDestroyed(this),
      ).subscribe((err) => {
        if (isError(err)) {
          this.errorReporter.reportError(err.message, err);
        } else {
          this.errorReporter.reportError(err);
        }
      });

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.connection$ : of(undefined)),
        filter(v => !!v),
        untilDestroyed(this),
      ).subscribe((state) => {
        switch (state!) {
          case 'new':
          case 'checking':
          case 'completed': {
            this.isConnecting = true;
            break;
          }
          case 'connected': {
            this.isConnected = true;
            this.isConnecting = false;
            if (!this.justUpdatedToken) this.playSound('joined');
            break;
          }
          case 'failed':
          case 'closed':
          case 'disconnected': {
            this.stop(`connection$.next(${state})`);
            break;
          }
          default: {
            DEVELOPMENT && console.log(`Invalid (unhandled) ice state - ${state}`);
            break;
          }
        }
      }, err => DEVELOPMENT && console.error(err));

      this.voiceChat$
        .pipe(
          switchMap(voiceChat => voiceChat ? voiceChat.participants$ : of([])),
          untilDestroyed(this),
        ).subscribe(participants => {
          let sound: keyof VoiceChatSounds | undefined = undefined;

          // determining what happened
          const participantsThatLeft = new Set<string>([]);
          const participantsThatJoined = new Set<string>([]);

          this.participantsByConnectionId.forEach((participant, key) => {
            const foundParticipant = participants.find(p => p.connectionId === key);

            if (!foundParticipant) {
              // remove participants
              const participant2 = this.participantsByConnectionId.get(key)!;
              this.participantsByConnectionId.delete(key);
              this.participantsByUniqId.delete(participant2.uniqId);
              this.updateTalking(participant2, false);
              releaseParticipant(participant2);
              participantsThatLeft.add(participant.participant.data.peerId);
            } else {
              // participant was present and is preset
            }
          });

          for (const p of participants) {
            // add new participant
            if (!this.participantsByConnectionId.has(p.connectionId)) {
              participantsThatJoined.add(p.data.peerId);
              const uniqId = `${p.data.peerId}`;
              const audio = new Audio();

              if (!this.canChangeOutputDevice && this.settings.outputDeviceId) {
                (audio as any).setSinkId(this.settings.outputDeviceId)
                  .catch((e: Error) => DEVELOPMENT && console.error(e));
              }

              document.body?.appendChild(audio); // body is null sometimes
              p.setHostElement(audio);
              const participant: Participant = { uniqId, participant: p, audio, subscription: undefined as any, element: undefined };

              let videoElement: HTMLVideoElement;
              p.onNewMediaTrack = async (mediaTag, track: MediaStreamTrack) => {
                if (mediaTag === 'cam-video') {
                  videoElement = participant.video = this.videoHtmlElementFromTrack(track);
                  track.addEventListener('ended', () => {
                    videoElement.dispatchEvent(new Event('ended'));
                    videoElement.remove();
                    participant.presentation = undefined;
                  });
                  this.onNewVideo.next(videoElement);

                  return true;
                } else if (mediaTag.startsWith('present')) {
                  const screenshareVideoElement = participant.presentation = this.videoHtmlElementFromTrack(track);

                  track.addEventListener('ended', () => {
                    this.zone.run(() => {
                      screenshareVideoElement.dispatchEvent(new Event('ended'));
                      screenshareVideoElement.remove();
                      participant.presentation = undefined;
                    });
                  });

                  this.onNewPresentation.next(screenshareVideoElement);

                  return true;
                }
                return false;
              };

              this.participantsByConnectionId.set(p.connectionId, participant);
              this.participantsByUniqId.set(uniqId, participant);
              const isTalkingMonitor$ = participant.participant.isTalking$.pipe(tap((talking) => {
                this.updateTalking(participant, talking as boolean);
              }), ignoreElements());
              const hasVideoMonitor$ = p.hasVideo$.pipe(tap(hasVideo => {
                this.zone.run(() => {
                  if (videoElement) {
                    videoElement.hidden = !hasVideo;
                  }
                  participant.video = hasVideo ? videoElement : undefined;
                });
              }));

              participant.subscription = merge(isTalkingMonitor$, hasVideoMonitor$).subscribe(() => { }, e => {
                this.errorReporter.reportError('merge(isTalkingMonitor$, hasVideoMonitor$) error', e);
              });
              setParticipantElement(participant, this.userElementsByUniqId.get(uniqId));
            }
          }

          const model = this.model;
          this._videoUsers = [...this.participantsByConnectionId.values()].map(p => {
            let cachedName: string | undefined;
            let cachedTime: number | undefined;
            return {
              uniqId: p.uniqId,
              get avatarVideo() { return p.video; },
              get screenshareVideo() { return p.presentation; },
              get name() {
                if (!cachedTime || cachedTime < performance.now() - 1000) {
                  cachedName = model?.getUserByUniqId(p.uniqId, true)?.name;
                  cachedTime = performance.now();
                }
                return cachedName;
              }
            };
          });

          if (participantsThatJoined.size) {
            sound = 'joined';
            this.updateUserMutes();
          } else if (participantsThatLeft.size) {
            sound = 'leftOther';
          }

          if (sound && !this.isConnecting && !this.justUpdatedToken) {
            this.playSound(sound);
          }
        }, e => {
          this.errorReporter.reportError('voiceChat$2 error', e);
        });
    });
  }
  videoForUser(user: User): HTMLVideoElement | undefined {
    if (user === this.model?.user) return this.selfVideoElement;
    return this.participantsByUniqId.get(user.uniqId)?.video;
  }
  get controls() {
    return this.controls$.value;
  }
  private get voiceChat() {
    return this.voiceChat$.value;
  }
  private get justUpdatedToken() {
    return (performance.now() - this.updatedTokenAt) < (2 * SECOND);
  }
  private async initLibrary() {
    try {
      if (!this.chatProvider) {
        const library = await import('@codecharm/voice-chat-client-mediasoup' /* webpackChunkName: "voice-chat" */);
        library.enableProdMode();
        this.chatProvider = new library.MediasoupVoiceChatProvider();
        this.initObservables();
      }
    } catch (e) {
      console.error(e);
      this.errorReporter.reportError('Failed to import voice chat', e);
      // TODO: show error to user ?
    }
  }
  private isSupported() {
    return 'getUserMedia' in navigator.mediaDevices && !!this.chatProvider?.isSupported();
  }
  async start() {
    if (this.isConnected) return;
    if (!this.model) throw new Error('Model is not initialized');
    if (!this.chatProvider) return;

    if (!this.isSupported()) {
      if (isiOS) {
        throw new Error(`Please switch to Safari browser to use voice chat on ${isiPhone ? 'iPhone' : 'iPad'}`);
      } else {
        throw new Error(`Voice chat is not supported on this browser`);
      }
    }

    const model = this.model;
    const admin = model.user.isSuperAdmin;

    if (this.session) {
      if (!admin && !havePermission(model, 'voiceListen')) {
        throw new Error(`You don't have permission to join voice calls`);
      }
    } else {
      if (!admin && !havePermission(model, 'voiceTalk')) {
        throw new Error(`You don't have permission to start voice calls`);
      }
    }

    await this.join();
  }

  private videoHtmlElementFromTrack(track: MediaStreamTrack) {
    const video = document.createElement('video');
    video.autoplay = true;
    video.srcObject = new MediaStream([track]);
    video.playsInline = true;
    video.hidden = true;
    document.body.appendChild(video);
    track.addEventListener('ended', () => video.remove());

    return video;
  }

  private applyVideoCursors() {
    if (this.model?.editor) {
      this.model.editor.settings.includeVideo = this.settings.videoLayout === 'cursors';
    }
  }

  async join() {
    if (!this.model) throw new Error('Model is not initialized');

    this.applyVideoCursors();

    // play sound right away to unlock audio on iOS
    if (!this.initializedSounds) {
      this.initializedSounds = true;
      const sound = this.sounds.leftOther;
      if (sound) {
        sound.volume = 0.01;
        sound.play()
          .then(() => sound.pause())
          .catch(e => DEVELOPMENT && console.warn(e));
      }
    }

    try {
      await otherActionWithRetry(this.model, OtherAction.JoinVoiceChat, this.settings, 'Failed to connect to voice chat');
    } catch (e) {
      this.errorReporter.reportError('Failed to connect to voice chat', e);
      this.stop('failed-join');
      throw e;
    }
  }
  setToken(token: string | undefined) {
    this.zone.runOutsideAngular(() => {
      this.token$.next(token);
    });
  }
  async stopAsync(caller: string) {
    logAction(`[voice] stopAsync(${caller})`);
    const manualDc = this.isManualDc(caller);
    let promise: Promise<void> | undefined = undefined;

    if (this.model) {
      if (!manualDc) this.model.trackEvent(Analytics.NotManualVcDisconnection, { caller });
      promise = otherActionWithRetry(this.model, OtherAction.LeaveVoiceChat, undefined, 'LeaveVoiceChat failed');
      this.displayVcFeedbackModal(this.model, manualDc);
    } else {
      DEVELOPMENT && console.warn('Missing model in actualVoiceChatService.ts stopAsync()');
    }

    this.finishStop();

    return promise;
  }
  stop(caller: string) {
    logAction(`[voice] stop(${caller})`);
    const manualDc = this.isManualDc(caller);

    if (this.model && !this.model.closingPromise) {
      if (!manualDc) this.model.trackEvent(Analytics.NotManualVcDisconnection, { caller });

      otherActionWithRetry(this.model, OtherAction.LeaveVoiceChat, undefined, 'LeaveVoiceChat failed')
        .catch(e => { if (DEVELOPMENT) console.error(e); });

      this.displayVcFeedbackModal(this.model, manualDc);
    } else {
      DEVELOPMENT && console.warn('Missing model in actualVoiceChatService.ts stop()');
    }

    this.finishStop();
  }
  private isManualDc(stopCaller: string) {
    return (stopCaller === 'user-btn' || stopCaller === 'voice-chat-box' || stopCaller === 'drawing-page');
  }
  private finishStop() {
    this.setToken(undefined);
    if (this.isConnected || this.isConnecting) this.playSound('leftMe');

    // reset these values here, so we can immediately start again, otherwise we'd have to wait for token rxjs pipeline to finish.
    this.isConnected = false;
    this.isConnecting = false;

    this.stopScreensharing();

    if (this.selfVideoElement) {
      closeStream(this.selfVideoElement.srcObject as MediaStream);
      this.selfVideoElement.remove();
      this.selfVideoElement = undefined;
    }
  }
  reset() {
    this.stop('reset');
    this.setToken(undefined);
    this.session = false;
    this.lonelyCall = true;
    this.muted.clear();
    this.mutedByAdmin.clear();
    this.joined.clear();
  }
  addUserElement(uniqId: string, element: HTMLElement) {
    this.userElementsByUniqId.set(uniqId, element);
    const participant = this.participantsByUniqId.get(uniqId);
    if (participant) setParticipantElement(participant, element);
  }
  removeUserElement(uniqId: string, element: HTMLElement) {
    const participant = this.participantsByUniqId.get(uniqId);
    if (participant) setParticipantElement(participant, undefined);
    removeFromMap(this.userElementsByUniqId, value => value === element);
  }
  getUserVolume(user: VoiceChatUser) {
    return this.usersVolumes.get(user.name) ?? 1;
  }
  setUserVolume(user: VoiceChatUser, volume: number) {
    if (volume === 1) this.usersVolumes.delete(user.name);
    else this.usersVolumes.set(user.name, volume);

    const participant = this.participantsByUniqId.get(user.uniqId);
    participant?.participant.outputGain$.next(volume);

    this.saveSettings();
  }
  isMuted() {
    return this.settings.mute || this.settings.deafen || (this.model ? !havePermission(this.model, 'voiceTalk') : false);
  }
  isUserMuted(user: VoiceChatUser) {
    return this.usersMuted.has(user.name);
  }
  setUserMuted(user: VoiceChatUser, muted: boolean) {
    if (muted) {
      this.usersMuted.add(user.name);
    } else {
      this.usersMuted.delete(user.name);
    }

    const participant = this.participantsByUniqId.get(user.uniqId);
    if (participant) this.updateMute(participant);

    this.saveSettings();
  }
  updateUserMutes() {
    // make sure we remove talking indicator for user if they got muted by admin
    if (this.userElement && this.mutedByAdmin.has(this.model?.user.uniqId ?? '')) {
      this.userElement.classList.remove('is-talking');
    }

    this.participantsByUniqId.forEach(participant => this.updateMute(participant));
    this.model?.editor?.apply(() => { });
  }
  private isParticipantMuted(participant: Participant) {
    if (this.mutedByAdmin.has(participant.uniqId)) return true;

    const user = this.model?.getUserByUniqId(participant.uniqId, true);
    return !!user && (this.isUserMuted(user) || !userHasPermission(this.model!, user, 'voiceTalk'));
  }
  private updateMute(participant: Participant) {
    participant.participant.mute$.next(this.isParticipantMuted(participant));
  }
  updateOutputDevice() {
    if (!this.canChangeOutputDevice) return;

    const deviceId = this.settings.outputDeviceId;

    this.participantsByConnectionId.forEach(value => {
      const audio = value.audio as any;
      audio.setSinkId(deviceId).catch((e: Error) => DEVELOPMENT && console.error(e));
    });
  }
  applyMuted() {
    this.applySettings();

    if (this.model) {
      const muted = this.settings.mute || this.settings.deafen;
      otherActionWithRetry(this.model, muted ? OtherAction.MuteVoiceChat : OtherAction.UnmuteVoiceChat, undefined, 'MuteUnmute failed')
        .catch(e => DEVELOPMENT && console.error(e));
    }
  }

  private screenshareHandle: ReturnType<VoiceControls['present']> | undefined;
  private selfVideoElement?: HTMLVideoElement;
  private selfScreenshareElement?: HTMLVideoElement;

  isScreensharing() {
    return this.screenshareHandle && this.selfScreenshareElement && !this.selfScreenshareElement?.paused || false;
  }

  async startScreensharing() {
    if (!this.controls || !this.model) return;
    if (this.screenshareHandle) return;

    const stream = await navigator.mediaDevices.getDisplayMedia({ audio: false });
    this.selfScreenshareElement = this.videoHtmlElementFromTrack(stream.getVideoTracks()[0]);
    this.model.user.screenshareVideo = this.selfScreenshareElement;
    this.screenshareHandle = this.controls.present(this.model.user.localId.toString(), stream);
    for (const track of stream.getVideoTracks()) {
      track.addEventListener('ended', () => {
        this.stopScreensharing();
      });
    }
  }
  stopScreensharing() {
    if (!this.model) return;
    if (!this.screenshareHandle) return;

    this.screenshareHandle.remove();
    this.screenshareHandle = undefined;
    if (this.selfScreenshareElement) {
      closeStream(this.selfScreenshareElement.srcObject as MediaStream);
      this.selfScreenshareElement.dispatchEvent(new Event('ended'));
      this.selfScreenshareElement.remove();
      this.selfScreenshareElement = undefined;
      this.model.user.screenshareVideo = undefined;
    }
  }

  async createVideoStream() {
    return await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: this.settings.videoDeviceId,
        width: { max: 640 },
        height: { max: 480 },
        aspectRatio: { ideal: 4 / 3 },
        frameRate: 30,
        facingMode: 'user',
      },
    });
  }

  async applyVideo() {
    const controls = this.controls;
    if (!controls) return;

    if (this.settings.videoEnabled) {
      const stream = await this.createVideoStream();
      controls.enableVideo$.next(stream);
      this.selfVideoElement = document.createElement('video');
      this.selfVideoElement.srcObject = stream;
      this.selfVideoElement.autoplay = true;
      this.selfVideoElement.playsInline = true;
    } else if (controls.enableVideo$.value) {
      controls.enableVideo$.next(false);
      this.selfVideoElement?.remove();
      this.selfVideoElement = undefined;
      if (typeof controls.enableVideo$.value === 'object' && 'getTracks' in controls.enableVideo$.value) {
        closeStream(controls.enableVideo$.value);
      }
    }
    this.saveSettings();
  }

  async setVideoDevice(_deviceId: string | undefined) {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        deviceId: this.settings.videoDeviceId,
        facingMode: 'user',
      },
    });
    this.voiceChat?.changeVideoDevice(stream).then(() => {
      this.selfVideoElement = this.videoHtmlElementFromTrack(stream.getVideoTracks()[0]);
    }).catch(console.error);
  }

  async setInputDevice(deviceId: string | undefined) {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId },
      video: false,
    });
    this.voiceChat?.changeAudioDevice(stream).catch(console.error);
  }

  applySettings() {
    const settings = this.settings;

    if (this.voiceChat) {
      this.voiceChat.globalOutputGain$.next(settings.outputVolume);
      this.voiceChat.globalMute$.next(settings.deafen);
    }

    if (this.controls) {
      this.controls.inputGain$.next(settings.inputVolume);
      this.controls.inputMute$.next(settings.mute || settings.deafen || !havePermission(this.model!, 'voiceTalk'));
      this.controls.activationType$.next(settings.activation);
      this.controls.voiceLevelActivation$.next(settings.voiceActivationLevel);
    }

    this.saveSettings();
  }
  private saveSettings = debounce(() => {
    const users: [string, number, number][] = [];

    this.usersVolumes.forEach((value, key) => {
      users.push([key, value, this.usersMuted.has(key) ? 1 : 0]);
    });

    this.usersMuted.forEach(key => {
      if (!this.usersVolumes.has(key)) {
        users.push([key, 1, 1]);
      }
    });

    // HACK: clear whole list if we reached limit
    if (users.length < 100) {
      this.settings.users = users;
    }

    this.applyVideoCursors();

    storageSetItem('voice-chat-settings', JSON.stringify(this.settings));
  }, 1000);
  updateUserName(oldName: string, newName: string) {
    if (this.usersVolumes.has(oldName)) {
      this.usersVolumes.set(newName, this.usersVolumes.get(oldName)!);
      this.usersVolumes.delete(oldName);
    }

    if (this.usersMuted.has(oldName)) {
      this.usersMuted.add(newName);
      this.usersMuted.delete(oldName);
    }
  }
  private updateTalking(participant: Participant, talking: boolean) {
    if (participant.element) toggleClass(participant.element, 'is-talking', talking);

    // emit last talking user
    removeItem(this.talkingStack, participant);
    if (talking) this.talkingStack.push(participant);
    const last = this.talkingStack.length ? this.talkingStack[this.talkingStack.length - 1] : undefined;
    const user = last && this.model?.getUserByUniqId(last.uniqId, true);
    if (user) {
      user.talking = talking;
    }
    this.onTalking.next(user);
  }
  playSound(soundName: keyof VoiceChatSounds) {
    if (!USE_SOUNDS) return;
    if (this.model?.settings.muteSounds) return;

    try {
      const sound = this.sounds[soundName];

      if (sound && sound.paused) {
        sound.currentTime = 0;
        sound.volume = Math.min(this.settings.outputVolume, 1);
        sound.play().catch(e => DEVELOPMENT && console.warn(e));
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
  }
  private displayVcFeedbackModal(model: RealModel, manualDc: boolean) {
    const everyFifthCall = !(storageGetNumber('voiceCalls') % 5);
    const notManualDisconnect = !manualDc;
    const popupUnpresent = !document.querySelector('.vc-feedback-popup');
    const notLonelyCall = !model.voiceChat.lonelyCall;
    const notOptedOut = !storageGetBoolean('hideVcFeedback');
    if (everyFifthCall && notManualDisconnect && popupUnpresent && notLonelyCall && notOptedOut) {
      model.modals.openVoiceChatFeedbackModal()
        .then((feedback) => {
          if (feedback && this.model) {
            this.model.trackEvent<VcFeedback>(Analytics.VcFeedbackSubmitted, feedback);
            this.toastService.success({ message: 'Thank you for your feedback!' });
          }
        })
        .catch((e) => {
          this.errorReporter.reportError(e);
        });
    }
  }
}

function releaseParticipant(participant: Participant) {
  removeElement(participant.audio);
  if (participant.video) {
    participant.video.dispatchEvent(new Event('ended'));
    removeElement(participant.video);
  }
  if (participant.presentation) {
    participant.presentation.dispatchEvent(new Event('ended'));
    removeElement(participant.presentation);
  }
  setParticipantElement(participant, undefined);
  participant.subscription.unsubscribe();
}

function setParticipantElement(participant: Participant, element: HTMLElement | undefined) {
  participant.element?.classList.remove('is-talking', 'is-connected');
  participant.element = element;
  participant.element?.classList.add('is-connected');
}
